import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { first } from 'rxjs/operators';
import { AgendaApiService } from 'src/app/services/firebase/agenda/agenda-api.service';
import { Agenda } from 'src/app/state-v2/model/agenda.model';
import { AppLang } from 'src/assets/i18n/app-lang';
import { ACTIVITY_TYPE_OBJECTIVE_ITEM } from './../agenda-item-view/agenda-item-view.component';
import PT from './i18n/pt.json';

@Component({
  selector: 'app-agenda-activity-detail',
  templateUrl: './agenda-activity-detail.component.html',
  styleUrls: ['./agenda-activity-detail.component.scss']
})
export class AgendaActivityDetailComponent implements OnInit {

  /**
   * i18n content
   */
  content;

  /**
   * The activity model
   */
  agendaItem: Agenda;

  /**
   * Indicator if the activity will be associated to an objective
   */
  hasObjectiveRelationship = false;

  /**
   * Acitivity id
   */
  idField;

  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private agendaService: AgendaApiService
  ) { }

  ngOnInit() {
    moment.locale(AppLang.DEFAULT);
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;

    this.idField = this.route.snapshot.paramMap.get('idField');

    if (this.idField) {
      this.agendaService.getAgendaById(this.idField).pipe(first()).subscribe(activity => {
        this.agendaItem = activity;

        if (activity?.type === ACTIVITY_TYPE_OBJECTIVE_ITEM.type) {
          this.hasObjectiveRelationship = true;
        }
      });
    }
  }

}
