import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import PT from './i18n/pt.json';
import moment from 'moment';
import { AppLang } from 'src/assets/i18n/app-lang';

@Injectable()
export class AgendaItemViewConfig {

  constructor(private translate: TranslateService) {
    moment.locale(AppLang.DEFAULT);
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;
  }

  /**
   * The page's copy content
   */
  content: any;

  agendaTypeFormControl = 'agendaTypeFormControl';
  agendaTypeValidators = [
    Validators.required
  ];

  taskDescriptionFormControl = 'taskDescriptionControl';
  taskDescriptionMaxLength = 70;
  taskDescriptionMinLength = 1;
  taskDescriptionValidators = [
    Validators.required,
    Validators.maxLength(this.taskDescriptionMaxLength),
    Validators.minLength(this.taskDescriptionMinLength),
  ];

  activityTypeFormControl = 'activityTypeFormControl';

  subjectFormControl = 'subjectFormControl';
  subjectMaxLength = 35;
  subjectMinLength = 1;
  subjectValidators = [
    Validators.required,
    Validators.maxLength(this.subjectMaxLength),
    Validators.minLength(this.subjectMinLength),
  ];

  timeHourOption = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23'
  ].map((value, index) => {
    return {
      value: index,
      viewValue: value
    };
  });

  timeMinOption = [
    '00',
    '15',
    '30',
    '45'
  ].map((value, index) => {
    return {
      value: index,
      viewValue: value
    };
  });

  pillarsCheckBox: Array<any>;

  weekOptions = moment.weekdays()
    .map((value, index) => {
      return {
        value: index,
        viewValue: value
      };
  });

}
