<div class="menu-container">
  <psm-home-card
    [title]="title"
    [pillarId]="pillarItem?.pillarId">
    <section>
      <mat-card>
        <mat-card-title>{{ goalItem?.header }}</mat-card-title>
        <mat-card-subtitle>{{ goalItem?.description }}</mat-card-subtitle>
        <div class="card-goal-content">
          <div>
            <psm-form-field [title]="content.lblReason">
              <span>
                {{ goalItem?.reason }}
              </span>
            </psm-form-field>
          </div>
          <div>
            <psm-form-field [title]="content.lblReality">
              <span>
                {{ goalItem?.reality }}
              </span>
            </psm-form-field>
          </div>
        </div>
        <div class="card-goal-content">
          <div>
            <psm-form-field [title]="content.lblResources">
              <mat-chip-list>
                <mat-chip
                  [ngClass]="{
                    'chip-bg-color-blue': pillarItem?.pillarId === 'self_development',
                    'chip-bg-color-green': pillarItem?.pillarId === 'work',
                    'chip-bg-color-yellow': pillarItem?.pillarId === 'family',
                    'chip-bg-color-red': pillarItem?.pillarId === 'health'
                  }"
                  *ngFor="let resourceAvailable of goalItem?.goalResAvailable"
                >
                  {{ resourceAvailable.resource }}
                </mat-chip>
              </mat-chip-list>
            </psm-form-field>
          </div>
          <div>
            <psm-form-field [title]="content.lblResourceRequired">
              <mat-chip-list>
                <mat-chip
                  *ngFor="let resourceRequired of goalItem?.goalResRequired"
                  [ngClass]="{
                    'chip-bg-color-blue': pillarItem?.pillarId === 'self_development',
                    'chip-bg-color-green': pillarItem?.pillarId === 'work',
                    'chip-bg-color-yellow': pillarItem?.pillarId === 'family',
                    'chip-bg-color-red': pillarItem?.pillarId === 'health'
                  }"
                >
                  {{ resourceRequired.resource }}
                </mat-chip>
              </mat-chip-list>
            </psm-form-field>
          </div>
        </div>
        <div class="card-goal-content">
          <div>
            <psm-form-field
              [title]="content.lblGoalCreationDate"
              [hint]="goalItem?.creationDate.toMillis() | date: 'dd/MM/yyyy h:mm:ss a'">
            </psm-form-field>
          </div>
          <div>
            <psm-form-field
              [title]="content.lblGoalExpiryDate"
              [hint]="goalItem?.expireDate.toMillis() | date: 'dd/MM/yyyy h:mm:ss a'">
            </psm-form-field>
          </div>
        </div>
        <mat-card-actions>
          <div class="card-actions">
            <button *ngIf="showGoalCRUDControls" mat-button (click)="onObjectiveClick(goalItem)">
              <mat-icon>add_task</mat-icon>
              {{ content.objectiveBtn }}
            </button>
            <button *ngIf="showGoalCRUDControls" mat-button (click)="onEditClick(goalItem)">
              <mat-icon>create</mat-icon>
              {{ content.editBtn }}
            </button>
            <button *ngIf="showGoalCRUDControls"  mat-button (click)="onRemoveClick(goalItem)">
              <mat-icon>remove_circle</mat-icon>
              {{ content.removeBtn }}
            </button>
            <button *ngIf="showGoalControls" mat-button (click)="onAddGoalNoteClick()">
              <mat-icon>edit_note</mat-icon>
              {{ content.actionControls.addGoalNotes }}
            </button>
            <button *ngIf="showGoalCRUDControls" mat-button (click)="onViewDetailsClick(goalItem)">
              <mat-icon>visibility</mat-icon>
              {{ content.actionControls.viewDetails }}
            </button>
          </div>
        </mat-card-actions>
      </mat-card>
    </section>
  </psm-home-card>
</div>
