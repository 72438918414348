import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PrimeIcons } from 'primeng/api';
import { LogoutConfirmationComponent } from 'src/app/pages/dialogs/common/logout-confirmation/logout-confirmation.component';
import { AuthService } from 'src/app/services/firebase/authentication/auth.service';
import { setToolBarOptions } from 'src/app/state-v2/action/component.actions';
import { AppLang } from 'src/assets/i18n/app-lang';
import { RouteName } from 'src/shared/router-paths';
import PT from './i18n/pt.json';

export const ACTION = {
  name: 'APP_MENU'
};

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'psm-menu',
  templateUrl: './psm-menu.component.html',
  styleUrls: ['./psm-menu.component.scss']
})
export class PsmMenuComponent implements OnInit {

  content: any;

  /**
   * It defines the menu structure. Please see the default implementation as example
   */
  @Input()
  menuItems: any;

  /**
   * It emits the onShow event handler
   */
  @Output()
  onShow = new EventEmitter();

  /**
   * It emits the onHide event handler
   */
  @Output()
  onHide = new EventEmitter();

  /**
   * It emits the onHide event handler
   */
   @Output()
   onLogout = new EventEmitter();

  constructor(
    private translate: TranslateService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private auth: AuthService,
    private store: Store) { }

  ngOnInit(): void {
    this.content = PT.content;
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);

    if (!this.menuItems) {
      this.menuItems = [
        {
          label: this.content.menuHome.label,
          items:
          [
            {
              label: this.content.menuHome.items[0].label,
              icon: PrimeIcons.HOME,
              command: () => {
                this.router.navigate([RouteName.HOME_VIEW]);
                this.store.dispatch(setToolBarOptions({
                  setToolBar: false
                }));
              }
            }
          ]
        },
        {
          label: this.content.menuMyPdpf.label,
          items:
          [
            {
              label: this.content.menuMyPdpf.items[0].label,
              icon: PrimeIcons.TH_LARGE,
              command: () => {
                this.router.navigate([RouteName.PILLAR_DEFINITION, {action: ACTION.name}]);
                this.store.dispatch(setToolBarOptions({
                  setToolBar: true
                }));
              }
            },
            {
              label: this.content.menuMyPdpf.items[1].label,
              icon: PrimeIcons.LIST,
              command: () => {
                this.router.navigate([RouteName.GOAL_SUMMARY]);
                this.store.dispatch(setToolBarOptions({
                  setToolBar: true
                }));
              }
            },
            {
              label: this.content.menuMyPdpf.items[2].label,
              icon: PrimeIcons.LIST,
              command: () => {
                this.router.navigate([RouteName.OBJECTIVE_SUMMARY]);
                this.store.dispatch(setToolBarOptions({
                  setToolBar: true
                }));
              }
            },
            {
              label: this.content.menuMyPdpf.items[3].label,
              icon: PrimeIcons.CALENDAR,
              command: () => {
                this.router.navigate([RouteName.AGENDA, {action: ACTION.name}]);
                this.store.dispatch(setToolBarOptions({
                  setToolBar: true
                }));
              }
            }
          ]
        },
        {
          label: this.content.menuConfig.label,
          items:
          [
            {
              label: this.content.menuConfig.items[0].label,
              icon: PrimeIcons.USER,
              command: () => {
                this.router.navigate([RouteName.USER_INFO, {action: ACTION.name}]);
                this.store.dispatch(setToolBarOptions({
                  setToolBar: true
                }));
              }
            }
          ]
        },
        {
          label: this.content.menuSupport.label,
          items:
          [
            {
              label: this.content.menuSupport.items[0].label,
              icon: PrimeIcons.PHONE,
              command: () => {
                console.log('On Support Click menu');
              }
            }
          ]
        },
        {
          label: this.content.menuLogout.label,
          items:
          [
            {
              label: this.content.menuLogout.items[0].label,
              icon: PrimeIcons.SIGN_OUT,
              command: () => {
                const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
                  data: {
                    confirm: true
                  }
                });

                dialogRef.afterClosed().subscribe(res => {
                  if (res) {
                    this.onLogout.emit(true);
                    this.auth.logout();
                    this.snackBar.open(this.content.menuLogout.snackAlert, '', {
                      duration: 2000,
                    });
                  }
                });
              }
            }
          ]
        }
      ];
    }
  }

  /**
   * onHide event emitter
   * @param event
   */
  onHideEvent(event) {
    this.onHide.emit(event);
  }

  /**
   * onShow event emitter
   * @param event
   */
   onShowEvent(event) {
    this.onShow.emit(event);
  }

}
