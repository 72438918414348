import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
// tslint:disable-next-line: max-line-length
import { AccountInUseDialogComponent } from 'src/app/pages/dialogs/account-activation/account-in-use-dialog/account-in-use-dialog.component';
// tslint:disable-next-line: max-line-length
import { Store } from '@ngrx/store';
import { LoginUnsuccessfulDialogComponent } from 'src/app/pages/dialogs/login/login-unsuccessful-dialog/login-unsuccessful-dialog.component';
import { logoutSession } from 'src/app/state-v2/action/login.actions';
import FIRESTORE_ERROR_MAP from '../../../../shared/data-config/firebase_error_map.json';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: Observable<firebase.User>;

  constructor(
    private afAuth: AngularFireAuth,
    private dialog: MatDialog,
    private router: Router,
    private spinner: NgxSpinnerService,
    private store: Store
  ) {
    this.user = this.afAuth.authState;
  }

  getUser(): Observable<firebase.User> {
    return this.user.pipe(take(1));
  }

  isLoggedIn(): Observable<boolean> {
    return this.user.pipe(
      take(1),
      map(authState => !!authState)
    );
  }

  login(user: any) {
    this.spinner.show();
    this.afAuth.signInWithEmailAndPassword(user.email, user.password)
      .then((res) => {
        this.router.navigate(['/']);
        /**
         * TODO: Remove it - Util to test congratulation page and its flow.
         */
        // this.router.navigate([RouteName.CONGRATULATION_PAGE]);
        /**
         * TODO: Remove it - Util to test congratulation page and its flow.
         */
      })
      .catch((error) => {
        console.error(error);
        this.dialog.open(LoginUnsuccessfulDialogComponent);
      })
      .finally(() => this.spinner.hide());
  }

  logout() {
    this.spinner.show();
    return this.afAuth.signOut()
      .then(() => {
        this.store.dispatch(logoutSession());
        this.router.navigate(['/']);
        this.spinner.hide();
      }).catch(e => {
        console.error('Logout error: ' + JSON.stringify(e));
        this.spinner.hide();
      });
  }

  register(email: string, password: string): Promise<any> {
    return this.afAuth.createUserWithEmailAndPassword(email, password)
      .catch(e => {
        if (e.code === FIRESTORE_ERROR_MAP.MAP.registration.alreadyInUse.code) {
          this.dialog.open(AccountInUseDialogComponent);
        }
        this.spinner.hide();
      });
  }

}
