import { Component, Input, OnInit } from '@angular/core';
import { Profile } from 'src/app/state-v2/model/profile.model';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'psm-user-badge',
  templateUrl: './psm-user-badge.component.html',
  styleUrls: ['./psm-user-badge.component.scss']
})
export class PsmUserBadgeComponent implements OnInit {

  /**
   * User's profile state
   */
  @Input() profileState: Profile

  constructor() { }

  ngOnInit(): void {
    this.profileState = {
      personalInfo: {
        name: '',
        lastName: ''
      },
      email: ''
    }
  }

}
