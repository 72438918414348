<div class="p-d-flex p-jc-center">
  <button
    pButton
    pRipple
    type="button"
    [label]="label"
    [icon]="icon"
    [iconPos]="iconPos"
    [ngClass]="{
      'p-button-secondary': color === 'secondary',
      'p-button-success': color === 'success',
      'p-button-info': color === 'info',
      'p-button-warning': color === 'warning',
      'p-button-help': color === 'help',
      'p-button-danger': color === 'danger',
      'p-button-rounded': !square
    }"
    class="p-button-lg"
    [loading]="isLoading"
    [disabled]="disabled"
    (click)="handleClick($event)"
    (onFocus)="handleOnFocus($event)"
    (onBlur)="handleOnBlur($event)">
  </button>
</div>

