<div class="m-container">
  <psm-home-card
    [title]="content.title"
    [subTitle]="content.text1">
  </psm-home-card>
  <mat-accordion class="accordion-header-align">
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>add_circle</mat-icon>
          <span>
            {{ content.btnAddActivity }}
          </span>
        </mat-panel-title>
        <mat-panel-description>
          {{ content.addActivityDescription }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <agenda-item-view (onSaveCompletedEvent)="accordion.closeAll()" [hasObjectiveRelationship]="false"></agenda-item-view>
    </mat-expansion-panel>
    <mat-expansion-panel
      hideToggle
      (opened)="objectiveActivityAccordionState = true"
      (closed)="objectiveActivityAccordionState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>add_circle</mat-icon>
          <span>
            {{ content.btnAddActivityObjective }}
          </span>
        </mat-panel-title>
        <mat-panel-description>
          {{ content.addActivityObjectiveDescription }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <agenda-item-view (onSaveCompletedEvent)="accordion.closeAll()" [hasObjectiveRelationship]="true"></agenda-item-view>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="p-flex-column w-full">
    <psm-home-card [title]="content.radioFilter.daily">
      <section>
        <psm-daily-agenda
          previewMode="true"
          [agenda]="agenda$ | async"
          [selectedItem]="selectedAgendaItem"
          (itemClick)="onAgendaItemClick($event)"
          (dateSelectionChangeHandler)="dateSelectionChangeHandler($event)">
        </psm-daily-agenda>
      </section>
    </psm-home-card>
  </div>
</div>
