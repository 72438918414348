import { createAction, props } from '@ngrx/store';
import { GoalState } from '../reducer/goal.reducer';

export const openGoalsSummaryPage = createAction(
  '[Goals Summary Page] Open Goals Summmary Page'
);

export const openGoalDetails = createAction(
  '[Goal Details Page] Open Goal Details'
);

export const openGoalDetailsFailure = createAction(
  '[Goal Details Page] Open Goal Details Failure',
  props<{error: any}>()
);

export const openGoalsPage = createAction(
  '[Goals Page] Open Goals',
  props<{pillarNode: string}>()
);

export const openGoalsPageSuccess = createAction(
  '[Goals Page] Open Goals Success'
);

export const openGoalsPageFailure = createAction(
  '[Goals Page] Open Goals Failure',
  props<{error: any}>()
);

export const addGoalDocument = createAction(
  '[Goal API] Add Goal Document',
  props<{goal: GoalState}>()
);

export const deleteGoalDocument = createAction(
  '[Goal API] Delete Goal Document',
  props<{docId: string}>()
);

export const deleteGoalDocumentSuccess = createAction(
  '[Goal API] Delete Goal Document Success'
);

export const deleteGoalDocumentFailure = createAction(
  '[Goal API] Delete Goal Document Failure',
  props<{error: any}>()
);

export const addGoalDocumentSuccess = createAction(
  '[Goal API] Add Goal Document Success'
);

export const addGoalDocumentFailure = createAction(
  '[Goal API] Add Goal Document Failure',
  props<{error: any}>()
);

export const addGoalFailureMessageDisplay = createAction(
  '[Goal API] Add Goal Document Failure Message Display'
);

export const getGoals = createAction(
  '[Goal API] Get Goals',
  props<{pillarId: string}>()
);

export const getGoalsFailure = createAction(
  '[Goal API] Get Goals',
  props<{error: any}>()
);

export const getGoalByGoalId = createAction(
  '[Goal API] Get Goal by Goal ID',
  props<{goalId: string}>()
);

export const getGoalByGoalIdFailure = createAction(
  '[Goal API] Get Goal by Goal ID Failure',
  props<{error: any}>()
);
