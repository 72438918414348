<div class="m-container">
  <psm-home-card
    [title]="content.title"
    [subTitle]="pillarConfig.title">
    <mat-card>
      <mat-card-content>
        {{ pillarConfig.description }}
        <br />
        <br />
        {{ pillarConfig.longDescription }}
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          {{ content.subTitle }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="center stretch">
        <mat-list>
          <mat-list-item *ngFor="let topic of pillarConfig.topics">
            <mat-icon mat-list-icon color="primary">done</mat-icon>
            <div mat-line>
              <span style="color: black;">
                {{ topic }}
              </span>
            </div>
            <mat-divider></mat-divider>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </psm-home-card>
</div>

