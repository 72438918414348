<mat-dialog-content>
  <mat-card>
    <mat-card-header id="dialog-container">
      <mat-card-title>{{ content.title }}</mat-card-title>
      <mat-card-subtitle>{{ content.subtitle }}</mat-card-subtitle>
    </mat-card-header>
    <div class="card-container">
      <p>
        <span class="opt-content-header">
          {{ content.title4 }}
        </span>
        <br/>
        <span class="opt-content">
          {{ content.description }}
        </span>
      </p>
      <p>
        <span class="opt-content-header">
          {{ content.example.title3 }}
        </span>
        <br/>
        <span class="opt-content">
          {{ content.example.objectiveDescription }}
        </span>
      </p>
      <p>
        <span class="opt-content-header">
          {{ content.title2 }}
        </span> 
        <br/>
      </p>
      <mat-chip-list>
        <mat-chip *ngFor="let item of content.example.objectiveOptions">
          {{item}}
        </mat-chip>
      </mat-chip-list>
      <p>
        <span class="opt-content">
          {{ content.summary }}
        </span>
      </p>
    </div>
    <mat-card-actions>
      <div class="card-container">
        <div class="card-small-default">
          <button mat-raised-button (click)="onCloseDialog()" color="alert" class="btn-size-default">
            {{ content.btnClose }}
          </button>
        </div>
      </div>
    </mat-card-actions>
  </mat-card>
</mat-dialog-content>
