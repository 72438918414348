import { createAction } from '@ngrx/store';

export const loginSession = createAction(
  '[Login] Login Session'
);

export const logoutSession = createAction(
  '[Login] Logout Session'
);

