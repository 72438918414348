import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

@Injectable()
export class AccountActivationConfig {

  emailControl = 'emailControl';
  emailValidator = [
    Validators.required,
    Validators.email,
    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
  ];

  passwordControl = 'passwordControl';
  passwordMinLength = 6;
  passwordValidator = [
    Validators.required,
    Validators.minLength(this.passwordMinLength)
  ];

  passwordConfirmControl = 'passwordConfirmControl';
  passwordConfirmMinLength = 6;
  passwordConfirmValidator = [
    Validators.required,
    Validators.minLength(this.passwordConfirmMinLength)
  ];

  tokenControl = 'tokenControl';
  tokenMinLength = 1;
  tokenMaxLength = 100;
  tokenValidator = [
    Validators.required,
    Validators.maxLength(this.tokenMaxLength),
    Validators.minLength(this.tokenMinLength)
  ];

  constructor() {}

}
