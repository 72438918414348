import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppLang } from '../../../assets/i18n/app-lang';

import { Agenda } from 'src/app/state-v2/model/agenda.model';
import PT from './i18n/pt.json';
import { FormGroup } from '@angular/forms';
import moment from 'moment';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'psm-daily-agenda',
  templateUrl: './psm-daily-agenda.component.html',
  styleUrls: ['./psm-daily-agenda.component.scss']
})
export class PsmDailyAgendaComponent implements OnInit {

  /**
   * Agenda datasource
   */
  @Input() agenda: any;

  /**
   * Whether or not to display the whole agenda.
   * The preview mode only displays the time and activity name.
   * Mostly used for the main home card
   */
  @Input() previewMode = false;

  /**
   * The selected item in the agenda
   * Mostly used to show the current item according to the time
   */
  @Input() selectedItem: Agenda | any;

  /**
   * Emits an event whenever row is selected
   */
  @Output() selectedRowEvent = new EventEmitter();

  /**
   * Emits click event on the agenda item
   */
  @Output() itemClick = new EventEmitter();

  /**
   * It emits an event whenever the selected date changes
   */
  @Output() dateSelectionChangeHandler = new EventEmitter();

  /**
   * Main component's form
   */
  form = new FormGroup({});

  /**
   * The week options shown in the dropdown
   */
  weekOptions;

  /**
   * Component Copy
   */
  content: any;

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    moment.locale(AppLang.DEFAULT);

    this.weekOptions = moment.weekdays()
      .map((value, index) => {
        return {
          value: index,
          viewValue: value
        };
      });

    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;
  }

  /**
   * It emits the selected agenda item
   * @param event selected agenda item
   */
  onRowSelect(event) {
    this.selectedRowEvent.emit(event.data);
  }

  /**
   * WeekDay selection event handler
   * @param event MatEvent
   */
  onWeekDaySelectionChange(event) {
    this.dateSelectionChangeHandler.emit(event && event?.value ? event?.value : '');
  }

  /**
   * It returns the selected week day if it's an existing activity
   */
  get selectedWeekDay() {
    return moment().day();
  }

}
