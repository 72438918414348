import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { PdpfPartyService } from 'src/app/services/common/pdpf-party/pdpf-party.service';
import { AppLang } from 'src/assets/i18n/app-lang';
import { RouteName } from 'src/shared/router-paths';
import { conformToMask } from 'text-mask-core';
import { PsmTextInputConfig } from '../../components/psm-text-input/psm-text-input.config';
import { SaveDataConfirmationComponent } from '../dialogs/common/save-data-confirmation/save-data-confirmation.component';
import { MasterButton } from '../master-content/master-content.class.button';
import { MasterContentPage } from '../master-content/master-content.class.page';
import { ACTION } from './action/action';
import { UserInfoConfig } from './user-info.config';

import { Store } from '@ngrx/store';
import MOMENT from 'moment';
import { setProfile } from 'src/app/state-v2/action/profile.actions';
import { Profile } from 'src/app/state-v2/model/profile.model';
import { ServiceAccount } from 'src/app/state-v2/model/service-account.model';
import { selectProfile } from 'src/app/state-v2/selector/profile.selector';
import { selectServiceAccount } from 'src/app/state-v2/selector/service-accont.selector';
import PT from './i18n/pt.json';


@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit {

  config: UserInfoConfig;
  textMaskConfig: PsmTextInputConfig;
  content: any;
  form = new FormGroup({});
  page: MasterContentPage;
  PARENT_ACTION: any;

  /**
   * Profile's avatar
   *
   * @type {string}
   * @memberof UserInfoComponent
   */
  avatarUrl;

  fb;
  downloadURL: Observable<string>;

  /**
   * Profile State
   */
  profile: Profile;

  /**
   * The service account representation
   */
  serviceAccount: ServiceAccount;

  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private pdpfPartyService: PdpfPartyService,
    private userInfoConfig: UserInfoConfig,
    private psmTextInputConfig: PsmTextInputConfig,
    private storage: AngularFireStorage,
    private store: Store
  ) { }

  ngOnInit(): void {
    MOMENT.locale(AppLang.DEFAULT);
    this.textMaskConfig = this.psmTextInputConfig;
    this.config = this.userInfoConfig;
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;
    this.PARENT_ACTION = ACTION.PARENT_ACTION;

    this.page = new MasterContentPage(
      true,
      true,
      undefined,
      this.content.formTitle,
      undefined,
      new MasterButton(
        this.PARENT_ACTION.ACCOUNT_ACTIVATION.active
          ? this.content.btnNext
          : this.content.btnSave
      ),
      new MasterButton(this.content.btnClose)
    );

    if (this.route.snapshot.paramMap.get('action')) {
      const routeAction = this.route.snapshot.paramMap.get('action');

      if (routeAction.localeCompare(ACTION.PARENT_ACTION.ACCOUNT_ACTIVATION.name) === 0) {
        ACTION.PARENT_ACTION.ACCOUNT_ACTIVATION.active = true;
      } else {
        ACTION.PARENT_ACTION.APP_MENU.active = true;
      }
    }

    this.store.select(selectProfile).subscribe(profile => this.profile = profile);
    this.store.select(selectServiceAccount).subscribe(serviceAccount => this.serviceAccount = serviceAccount);
  }

  /**
   * It handles the avatar upload for the user
   * @param event
   */
  onSelectAvatar(event) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        let img = new Image;
        this.avatarUrl = event.target.result;
        img.src = event.target.result as string;
      }

      const file = event.target.files[0];
      const filePath = `avatar_images/${this.serviceAccount?.profileRef}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);

      task.snapshotChanges().subscribe(url => {
        this.downloadURL = fileRef.getDownloadURL();
        this.downloadURL.subscribe(url => {
          if (url) {
            this.fb = url;
            this.profile.avatarUrl = url;
            this.store.dispatch(setProfile({
              profile: {
                ...this.profile,
                idField: this.serviceAccount?.profileRef
              }
            }));
          }
        });
      });
    }
  }

  onDeleteAvatar(event) {

  }

  /**
   * It prefill the gender value from store
   * @param value string
   */
  get genderValue() {
    const value = this.profile.personalInfo?.gender
      ? this.profile.personalInfo?.gender : '';

    const selectedItem =
      this.config.genderList.filter(gender => gender.viewValue === value);

    return selectedItem.length > 0 ? selectedItem[0].value : '';
  }

  /**
   * It returns the masked value for the phonenumber
   * @param value string
   * @returns string
   */
  getPhoneValue(value: string) {
    if (value && typeof value === 'string') {
      const maskedValue = conformToMask(value, this.textMaskConfig.ptPhoneMask.mask, {
        guide: false
      });
      return maskedValue.conformedValue;
    }
  }

  /**
   * It updates the store with the new dropdown selection
   * @param event MatSelectChange
   */
  genderSelectionChange(event) {
    const newList =
      this.config.genderList.filter(gender => gender.value === event.value);
    this.profile.personalInfo.gender = newList.length > 0
      ? newList[0].viewValue : this.profile.personalInfo.gender;
  }

  /**
   * It updates the store with current values and update firestore
   *
   */
  onSubmitForm(event) {
    const dialogRef = this.dialog.open(SaveDataConfirmationComponent, {
      data: {
        confirm: true
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.spinner.show();
        this.profile.personalInfo.lastName = this.form.get(this.config.lastNameFormControl).value;
        this.profile.personalInfo.name = this.form.get(this.config.nameFormControl).value;
        this.profile.personalInfo.dob = this.pdpfPartyService.getFormattedDate(
            new Date(this.form.get(this.config.dobFormControl).value)
          );
        this.profile.personalInfo.phoneNumber = this.form.get(this.config.phoneFormControl).value;

        this.store.dispatch(setProfile({
          profile: {
            ...this.profile,
            idField: this.serviceAccount?.profileRef
          }
        }));

        this.spinner.hide();
        this.snackBar.open(this.content.successAlert.message, '', {
          duration: 2000,
        });

        if (ACTION.PARENT_ACTION.ACCOUNT_ACTIVATION.active) {
          this.router.navigate([RouteName.PILLAR_DEFINITION, {
            action: ACTION.PARENT_ACTION.ACCOUNT_ACTIVATION.name
          }]);
        }
      }
    });
  }

  /**
   * It navigates back to the home page
   */
  public onCloseBtnAction(event) {
    this.router.navigate([RouteName.HOME_VIEW]);
  }

}
