<div class="m-container">
  <div *ngFor="let pillar of pillars | async">
    <psm-home-card
      [title]="content.title + ' ' + pillar?.title"
      [pillarId]="pillar?.pillarId"
      [subTitle]="goal?.header">
      <div class="card-content">
        <button mat-button (click)="onAddClick()">
          <mat-icon>add_circle</mat-icon>
          {{ content.btnAdd }}
        </button>
        <button mat-button (click)="onLearnMoreClick()">
          <mat-icon>info_outline</mat-icon>
          {{ content.btnLearnMore }}
        </button>
      </div>
    </psm-home-card>
  </div>
  <div class="p-flex-column w-full">
    <div *ngFor="let objective of objectives | async; let i = index">
      <psm-objective-view
        [goalId]="goal?.goalId"
        [title]="content.objCard.title + ' ' + (i + 1)"
        [pillar]="pillar"
        [objective]="objective"
        [showControls]="true">
      </psm-objective-view>
    </div>
  </div>
</div>
