import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppLang } from 'src/assets/i18n/app-lang';
import { RouteName } from 'src/shared/router-paths';
import { conformToMask } from 'text-mask-core';
import { PsmTextInputConfig } from '../../components/psm-text-input/psm-text-input.config';
import { MasterButton } from '../master-content/master-content.class.button';
import { MasterContentPage } from '../master-content/master-content.class.page';
import { ACTION } from './action/action';
import { UserPhoneConfig } from './user-phone.config';

import { Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { setProfile } from 'src/app/state-v2/action/profile.actions';
import { Profile } from 'src/app/state-v2/model/profile.model';
import { ServiceAccount } from 'src/app/state-v2/model/service-account.model';
import { selectProfile } from 'src/app/state-v2/selector/profile.selector';
import { selectServiceAccount } from 'src/app/state-v2/selector/service-accont.selector';
import PT from './i18n/pt.json';


@Component({
  selector: 'app-user-phone',
  templateUrl: './user-phone.component.html',
  styleUrls: ['./user-phone.component.scss']
})
export class UserPhoneComponent implements OnInit {

  config: UserPhoneConfig;
  textMaskConfig: PsmTextInputConfig;
  page: MasterContentPage;
  content: any;
  form = new FormGroup({});

  /**
   * The service account representation
   */
  serviceAccount: ServiceAccount;

  /**
   * Representation of the profile state
   */
  profile: Profile

  constructor(
    private router: Router,
    private translate: TranslateService,
    private userPhoneConfig: UserPhoneConfig,
    private psmTextInputConfig: PsmTextInputConfig,
    private spinner: NgxSpinnerService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.config = this.userPhoneConfig;
    this.textMaskConfig = this.psmTextInputConfig;
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;

    if (!this.page) {
      this.page = new MasterContentPage(
        false,
        true,
        'assets/images/imgs/phone-telephone.svg',
        '',
        [this.content.title],
        new MasterButton(this.content.btnNext),
        new MasterButton(this.content.btnBack)
      );
    }

    this.store.select(selectServiceAccount).subscribe(serviceAccount => this.serviceAccount = JSON.parse(JSON.stringify(serviceAccount)));
    this.store.select(selectProfile).subscribe(profile => this.profile = JSON.parse(JSON.stringify(profile)));
  }

  /**
   * It returns the masked value for the phonenumber
   * @param value string
   * @returns string
   */
   getPhoneValue(value: string) {
    if (value && typeof value === 'string') {
      const maskedValue = conformToMask(value, this.textMaskConfig.ptPhoneMask.mask, {
        guide: false
      });
      return maskedValue.conformedValue;
    }
  }

  /**
   * It updates the store with current values and update firestore
   *
   */
  btnForwardEvent(event) {
    if (this.form.valid) {
      this.spinner.show();
      this.profile.personalInfo.phoneNumber = this.form.get(this.config.phoneFormControl).value;

      this.store.dispatch(setProfile({
        profile: {
          ...this.profile,
          idField: this.serviceAccount?.profileRef
        }
      }));

      this.router.navigate([RouteName.PILLAR_DEFINITION, {
        action: ACTION.PARENT_ACTION.SELF.name
      }]);
      this.spinner.hide();
    }
  }

  /**
   * Routes back to previous page
   */
  btnBackwardEvent(event) {
    this.router.navigate([RouteName.USER_DOB_GENDER_FORM]);
  }

}
