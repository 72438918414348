import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import PT from './i18n/pt.json';
import { AppLang } from 'src/assets/i18n/app-lang';

export interface DialogData {
  confirm: boolean | true;
}


@Component({
  selector: 'app-logout-confirmation',
  templateUrl: './logout-confirmation.component.html',
  styleUrls: ['./logout-confirmation.component.scss']
})
export class LogoutConfirmationComponent implements OnInit {

  content: any;

  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<LogoutConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { }

  ngOnInit(): void {
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT['dialog-content'];
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

}
