import { PdpfPartyService } from 'src/app/services/common/pdpf-party/pdpf-party.service';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import moment from 'moment';
import { addAgendaItem, deleteAgendaItem } from 'src/app/state-v2/action/agenda.actions';
import { Agenda } from 'src/app/state-v2/model/agenda.model';
import { ServiceAccount } from 'src/app/state-v2/model/service-account.model';
import { selectServiceAccount } from 'src/app/state-v2/selector/service-accont.selector';
import { AppLang } from 'src/assets/i18n/app-lang';
import { SaveDataConfirmationComponent } from '../../dialogs/common/save-data-confirmation/save-data-confirmation.component';
import { AgendaItemViewConfig } from './agenda-item-view.config';
import PT from './i18n/pt.json';
import { addNoteApi } from 'src/app/state-v2/action/notes.actions';
import { PsmNotesComponent } from 'src/app/components/psm-notes/psm-notes.component';
import { Router } from '@angular/router';
import { RouteName } from 'src/shared/router-paths';
import { OptionModel } from 'src/app/components/psm-dropdown/psm-dropdown.component';
import { ObjectiveModel } from 'src/app/state-v2/model/objective.model';
import { Subscription } from 'rxjs';
import { ObjectiveApiServiceV2 } from 'src/app/services/firebase/objective/objective-api.service';
import { PillarApiService } from 'src/app/services/firebase/pillar/pillar-api.service';

export enum AGENDA_ITEM_SETTINGS {
  SPECIAL_EVENTS_TOGGLE = 0
}

export enum ACTIVITY_TYPE_REGULAR_ITEM {
  type = 'regular_activity'
}

export enum ACTIVITY_TYPE_OBJECTIVE_ITEM {
  type = 'objective_activity'
}

@Component({
  selector: 'agenda-item-view',
  templateUrl: './agenda-item-view.component.html',
  styleUrls: ['./agenda-item-view.component.scss']
})
export class AgendaItemViewComponent implements OnInit, OnDestroy {
  specialEventsToggle = AGENDA_ITEM_SETTINGS.SPECIAL_EVENTS_TOGGLE;

  /**
   * The page's copy content
   */
  content: any;

  /**
   * Flags whether or not it is edit fields mode
   */
  @Input() editForm = false;

  /**
   * Indicator if the activity will be associated to an objective
   *
   */
  @Input() hasObjectiveRelationship = false;

  /**
   * The weekday label shown on the modal
   */
  weekDayLabel: string;

  /**
   * Flag whether or not the event is week type
   */
  isWeekEventType = true;

  /**
   * Component Config
   */
  config: AgendaItemViewConfig;

  /**
   * It represents the service account state
   */
  serviceAccount: ServiceAccount;

  /**
   * It displays the view more details button
   */
  @Input()
  showDetailsBtn = false

  /**
   * Agenda Item
   */
  @Input()
  agendaItem: Agenda = {
    completed: false,
    title: '',
    weekDay: '',
    content: '',
    endHour: '',
    endMin: '',
    startHour: '',
    startMin: ''
  };

  /**
   * It emits an event click when the save operation is completed
   */
  @Output() onSaveCompletedEvent = new EventEmitter();

  /**
   * Pillar array for the selection component
   */
  pillarArray: Array<OptionModel> = [];

  /**
   * Main component's form
   */
  form = new FormGroup({});

  /**
   * The objective call subscription
   */
  objectiveSubscription: Subscription;

  /**
   * Objective array for the selection component
   */
  objectiveDropDownArray: Array<OptionModel> = [];

  /**
   * Objective array
   */
  objectiveArray: Array<ObjectiveModel> = [];

  /**
   * Tracks the current selection for the pillar dropdown
   */
  selectedPillar = '';

  /**
   * Tracks the current selection for the objective dropdown
   */
  selectedObjetiveId = '';

  /**
   * The pillar call subscription
   */
  pillarSubscription: Subscription;

  constructor(
    private translate: TranslateService,
    private agendaItemViewConfig: AgendaItemViewConfig,
    private store: Store,
    public dialogRef: MatDialogRef<AgendaItemViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private router: Router,
    private objectiveApi: ObjectiveApiServiceV2,
    private pillarApi: PillarApiService,
    private pdpfPartyService: PdpfPartyService
  ) {}

  ngOnInit(): void {
    this.store.select(selectServiceAccount).subscribe(serviceAccount => this.serviceAccount = serviceAccount);

    moment.locale(AppLang.DEFAULT);
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = this.agendaItemViewConfig.content;
    this.config = this.agendaItemViewConfig;

    if (this.data?.hasObjectiveRelationship) {
      this.hasObjectiveRelationship = true;
    }

    if (this.hasObjectiveRelationship) {
      this.pillarSubscription = this.pillarApi.getPillarsStream(this.serviceAccount?.profileRef ?? '').subscribe(
        pillars => (pillars.forEach(pillar => {
          this.pillarArray.push({
            value: pillar.pillarId,
            viewValue: pillar.title
          });
        })
      ));
    }

    // If user is editing the item
    if (this.data?.agendaItem) {
      this.agendaItem = _.cloneDeep(this.data['agendaItem']);
      this.editForm = true;
      this.showDetailsBtn = true;

      if (this.hasObjectiveRelationship) {
        this.selectedObjetiveId = this.agendaItem?.objectiveId ?? '';
        this.onSelectPillarHandler({ value: this.agendaItem?.pillarId ?? '' });
      }
    }

    this.weekDayLabel = !this.editForm
      ? moment().day(this.agendaItem.weekDay).format('dddd') : this.content.lblWeekDay;

    this.config.pillarsCheckBox = this.content.components.pillarsCheckBox as Array<any>;
  }

  ngOnDestroy(): void {
    this.editForm = false;

    if (this.objectiveSubscription) {
      this.objectiveSubscription.unsubscribe();
    }

    if (this.pillarSubscription) {
      this.pillarSubscription.unsubscribe();
    }
  }

  /**
   * It returns the selected week day if it's an existing activity
   */
  get selectedWeekDay() {
    const selectedDay = this.config.weekOptions.filter(date => date.value === this.agendaItem?.weekDay);
    return selectedDay.length > 0 ? selectedDay[0].value : '';
  }

  /**
   * It updates the selection value
   * @param checkBoxName string
   */
  onCheckPillarBox(event, checkBoxName: string) {
    if (event && checkBoxName && typeof checkBoxName === 'string') {
      _.each(this.config.pillarsCheckBox, (value, key) => {
        if (value.resource === checkBoxName) {
          value.selected = event.checked;
        }
      });
    }
  }

  get objective() {
    if (this.editForm && this.objectiveDropDownArray.length > 0) {
      const objective = _.find(this.objectiveArray, (objective) => {
        return objective.objectiveId = this.agendaItem.objectiveId;
      }) as ObjectiveModel;

      return _.find(this.objectiveDropDownArray, ['value', objective?.objectiveId ?? ''])?.value ?? '';
    }

    return '';
  }

  /**
   * It filters the objectives by the selected pillar
   * @param event MatEvent
   */
  onSelectPillarHandler(event) {
    if (event?.value && (this.selectedPillar !== event?.value)) {
      this.selectedPillar = event?.value;
      this.objectiveDropDownArray = [];
      this.objectiveArray = [];

      this.objectiveSubscription =
        this.objectiveApi.getObjectivesByPillarNode(event?.value, this.serviceAccount?.profileRef ?? '').subscribe(
          objectives => objectives.forEach(objective => {
            this.objectiveArray.push(objective);
            this.objectiveDropDownArray.push({
              value: objective.objectiveId,
              viewValue: objective.description
            })
          })
        );
    }
  }

  /**
   * It updates the current objective selection
   * @param event
   */
  onSelectObjectiveHandler(event) {
    if (event?.value && (this.selectedObjetiveId !== event?.value)) {
      this.selectedObjetiveId = event?.value;
    }
  }

  get selectCurrentTimeStart() {
    if (!this.editForm) {
      const now = moment().hour();
      const query = _.find(this.config.timeHourOption, ['viewValue', now.toString()]);
      const selectedTime = query?.value ?? '12';

      if (!this.agendaItem.startHour) {
        this.agendaItem.startHour = selectedTime.toString();
      }

      return selectedTime;
    }

    return _.find(this.config.timeHourOption, ['viewValue', this.agendaItem?.startHour ?? ''])?.value ?? '';
  }

  /**
   * WeekDay selection event handler
   * @param event MatEvent
   */
  onWeekDaySelectionChange(event) {
    this.agendaItem.weekDay = _.find(this.config.weekOptions, ['value', event.value]).value;
  }

  /**
   * Update the check event type model
   * @param event boolean
   */
  onWeekTypeChange(event) {
    this.isWeekEventType = event.checked;
  }

  /**
   * It returns the current time to the time selection dropdown
   */
  get selectCurrentTimeEnd() {
    if (!this.editForm) {
      let timeNow = moment().hour();

      if (timeNow === 23) {
        timeNow = 0;
      } else {
        timeNow++;
      }

      const query = _.find(this.config.timeHourOption, ['viewValue', timeNow.toString()]);
      const selectedTime = query?.value ?? '12';

      if (!this.agendaItem.endHour) {
        this.agendaItem.endHour = selectedTime.toString();
      }

      return selectedTime;
    }

    return _.find(this.config.timeHourOption, ['viewValue', this.agendaItem?.endHour ?? ''])?.value ?? '';
  }

  get selectCurrentTimeMinStart() {
    if (!this.editForm) {
      if (!this.agendaItem.startMin) {
        this.agendaItem.startMin = this.config.timeMinOption[0].viewValue.toString();
      }

      return this.config.timeMinOption[0].value;
    }

    return _.find(this.config.timeMinOption, ['viewValue', this.agendaItem?.startMin ?? ''])?.value ?? '';
  }

  get selectCurrentTimeMinEnd() {
    if (!this.editForm) {
      if (!this.agendaItem.endMin) {
        this.agendaItem.endMin = this.config.timeMinOption[0].viewValue.toString();
      }

      return this.config.timeMinOption[0].value;
    }

    return _.find(this.config.timeMinOption, ['viewValue', this.agendaItem?.endMin ?? ''])?.value ?? '';
  }

  /**
   * Defines the start of the event
   * @param event selected hour to start event
   */
  onHourStartSelectionChange(event) {
    this.agendaItem.startHour = this.config.timeHourOption[event.value].viewValue.toString();
  }

  /**
   * Defines the start of the event
   * @param event selected min to start event
   */
  onMinStartSelectionChange(event) {
    this.agendaItem.startMin = this.config.timeMinOption[event.value].viewValue.toString();
  }

  /**
   * Defines the end of the event
   * @param event selected hour to end event
   */
  onHourEndSelectionChange(event) {
    this.agendaItem.endHour = this.config.timeHourOption[event.value].viewValue.toString();
  }

  /**
   * Defines the end of the event
   * @param event selected min to end event
   */
  onMinEndSelectionChange(event) {
    this.agendaItem.endMin = this.config.timeMinOption[event.value].viewValue.toString();
  }

  /**
   *
   * @param event Change event
   * @param field The field name
   */
  onChangeEventHandler(event, field: string) {
    if (event && field && typeof 'event' === 'string') {
      this.agendaItem[field] = event;
    }
  }

  /**
   * Adds the item into the agenda
   * @param $event Click event
   */
  onAddClick() {
    this.pdpfPartyService.validateFormFields(this.form);

    if (this.form.valid && this.agendaItem) {
      if (this.hasObjectiveRelationship) {
        this.agendaItem.objectiveId = this.selectedObjetiveId ?? '';
        this.agendaItem.pillarId = this.selectedPillar ?? '';

        const objective = _.find(this.objectiveArray, (objective) => {
          return objective.objectiveId = this.agendaItem.objectiveId;
        }) as ObjectiveModel;

        this.agendaItem.endDate = objective.endDate ?? 'The end date is not defined';
        this.agendaItem.type = ACTIVITY_TYPE_OBJECTIVE_ITEM.type;

        this.store.dispatch(addAgendaItem({
          agenda: {
            ...this.agendaItem,
            profileId: this.serviceAccount?.profileRef ?? ''
          }
        }));
      } else {
        const startDate = new Date((new Date().getTime() - 3888000000));
        this.agendaItem.endDate = new Date(startDate.getFullYear() + 1, startDate.getMonth(), startDate.getDate());
        this.agendaItem.type = ACTIVITY_TYPE_REGULAR_ITEM.type;

        this.store.dispatch(addAgendaItem({
          agenda: {
            ...this.agendaItem,
            profileId: this.serviceAccount?.profileRef ?? ''
          }
        }));
      }

      this.onSaveCompletedEvent.emit();

      if (this.editForm && this.data['agendaItem']) {
        this.dialogRef.close();
      } else {
        this.router.navigate([RouteName.AGENDA]);
      }

      this.agendaItem = {
        completed: false,
        title: '',
        weekDay: '',
        content: '',
        endHour: '',
        endMin: '',
        startHour: '',
        startMin: '',
        objectiveId: '',
        pillarId: ''
      };
    }
  }

  get pillar() {
    if (this.editForm && this.pillarArray.length > 0) {
      return _.find(this.pillarArray, ['value', this.agendaItem?.pillarId ?? ''])?.value ?? '';
    }

    return '';
  }

  /**
   * It removes an activity from the agenda
   * @param agendaItem Agenda
   */
  onRemoveClick() {
    const confirmationDialog = this.dialog.open(SaveDataConfirmationComponent, {
      data: {
        confirm: true,
      },
    });

    confirmationDialog.afterClosed().subscribe((res) => {
      if (res) {
        this.store.dispatch(deleteAgendaItem({ idField: this.agendaItem.idField }));
        this.dialogRef.close();
      }
    });
  }

  /**
   * It opens app the note popup for addition
   */
  onAddNoteClick() {
    const notesDialog = this.dialog.open(PsmNotesComponent, {
      data: {
        title: this.agendaItem.title ?? ''
      }
    });

    notesDialog.afterClosed().subscribe(
      notePayload => {
        if (notePayload) {
          this.store.dispatch(addNoteApi({
            note: {
              note: notePayload,
              parentId: this.agendaItem.idField ?? '',
              parentType: 'agenda_activity',
              profile: this.serviceAccount?.profileRef ?? ''
            }
          }));
        }
      }
    );
  }

  /**
   * It routes to itself
   */
  onViewDetails() {
    this.dialogRef.close();
    this.router.navigate([RouteName.AGENDA_ACTIVITY_DETAIL, {
      idField: this.agendaItem?.idField ?? ''
    }]);
  }
}
