import { Component, OnInit, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'psm-home-card',
  templateUrl: './psm-home-card.component.html',
  styleUrls: ['./psm-home-card.component.scss']
})
export class PsmHomeCardComponent implements OnInit {

  /**
   * Title for the card
   */
  @Input() title: string;

  /**
   * Subtitle string if any
   *
   * @type {string}
   * @memberof PsmHomeCardComponent
   */
  @Input() subTitle: string;

  /**
   * It represents for which pillar attribute a card is shown
   * Each pillar type has its own border color
   */
  @Input() pillarId: string;

  /**
   * A hover background color is shown if the card is set to be selected
   */
  @Input() isSelected = false;

  constructor() { }

  ngOnInit(): void {
  }

}
