import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { SaveDataConfirmationComponent } from 'src/app/pages/dialogs/common/save-data-confirmation/save-data-confirmation.component';
import { GoalApiService } from 'src/app/services/firebase/goal/goal-api.service';
import { addNoteApi } from 'src/app/state-v2/action/notes.actions';
import { addObjectiveDocument } from 'src/app/state-v2/action/objective.actions';
import { Pillar } from 'src/app/state-v2/model/Pillar.model';
import { ObjectiveModel } from 'src/app/state-v2/model/objective.model';
import { ServiceAccount } from 'src/app/state-v2/model/service-account.model';
import { GoalState } from 'src/app/state-v2/reducer/goal.reducer';
import { selectServiceAccount } from 'src/app/state-v2/selector/service-accont.selector';
import { AppLang } from 'src/assets/i18n/app-lang';
import { RouteName } from 'src/shared/router-paths';
import { PsmNotesComponent } from '../psm-notes/psm-notes.component';
import PT from './i18n/pt.json';

@Component({
  selector: 'psm-objective-view',
  templateUrl: './psm-objective-view.component.html',
  styleUrls: ['./psm-objective-view.component.scss']
})
export class PsmObjectiveViewComponent implements OnInit {

  /**
   * Represents the goalid of the objective(s)
   *
   * @memberof PsmObjectiveViewComponent
   */
  @Input() goalId;

  /**
   * It defines the title at the top of the card
   */
  @Input() title;

  /**
   * The goal object data item to be shown on the screen
   *
   */
  @Input() objective: ObjectiveModel = {};

  /**
   * Object representing the selected pillar
   */
  @Input() pillar: Pillar = {};

  /**
   * If true it displays the controls to add notes, close the goal and add pictures
   *
   */
  @Input() showControls = false;

  /**
   * It represents the copy content of the page
   *
   */
  content: any;

  /**
   * It represents the Goal of the objective being displayed
   *
   * @type {GoalState}
   * @memberof PsmObjectiveViewComponent
   */
  goal: GoalState;

  /**
   * Service Account State
   */
  serviceAccount: ServiceAccount;

  constructor(
    private translate: TranslateService,
    private store: Store,
    private router: Router,
    private dialog: MatDialog,
    private goalApi: GoalApiService
  ) { }

  ngOnInit(): void {
    this.store.select(selectServiceAccount).subscribe(serviceAccount => this.serviceAccount = serviceAccount);

    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;

    if (this.objective?.goalId) {
      this.goalApi.getGoalByGoalId(this.objective.goalId)
        .pipe(take(1))
        .subscribe(goals => goals.forEach(goal => this.goal = goal));
    }
  }

  /**
   * It edits the selected goal
   */
  onEditClick(objective: ObjectiveModel) {
    this.router.navigate([
      RouteName.OBJECTIVE_FORM,
      {
        objectiveId: objective.objectiveId,
        pillarNode: this.pillar.pillarId,
        goalId: this.goalId
      },
    ]);
  }

  /**
   * It removes the document from firestore
   * @param index The array index for the given goal
   */
  onRemoveClick(objective: ObjectiveModel) {
    if (!objective) console.error('Objective not found');
    if (objective) {
      const dialogRef = this.dialog.open(SaveDataConfirmationComponent, {
        data: {
          confirm: true,
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          const objectiveDoc = Object.assign({}, this.objective);
          this.store.dispatch(addObjectiveDocument({
            objectiveDoc: {
              ...objectiveDoc,
              idField: objectiveDoc?.idField,
              active: false,
              profileId: this.serviceAccount?.profileRef ?? ''
            }
          }
        ));
        }
      });
    }
  }

  /**
   * It launches the dialog to add the note
   */
  onAddGoalNoteClick(objective: ObjectiveModel) {
    const notesDialog = this.dialog.open(PsmNotesComponent, {
      data: {
        title: objective.description ?? ''
      }
    });

    notesDialog.afterClosed().subscribe(
      notePayload => {
        if (notePayload) {
          this.store.dispatch(addNoteApi({
            note: {
              note: notePayload,
              parentId: objective.objectiveId ?? '',
              parentType: 'objective',
              profile: this.serviceAccount?.profileRef ?? ''
            }
          }));
        }
      }
    );
  }

}
