import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/firebase/authentication/auth.service';
import { TokenService } from 'src/app/services/token/token.service';
import { AppLang } from 'src/assets/i18n/app-lang';
import { RouteName } from 'src/shared/router-paths';
import { AccountActivationFormInvalidDialogComponent } from '../dialogs/account-activation/account-activation-form-invalid-dialog/account-activation-form-invalid-dialog.component';
import { AccountInUseDialogComponent } from '../dialogs/account-activation/account-in-use-dialog/account-in-use-dialog.component';
import { MasterContentPage } from '../master-content/master-content.class.page';
import { AccountActivationConfig } from './account-activation.config';

import { Store } from '@ngrx/store';
import { first, map } from 'rxjs/operators';
import { ProfileServiceV2 } from 'src/app/services/firebase/profile/profile.service';
import { loginSession } from 'src/app/state-v2/action/login.actions';
import { setPillarCollection } from 'src/app/state-v2/action/pillar.actions';
import { setProfile } from 'src/app/state-v2/action/profile.actions';
import { activateServiceAccount } from 'src/app/state-v2/action/service-account.actions';
import { Pillar } from 'src/app/state-v2/model/pillar.model';
import { selectPillar } from 'src/app/state-v2/selector/pillar.selector';
import { selectProfile } from 'src/app/state-v2/selector/profile.selector';
import PT from './i18n/pt.json';
import { selectComponent } from 'src/app/state-v2/selector/component.selector';


/**
 * Interface used to map the form
 */
export interface AccActivationModel {
  email?: string;
  token?: string;
  password?: string;
  passwordConfirm?: string;
}

@Component({
  selector: 'app-account-activation',
  templateUrl: './account-activation.component.html',
  styleUrls: ['./account-activation.component.scss']
})
export class AccountActivationComponent implements OnInit {

  config: AccountActivationConfig;
  page: MasterContentPage;
  form = new FormGroup({});
  tokenState: any;
  content: any;

  /**
   * Pillar state
   */
  pillars: Pillar[];

  /**
   * Account activation model
   */
  accActivationModel: AccActivationModel = {
    email: '',
    password: '',
    passwordConfirm: '',
    token: ''
  };

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private route: Router,
    private translate: TranslateService,
    private tokenService: TokenService,
    private accountActivationConfig: AccountActivationConfig,
    private store: Store,
    private profileService: ProfileServiceV2
  ) { }

  ngOnInit(): void {
    this.store.select(selectPillar).pipe(
      first()
    ).subscribe(pillars => this.pillars = pillars);

    this.config = this.accountActivationConfig;
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;
    this.store.select(selectComponent).subscribe(state => this.tokenState = state.tokenStatus);
    this.page = new MasterContentPage(
      false,
      true,
      'assets/images/svg/pdpf-logo-vert.svg',
      this.content.title,
      ['']
    );
  }

  /**
   * Captures the email input change
   */
  emailStatusChange(event: string) {
    const frmCtrl = this.form.get(this.config.emailControl);
    if (frmCtrl && frmCtrl.valid) {
      this.accActivationModel.email = event;
    }
  }

  /**
   * Captures the token input change
   */
  tokenStatusChange(event: string) {
    const frmCtrl = this.form.get(this.config.tokenControl);
    if (frmCtrl && frmCtrl.valid) {
      this.accActivationModel.token = event;
    }
  }

  /**
   * Captures the password input change
   */
  passwordStatusChange(event: string) {
    const frmCtrl = this.form.get(this.config.passwordControl);
    if (frmCtrl && frmCtrl.valid) {
      this.accActivationModel.password = event;
    }
  }

  /**
   * Captures the passwordConfirm input change
   */
  passwordConfirmStatusChange(event: string) {
    const frmCtrl = this.form.get(this.config.passwordConfirmControl);
    if (frmCtrl && frmCtrl.valid) {
      this.accActivationModel.passwordConfirm = event;
    }
  }

  /**
   * Submits the token registration and user registration to firestore
   */
  onSubmitForm() {
    this.spinner.show();

    if (this.form.invalid || (this.accActivationModel.password.localeCompare(this.accActivationModel.passwordConfirm) !== 0)) {
      this.dialog.open(AccountActivationFormInvalidDialogComponent);
      this.spinner.hide();
      return;
    }

    this.tokenService.validateToken(this.accActivationModel.token);
    this.profileService.getProfileByEmail(this.accActivationModel.email)
      .pipe(
        first(),
        map(e => {
          return e.empty;
        })
      ).subscribe(isEmpty => {
        if (isEmpty) {
          this.setFirestore(this.accActivationModel.token);
        } else {
          this.dialog.open(AccountInUseDialogComponent);
          this.spinner.hide();
        }
      });
  }

  /**
   * Set the Firestore profile
   * @param token string
   */
  setFirestore(token?: string) {
    if (this.tokenState && !this.tokenState.status && this.tokenState.msg !== undefined) {
      this.spinner.hide();
      this.route.navigate([RouteName.TOKEN_VALIDATION_ERROR]);
      return;
    }

    this.authService.register(this.accActivationModel.email, this.accActivationModel.password)
      .then(e => {
        this.store.dispatch(setProfile({
          profile: {
            active: true,
            completed: false,
            createDTTM: new Date(),
            email: this.accActivationModel.email,
            lastUpdateDTTM: new Date(),
            uid: e && e.user ? e.user.uid : '',
            personalInfo: {
              birthCountry: '',
              currentCountry: '',
              lastName: '',
              name: '',
              dob: '',
              gender: '',
              phoneNumber: ''
            }
          }
        }));

        this.store.select(selectProfile).pipe(
          first()
        ).subscribe(profile => {
          if (profile?.idField) {
            this.store.dispatch(setPillarCollection({
              pillars: { ...this.pillars },
              profileId: profile?.idField
            }));
          }

          this.store.dispatch(activateServiceAccount({
            email: this.accActivationModel.email,
            token
          }));
        });

        this.store.dispatch(loginSession());
        this.route.navigate([RouteName.CONGRATULATION_PAGE]);
        this.spinner.hide();
      })
      .catch(e => console.error(e));
  }

  /**
   * Routes back to the landing page
   */
  public onBackBtnAction() {
    this.route.navigate([RouteName.LANDING_PAGE]);
  }

}
