<app-master-content [masterContentPage]="page">
  <div class="p-flex-column w-full">
    <mat-card class="card-margin">
      <mat-card-header>
        <div
          mat-card-avatar
          class="card-order-avatar"
        ></div>
        <mat-card-title>
          {{ content.lblReorderTitle }}
        </mat-card-title>
        <mat-card-subtitle>
          {{content.lblReorderDesc}}
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div cdkDropList class="order-list" (cdkDropListDropped)="drop($event)">
          <!-- <div class="order-box" *ngFor="let goal of pillarModel.goals; let i = index" cdkDrag>
            <div class="order-placeholder" *cdkDragPlaceholder></div>
            <mat-chip-list>
              <mat-chip color="primary" selected>
                {{ i + 1 }}
              </mat-chip>
            </mat-chip-list>
            <span class="text-side-margin-medium">
              {{goal.header}}
            </span>
          </div> -->
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</app-master-content>
