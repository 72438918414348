export const ACTION = {
  PARENT_ACTION: {
    SELF: {
      name: 'PILLAR_ESSENCE',
      active: false,
    },
    HOME: {
      name: 'HOME',
      active: false
    },
    PILLAR_GOALS: {
      name: 'PILLAR_GOALS',
      active: false
    },
    APP_MENU: {
      name: 'APP_MENU',
      active: false
    },
    ROUTE_ERROR: {
      name: 'ROUTE_ERROR',
    }
  }
};
