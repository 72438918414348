import { Component, OnInit, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'psm-form-field',
  templateUrl: './psm-form-field.component.html',
  styleUrls: ['./psm-form-field.component.scss']
})
export class PsmFormFieldComponent implements OnInit {

  @Input() title: string;

  @Input() hint: string;

  constructor() { }

  ngOnInit(): void {
  }

}
