import { Injectable } from '@angular/core';
import _ from 'lodash';
import { Pillar } from 'src/app/state-v2/model/pillar.model';
import PILLAR_CONFIG from '../../../../shared/data-config/pillar-config.json';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class PdpfPartyService {

  constructor(
  ) { }

  /**
   * It provides the main Node for a pillar
   * @param title - The pillar title
   */
  getPillarNodeByTitle(title: string): string {
    switch (title) {
      case PILLAR_CONFIG.PILLARS.FAMILY.title:
        return 'FAMILY';
      case PILLAR_CONFIG.PILLARS.HEALTH.title:
        return 'HEALTH';
      case PILLAR_CONFIG.PILLARS.SELF_DEVELOPMENT.title:
        return 'SELF_DEVELOPMENT';
      case PILLAR_CONFIG.PILLARS.WORK.title:
        return 'WORK';
      default:
        console.error('Pillar title is not defined');
        return undefined;
    }
  }

  getPillarConfigByTitle(title: string) {
    switch (title) {
      case PILLAR_CONFIG.PILLARS.FAMILY.title:
        return PILLAR_CONFIG.PILLARS.FAMILY;
      case PILLAR_CONFIG.PILLARS.HEALTH.title:
        return PILLAR_CONFIG.PILLARS.HEALTH;
      case PILLAR_CONFIG.PILLARS.SELF_DEVELOPMENT.title:
        return PILLAR_CONFIG.PILLARS.SELF_DEVELOPMENT;
      case PILLAR_CONFIG.PILLARS.WORK.title:
        return PILLAR_CONFIG.PILLARS.WORK;
      default:
        console.error('Pillar title is not defined');
        return undefined;
    }
  }

  /**
   * The way the pillars must be shown in the UI makes this required.
   * @param pillars Array<PillarModel>
   * @returns An ordered array to be displayed in UI
   */
  reOrderPillarsToDisplay(pillars: Array<Pillar>) {
    if (pillars.length > 2) {
      const newArr = _.slice(pillars, 2, pillars.length);
      _.reverse(newArr);
      _.each(newArr, (value, key) => {
        _.pull(pillars, value);
      });

      return _.concat(pillars, newArr);
    }
  }

  /**
   * It returns the formatted date for the firebase
   */
  getFormattedDate(date: Date): string {
    const mm = date.getMonth() + 1;
    const dd = date.getDate();
    return [(mm > 9 ? '' : '0') + mm + '/', (dd > 9 ? '' : '0') + dd + '/', date.getFullYear()].join('');
  }

  /**
   * It returns a random string to be used as ID
   * @param profileId
   * @param idType
   * @returns
   */
  generateId(profileId: string, idType?: string): string {
    const id = Math.random().toString(16).slice(2) + (new Date()).getTime();
    if (!profileId) return id;
    return idType ? profileId + id + Array.from(idType.toLocaleUpperCase())[0] : profileId + id;
  }

  /**
   * Utility to retrieve the object from the firestore array Docs
   */
  getFirestoreData(docRef: any, key: string, value: string): unknown {
    return _.find(docRef.docs, (doc) => {
      if (doc.data()[key] === value) {
        return {
          ...doc.data(),
          idField: doc.ref.id
        };
      }
    });
  }

  validateFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched();
      } else if (control instanceof FormGroup) {
        this.validateFormFields(control);
      }
    });
  }

}
