import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import MOMENT from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { PdpfPartyService } from 'src/app/services/common/pdpf-party/pdpf-party.service';
import { GoalApiService } from 'src/app/services/firebase/goal/goal-api.service';
import { PillarApiService } from 'src/app/services/firebase/pillar/pillar-api.service';
import { addGoalDocument } from 'src/app/state-v2/action/goal.actions';
import { Pillar } from 'src/app/state-v2/model/Pillar.model';
import { ServiceAccount } from 'src/app/state-v2/model/service-account.model';
import { GoalState } from 'src/app/state-v2/reducer/goal.reducer';
import { selectServiceAccount } from 'src/app/state-v2/selector/service-accont.selector';
import { AppLang } from 'src/assets/i18n/app-lang';
import { RouteName } from 'src/shared/router-paths';
import { SaveDataConfirmationComponent } from '../../dialogs/common/save-data-confirmation/save-data-confirmation.component';
import { MasterContentPage } from '../../master-content/master-content.class.page';
import { ACTION } from './action/action';
import { GoalFormConfig } from './goal-form.config';
import PT from './i18n/pt.json';

@Component({
  selector: 'app-goal-form',
  templateUrl: './goal-form.component.html',
  styleUrls: ['./goal-form.component.scss'],
})
export class GoalFormComponent implements OnInit {

  /**
   * Goal object for screen edition
   *
   * @type {GoalState}
   * @memberof GoalFormComponent
   */
  goal: GoalState = {
    idField: '',
    active: true,
    priority: 0,
    goalId: '',
    creationDate: '',
    description: '',
    expireDate: '',
    favorite: false,
    header: '',
    length: 5,
    reality: '',
    reason: '',
    goalResAvailable: [
      {
        resource: 'Dinheiro',
        selected: false,
      },
      {
        resource: 'Infraestrutura',
        selected: false,
      },
      {
        resource: 'Informação',
        selected: false,
      },
      {
        resource: 'Pessoas',
        selected: false,
      },
      {
        resource: 'Tempo',
        selected: false,
      },
    ],
    goalResRequired: [
      {
        resource: 'Dinheiro',
        selected: false,
      },
      {
        resource: 'Infraestrutura',
        selected: false,
      },
      {
        resource: 'Informação',
        selected: false,
      },
      {
        resource: 'Pessoas',
        selected: false,
      },
      {
        resource: 'Tempo',
        selected: false,
      },
    ],
  };

  /**
   * It represents the current pillar selection
   *
   * @type {Pillar}
   * @memberof GoalFormComponent
   */
  pillar: Pillar = {
    order: 0,
    pillarId: '',
    shortTitle: '',
    title: ''
  };

  content: any;
  formStep1 = new FormGroup({});
  formStep2 = new FormGroup({});
  page: MasterContentPage;

  /**
   * Config file for the forms on the screen
   *
   * @type {GoalFormConfig}
   * @memberof GoalFormComponent
   */
  config: GoalFormConfig;

  /**
   * The representation of the pillar document from firestore
   *
   * @type {AngularFirestoreDocument<Pillar>}
   * @memberof GoalComponent
   */
  private pillars: Observable<Pillar[]>;

  /**
   * Goal array from firestore
   *
   * @type {Observable<GoalState[]>}
   * @memberof GoalComponent
   */
  private goals: Observable<GoalState[]>;

  /**
   * It represents the current pillar selection
   *
   * @type {string}
   * @memberof GoalComponent
   */
  pillarNode: string;

  /**
   * The doc id in case it's an edit
   *
   * @type {string}
   * @memberof GoalFormComponent
   */
  goalId: string;

  /**
  * Service Account State
  */
  serviceAccount: ServiceAccount;

  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private goalFormConfig: GoalFormConfig,
    private spinner: NgxSpinnerService,
    private pdpfPartyService: PdpfPartyService,
    private store: Store,
    private goalApi: GoalApiService,
    private pillarApi: PillarApiService
  ) {}

  ngOnInit(): void {
    this.store.select(selectServiceAccount).subscribe(serviceAccount => this.serviceAccount = serviceAccount);

    this.pillarNode = this.route.snapshot.paramMap.get('pillar') ?? '';
    this.goalId = this.route.snapshot.paramMap.get('goalId') ?? undefined;

    MOMENT.locale(AppLang.DEFAULT);
    this.config = this.goalFormConfig;
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;

    if (this.pillarNode) {
      this.pillars = this.pillarApi.getPillarStream(this.pillarNode, this.serviceAccount?.profileRef ?? '');
      this.pillars.pipe(take(1)).subscribe(pillars => {
        pillars.forEach(pillar => this.pillar = pillar);
      });
    }

    // If there is one id, it's an update operation
    if (this.goalId) {
      this.goals = this.goalApi.getGoalByGoalId(this.goalId);
      this.goals.subscribe(goals => {
        goals.forEach(goal => this.goal = goal);
      });
    }

    //TODO: To figure out back routing
    // this.backFowardToPillarGoal(false);
  }

  /**
   * It updates the selection value
   * @param checkBoxName string
   */
  onCheckResAvailable(event, checkBoxName: string, isResAvailable?: boolean) {
    if (event && checkBoxName && typeof checkBoxName === 'string') {
      const resourceList = isResAvailable
        ? this.goal.goalResAvailable
        : this.goal.goalResRequired;

      _.each(resourceList, (value, key) => {
        if (value.resource === checkBoxName) {
          value.selected = event.checked;
        }
      });
    }
  }

  /**
   * Dispacth an error message.
   * @param errorMessage string
   */
  private dispatchError(errorMessage: string) {
    console.error(errorMessage);
    this.router.navigate([
      RouteName.GOAL,
      {
        action: ACTION.PARENT_ACTION.ROUTE_ERROR,
      },
    ]);
  }

  /**
   * It updates the title model
   * @param event string
   */
  onChangeStatus(event, field: string) {
    if (event && typeof event === 'string') {
      this.goal[field] = event;
    }
  }

  /**
   * Updates the store and firestore
   */
  onSubmitForm(goalDoc: GoalState) {
    if (this.formStep2.valid) {
      const dialogRef = this.dialog.open(SaveDataConfirmationComponent, {
        data: {
          confirm: true,
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.spinner.show();
          const goalId = this.pdpfPartyService.generateId(this.serviceAccount?.profileRef, 'goal');
          const today = new Date();

          // TODO: Add priority option on the screen for selection
          this.store.dispatch(addGoalDocument({
            goal: {
              idField: goalDoc.idField,
              goalId,
              active: true,
              creationDate: new Date(),
              expireDate: new Date(today.getFullYear() + 5, today.getMonth(), today.getDate()),
              description: goalDoc.description,
              favorite: false,
              header: goalDoc.header,
              length: goalDoc.length,
              profileId: this.serviceAccount?.profileRef,
              pillarNode: this.pillarNode,
              priority: 0,
              reality: goalDoc.reality,
              reason: goalDoc.reason,
              goalResAvailable: _.filter(goalDoc.goalResAvailable, ['selected', true]),
              goalResRequired: _.filter(goalDoc.goalResRequired, ['selected', true])
            }
          }));

          this.router.navigate([
            RouteName.GOAL,
            {
              pillar: this.pillarNode
            },
          ]);

          this.spinner.hide();
        }
      });
    }
  }

}
