import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { setPillarCollection } from 'src/app/state-v2/action/pillar.actions';
import { Pillar } from 'src/app/state-v2/model/pillar.model';
import { ServiceAccount } from 'src/app/state-v2/model/service-account.model';
import { selectPillar } from 'src/app/state-v2/selector/pillar.selector';
import { selectServiceAccount } from 'src/app/state-v2/selector/service-accont.selector';
import { AppLang } from 'src/assets/i18n/app-lang';
import { RouteName } from 'src/shared/router-paths';
import { PdpfPartyService } from '../../services/common/pdpf-party/pdpf-party.service';
import { SaveDataConfirmationComponent } from '../dialogs/common/save-data-confirmation/save-data-confirmation.component';
import { PillarCreationDialogComponent } from '../dialogs/pillar-creation-dialog/pillar-creation-dialog.component';
import { MasterButton } from '../master-content/master-content.class.button';
import { MasterContentPage } from '../master-content/master-content.class.page';
import { ACTION } from './action/action';
import PT from './i18n/pt.json';

@Component({
  selector: 'app-pillar-creation',
  templateUrl: './pillar-creation.component.html',
  styleUrls: ['./pillar-creation.component.scss']
})
export class PillarCreationComponent implements OnInit, OnDestroy {

  page: MasterContentPage;
  form = new FormGroup({});

  /**
   * The pillar state
   */
  pillarState: Pillar[];
  pillarArrDisplay;

  routeAction: string;
  content: any;
  exampleBanner: string;

  /**
   * The service account representation
   */
  serviceAccount: ServiceAccount

  constructor(
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private pdpfParty: PdpfPartyService,
    private store: Store
  ) { }

  ngOnDestroy(): void {
    if (this.routeAction) {
      ACTION.PARENT_ACTION[this.routeAction].active = false;
    }
  }

  ngOnInit(): void {
    this.store.select(selectServiceAccount).subscribe(serviceAccount => this.serviceAccount = serviceAccount);
    this.store.select(selectPillar).subscribe(pillars => {
      this.pillarState = [...pillars];
      this.pillarArrDisplay = this.pdpfParty.reOrderPillarsToDisplay(Array.from(pillars).slice());
    });

    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;
    this.exampleBanner = 'assets/images/imgs/information.svg';

    this.routeAction = this.route.snapshot.paramMap.get('action')
      ? this.route.snapshot.paramMap.get('action') : '';

    if (this.routeAction) {
      ACTION.PARENT_ACTION[this.routeAction].active = true;
    }

    if (ACTION.PARENT_ACTION.USER_PHONE_FORM.active) {
      this.dialog.open(PillarCreationDialogComponent);
    }

    this.page = new MasterContentPage(
      false,
      true,
      'assets/images/svg/pdpf-logo-vert-icon.svg',
      this.content.title.txt1,
      [
        this.content.steps.step1.txt1 + this.content.steps.step1.txt2,
        this.content.steps.step2.txt1 + this.content.steps.step2.txt2
      ],
      new MasterButton(ACTION.PARENT_ACTION.APP_MENU.active
        ? this.content.btnSave : this.content.btnNext),
      new MasterButton(this.content.btnClose)
    );
  }

  /**
   * It reorders the item order property and saves the store
   */
  onSubmit(event) {
    const dialogRef = this.dialog.open(SaveDataConfirmationComponent, {
      data: {
        confirm: true
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.store.dispatch(setPillarCollection({
          profileId: this.serviceAccount?.profileRef,
          pillars: this.pillarState
        }));

        this.router.navigate([RouteName.HOME_VIEW]);
      }
    });
  }

  /**
   * It is the drag and drop event
   */
   drop(event: CdkDragDrop<any[]>) {
      moveItemInArray(this.pillarState, event.previousIndex, event.currentIndex);
      this.pillarArrDisplay = this.pdpfParty.reOrderPillarsToDisplay(Array.from(this.pillarState).slice());
    }

  /**
   * Routes back to the home page
   */
  onCloseClick(event) {
    this.router.navigate([RouteName.HOME_VIEW]);
  }

}
