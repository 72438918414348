<app-master-content
  [masterContentPage]="page"
  [form]="form"
  (btnBackwardEvent)="btnBackwardEvent($event)"
  (btnForwardEvent)="btnForwardEvent($event)"
>
  <div class="p-flex-column w-full p-mt-4">
    <mat-card class="p-p-3 p-p-sm-1 p-p-md-2 p-p-lg-3">
      <psm-text-input
        [parentFormGroup]="form"
        [controlName]="config.dobFormControl"
        [value]="profile.personalInfo?.dob"
        [label]="content.dobLbl"
        [isDatePicker]=true
        [name]="config.dobFormControl"
        [validators]="config.dobValidators">
      </psm-text-input>
      <psm-dropdown
        [label]="content.genderLbl"
        [parentFormGroup]="form"
        [value]="genderValue"
        [optionList]="config.genderList"
        (selectionChange)="genderSelectionChange($event)">
      </psm-dropdown>
    </mat-card>
  </div>
</app-master-content>
