export class FirestorePath {
  public static readonly COMPANIES = 'companies';
  public static readonly SERVICE_ACCOUNTS = 'service_accounts';
  public static readonly ADDRESSES = 'addresses';
  public static readonly COMPANY_PRODUCTS = 'company_products';
  public static readonly CONTACTS = 'contacts';
  public static readonly PHONES = 'phones';
  public static readonly PROFILES_COLLECTION = 'profile';
  public static readonly PILLARS = 'pillars';
  public static readonly PILLARS_COLLECTION = 'pillars_collection';
  public static readonly OBJECTIVE = 'objective';
  public static readonly AGENDA = 'agenda';
  public static readonly GOALS = 'goals';
  public static readonly OBJECTIVES = 'objectives';
  public static readonly WEEK_AGENDA = 'week_agenda';
  public static readonly EVENT_AGENDA = 'event_agenda';
  public static readonly NOTES = 'notes';
}
