<div *ngIf="!hasObjectiveRelationship; else agendaItemWithObjective">
  <form [formGroup]="form" class="edit-form">
    <div class="event-grid grid-rows-props">
      <div class="triple-col-span">
        <psm-form-field
          [title]="content.lblSubject">
          <psm-text-input
            [parentFormGroup]="form"
            [controlName]="config.subjectFormControl"
            [value]="agendaItem?.title"
            [maxLength]="config.taskDescriptionMaxLength"
            [minLength]="config.taskDescriptionMinLength"
            [validators]="config.taskDescriptionValidators"
            (inputStatus)="onChangeEventHandler($event, 'title')">
          </psm-text-input>
        </psm-form-field>
      </div>
      <div class="double-col-span">
        <psm-form-field
          [title]="content.lblDatePicker">
          <psm-dropdown
            [label]="content.weeksDropdown"
            [parentFormGroup]="form"
            [value]="selectedWeekDay"
            [optionList]="config.weekOptions"
            (selectionChange)="onWeekDaySelectionChange($event)">
          </psm-dropdown>
        </psm-form-field>
      </div>
      <div class="two-col-grid">
        <psm-form-field
          [title]="content.lblTimeStart">
          <div class="time-picker-box">
            <psm-dropdown
              class="timer-input"
              [parentFormGroup]="form"
              [value]="selectCurrentTimeStart"
              [optionList]="config.timeHourOption"
              (selectionChange)="onHourStartSelectionChange($event)">
            </psm-dropdown>
            <psm-dropdown
              class="timer-input"
              [parentFormGroup]="form"
              [value]="selectCurrentTimeMinStart"
              [optionList]="config.timeMinOption"
              (selectionChange)="onMinStartSelectionChange($event)">
            </psm-dropdown>
          </div>
        </psm-form-field>
        <psm-form-field
          [title]="content.lblTimeEnd">
          <div class="time-picker-box">
            <psm-dropdown
              class="timer-input"
              [parentFormGroup]="form"
              [value]="selectCurrentTimeEnd"
              [optionList]="config.timeHourOption"
              (selectionChange)="onHourEndSelectionChange($event)">
            </psm-dropdown>
            <psm-dropdown
              class="timer-input"
              [parentFormGroup]="form"
              [value]="selectCurrentTimeMinEnd"
              [optionList]="config.timeMinOption"
              (selectionChange)="onMinEndSelectionChange($event)">
            </psm-dropdown>
          </div>
        </psm-form-field>
      </div>
      <div class="triple-col-span">
        <psm-form-field
          [title]="content.lblActivity">
          <psm-text-area
            [parentFormGroup]="form"
            [controlName]="config.taskDescriptionFormControl"
            [value]="agendaItem?.content"
            [maxLength]="config.taskDescriptionMaxLength"
            [minLength]="config.taskDescriptionMinLength"
            [validators]="config.taskDescriptionValidators"
            rows="8"
            (inputStatus)="onChangeEventHandler($event, 'content')">
          </psm-text-area>
        </psm-form-field>
      </div>
    </div>
    <div class="flex flex-row items-center justify-center">
      <button mat-button (click)="onAddClick()">
        <mat-icon>add_task</mat-icon>
        {{ content.components.btnSave }}
      </button>
      <button mat-button (click)="onRemoveClick()" *ngIf="agendaItem?.idField">
        <mat-icon>create</mat-icon>
        {{ content.components.btnRemove }}
      </button>
      <button *ngIf="editForm" mat-button (click)="onAddNoteClick()">
        <mat-icon>edit_note</mat-icon>
        {{ content.components.btnAddNotes }}
      </button>
      <button *ngIf="showDetailsBtn" mat-button (click)="onViewDetails()">
        <mat-icon>visibility</mat-icon>
        {{ content.components.btnViewDetails }}
      </button>
      <div class="flex-grow"></div>
      <div *ngIf="editForm" class="al footer-note">
        <p>{{ content.lblCreatedOn + ' ' }}</p>
      </div>
      <div *ngIf="editForm" class="al al-l footer-note">
        <p>{{ agendaItem?.creationDate?.toMillis() | date: 'dd/MM/yyyy hh:mm a' }}</p>
      </div>
    </div>
  </form>
</div>

<ng-template #agendaItemWithObjective>
  <form [formGroup]="form" class="edit-form">
    <div class="event-grid grid-rows-props">
      <div>
        <psm-form-field
          [title]="content.dropdownPillar.title">
          <psm-dropdown
            [label]="content.dropdownPillar.label"
            [parentFormGroup]="form"
            [value]="pillar"
            [optionList]="pillarArray"
            (selectionChange)="onSelectPillarHandler($event)">
          </psm-dropdown>
        </psm-form-field>
      </div>
      <div class="double-col-span">
        <psm-form-field
          [title]="content.dropdownObjective.title">
          <psm-dropdown
            [label]="content.dropdownObjective.label"
            [parentFormGroup]="form"
            [value]="objective"
            [optionList]="objectiveDropDownArray"
            (selectionChange)="onSelectObjectiveHandler($event)">
          </psm-dropdown>
        </psm-form-field>
      </div>
      <div class="double-col-span">
        <psm-form-field
          [title]="content.lblDatePicker">
          <psm-dropdown
            [label]="content.weeksDropdown"
            [parentFormGroup]="form"
            [value]="selectedWeekDay"
            [optionList]="config.weekOptions"
            (selectionChange)="onWeekDaySelectionChange($event)">
          </psm-dropdown>
        </psm-form-field>
      </div>
      <div class="two-col-grid">
        <psm-form-field
          [title]="content.lblTimeStart">
          <div class="time-picker-box">
            <psm-dropdown
              class="timer-input"
              [parentFormGroup]="form"
              [value]="selectCurrentTimeStart"
              [optionList]="config.timeHourOption"
              (selectionChange)="onHourStartSelectionChange($event)">
            </psm-dropdown>
            <psm-dropdown
              class="timer-input"
              [parentFormGroup]="form"
              [value]="selectCurrentTimeMinStart"
              [optionList]="config.timeMinOption"
              (selectionChange)="onMinStartSelectionChange($event)">
            </psm-dropdown>
          </div>
        </psm-form-field>
        <psm-form-field
          [title]="content.lblTimeEnd">
          <div class="time-picker-box">
            <psm-dropdown
              class="timer-input"
              [parentFormGroup]="form"
              [value]="selectCurrentTimeEnd"
              [optionList]="config.timeHourOption"
              (selectionChange)="onHourEndSelectionChange($event)">
            </psm-dropdown>
            <psm-dropdown
              class="timer-input"
              [parentFormGroup]="form"
              [value]="selectCurrentTimeMinEnd"
              [optionList]="config.timeMinOption"
              (selectionChange)="onMinEndSelectionChange($event)">
            </psm-dropdown>
          </div>
        </psm-form-field>
      </div>
      <div class="triple-col-span">
        <psm-form-field
          [title]="content.lblActivity">
          <psm-text-area
            [parentFormGroup]="form"
            [controlName]="config.taskDescriptionFormControl"
            [value]="agendaItem?.content"
            [maxLength]="config.taskDescriptionMaxLength"
            [minLength]="config.taskDescriptionMinLength"
            [validators]="config.taskDescriptionValidators"
            rows="8"
            (inputStatus)="onChangeEventHandler($event, 'content')">
          </psm-text-area>
        </psm-form-field>
      </div>
    </div>
    <div class="flex flex-row items-center justify-center">
      <button mat-button (click)="onAddClick()">
        <mat-icon>add_task</mat-icon>
        {{ content.components.btnSave }}
      </button>
      <button mat-button (click)="onRemoveClick()" *ngIf="agendaItem?.idField">
        <mat-icon>create</mat-icon>
        {{ content.components.btnRemove }}
      </button>
      <button *ngIf="editForm" mat-button (click)="onAddNoteClick()">
        <mat-icon>edit_note</mat-icon>
        {{ content.components.btnAddNotes }}
      </button>
      <button *ngIf="showDetailsBtn" mat-button (click)="onViewDetails()">
        <mat-icon>visibility</mat-icon>
        {{ content.components.btnViewDetails }}
      </button>
      <div class="flex-grow"></div>
      <div *ngIf="editForm" class="al footer-note">
        <p>{{ content.lblCreatedOn + ' ' }}</p>
      </div>
      <div *ngIf="editForm" class="al al-l footer-note">
        <p>{{ agendaItem?.creationDate?.toMillis() | date: 'dd/MM/yyyy hh:mm a' }}</p>
      </div>
    </div>
  </form>
</ng-template>
