<h1 mat-dialog-title>{{ content.title }}</h1>
<div mat-dialog-content>
  <p>{{ content.text1 }}</p>
  <color-circle (onChangeComplete)="changeComplete($event)"></color-circle>
</div>
<div mat-dialog-actions>
  <div class="card-container">
    <div class="card-small-default">
      <button mat-raised-button [mat-dialog-close]="data.color" color="pdpf-primary" class="btn-size-default">
        {{ content.btnConfirm }}
      </button>
    </div>
    <div class="card-small-default">
      <button mat-raised-button (click)="onCloseDialog()" color="alert" class="btn-size-default">
        {{ content.btnCancel }}
      </button>
    </div>
  </div>
</div>