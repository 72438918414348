import { createAction, props } from '@ngrx/store';
import { Profile } from '../model/profile.model';

export const setProfile = createAction(
  '[Profile] Set Profile',
  props<{ profile: Profile }>()
);

export const setProfileSuccess = createAction(
  '[Profile] Set Profile Success',
  props<{ profile: Profile }>()
);

export const setProfileFailure = createAction(
  '[Profile] Set Profile Failure',
  props<{ error: any }>()
);

export const loadProfile = createAction(
  '[Profile] Load Profile',
  props<{ email: string }>()
);

export const loadProfileSuccess = createAction(
  '[Profile] Load Profile Success',
  props<{ profile: Profile }>()
);

export const loadProfileFailure = createAction(
  '[Profile] Load Profile Failure',
  props<{ error: any }>()
);

export const getProfileByEmailFailure = createAction(
  '[Profile API] Get Profile Failure',
  props<{ error: any }>()
);
