import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import _ from 'lodash';
import moment from 'moment';
import PT from './i18n/pt.json';
import { NotesFirebaseService } from 'src/app/services/firebase/notes/notes-firebase.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppLang } from 'src/assets/i18n/app-lang';
import { Note } from 'src/app/state-v2/model/note.model';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SaveDataConfirmationComponent } from 'src/app/pages/dialogs/common/save-data-confirmation/save-data-confirmation.component';
import { addNoteApi, deleteNoteApi } from 'src/app/state-v2/action/notes.actions';

@Component({
  selector: 'psm-comments-section',
  templateUrl: './psm-comments-section.component.html',
  styleUrls: ['./psm-comments-section.component.scss']
})
export class PsmCommentsSectionComponent implements OnInit, OnDestroy {

  /**
   * i18n content
   */
  content;

  /**
   * The activity notes array
   */
  activityNotes: Note[];
  notesSubscription: Subscription;

  /**
   * The note parent id
   */
  @Input() parentNoteId;

  constructor(
    private translate: TranslateService,
    private store: Store,
    private notesService: NotesFirebaseService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    moment.locale(AppLang.DEFAULT);
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;

    if (this.parentNoteId) {
      this.notesSubscription = this.notesService.getNotesByParentId(this.parentNoteId).subscribe(notes => {
        this.activityNotes = _.orderBy(notes, ['favorite', 'creationDate'], ['desc']);
      });
    }
  }

  ngOnDestroy(): void {
      if (this.notesSubscription) {
        this.notesSubscription.unsubscribe();
      }
  }

  /**
   * It erases a note upon click
   * @param note Note
   */
  onEraseNoteClick(note: Note) {
    if (note) {
      const dialogRef = this.dialog.open(SaveDataConfirmationComponent, {
        data: {
          confirm: true,
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.store.dispatch(deleteNoteApi({ noteId: note.idField }));
        }
      });
    }
  }

  /**
   * It saves the note as favorite
   * @param note Note
   */
  onMarkFavoriteNote(note: Note) {
    if (note) {
      note.favorite = !note.favorite;
      this.store.dispatch(addNoteApi({ note }));
    }
  }

}
