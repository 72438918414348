<app-master-content [masterContentPage]="page" [form]="form" (btnForwardEvent)="btnForwardEvent($event)">
  <div class="p-flex-column w-full p-mt-4">
    <mat-card class="p-p-3">
      <psm-text-input
        [parentFormGroup]="form"
        [controlName]="config.nameFormControl"
        [value]="profile.personalInfo?.name"
        [maxLength]="config.nameMaxLength"
        [minLength]="config.nameMinLength"
        [label]="content.nameLbl"
        [validators]="config.nameValidators">
      </psm-text-input>
      <psm-text-input
        [parentFormGroup]="form"
        [controlName]="config.lastNameFormControl"
        [value]="profile.personalInfo?.lastName"
        [maxLength]="config.lastNameMaxLength"
        [minLength]="config.lastNameMinLength"
        [label]="content.lastNameLbl"
        [validators]="config.lastNameValidators">
      </psm-text-input>
    </mat-card>
  </div>
</app-master-content>
