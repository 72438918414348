import { createAction, props } from '@ngrx/store';
import { ObjectiveModel } from '../model/objective.model';

export const openObjectivesSummaryPage = createAction(
  '[Objectives Summary Page] Open Objectives Summmary Page'
);

export const openObjectiveDetails = createAction(
  '[Objective Details Page] Open Objective Details'
);

export const openObjectiveDetailsSuccess = createAction(
  '[Objective Page] Open Objective Details Success'
);

export const openObjectiveDetailsFailure = createAction(
  '[Objective Details Page] Open Objective Details Failure',
  props<{error: any}>()
);

export const addObjectiveDocument = createAction(
  '[Objective API] Add Objective Document',
  props<{objectiveDoc: ObjectiveModel}>()
);

export const addObjectiveDocumentSuccess = createAction(
  '[Objective API] Add Objective Document Success'
);

export const addObjectiveDocumentFailure = createAction(
  '[Objective API] Add Objective Document Failure',
  props<{error: any}>()
);

export const addObjectiveFailureMessageDisplay = createAction(
  '[Objective API] Add Objective Document Failure Message Display'
);

export const openObjectivesPage = createAction(
  '[Objectives Page] Open Objectives Page',
  props<{goalId: string}>()
);

export const openObjectivesPageFailure = createAction(
  '[Objectives Page] Open Objectives Page',
  props<{error: any}>()
);

export const getObjectivesApi = createAction(
  '[Objective API] Get Objectives',
  props<{goalId: string}>()
);

export const getObjectivesApiFailure = createAction(
  '[Objective API] Get Objectives Failure',
  props<{error: any}>()
);

export const getObjectivesByPillarNodeApi = createAction(
  '[Objective API] Get Objectives by Pillar Node',
  props<{pillarNode: string}>()
);

export const getObjectivesByPillarNodeApiFailure = createAction(
  '[Objective API] Get Objectives by Pillar Node Failure',
  props<{error: any}>()
);

export const getObjectivesByIdApi = createAction(
  '[Objective API] Get Objectives by ID',
  props<{objectiveId: string}>()
);

export const getObjectivesByIdApiFailure = createAction(
  '[Objective API] Get Objectives by ID Failure',
  props<{error: any}>()
);
