import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PdpfPartyService } from 'src/app/services/common/pdpf-party/pdpf-party.service';
import { setToolBarURL } from 'src/app/state-v2/action/component.actions';
import { AppLang } from 'src/assets/i18n/app-lang';
import { RouteName } from 'src/shared/router-paths';
import { MasterContentPage } from '../master-content/master-content.class.page';
import { ACTION } from './action/action';
import PT from './i18n/pt.json';

@Component({
  selector: 'app-pillar-essence',
  templateUrl: './pillar-essence.component.html',
  styleUrls: ['./pillar-essence.component.scss']
})
export class PillarEssenceComponent implements OnInit {

  showAll = false;
  content;
  pillarConfig;
  pageContent: MasterContentPage;
  pillarAction: string;

  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private pdpfPartyService: PdpfPartyService,
    private store: Store) { }

  ngOnInit(): void {
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;
    const action = this.route.snapshot.paramMap.get('action');
    this.pillarAction = this.route.snapshot.paramMap.get('pillarAction');

    if (action != null) {
      switch (action) {
        case ACTION.PARENT_ACTION.PILLAR_GOALS.name:
          this.pillarConfig = this.pdpfPartyService.getPillarConfigByTitle(this.pillarAction);
          break;
      }
    }

    this.store.dispatch(setToolBarURL({
      url: RouteName.GOAL,
      params: {
        action: ACTION.PARENT_ACTION.SELF.name,
        pillarAction: this.pillarAction
      }
    }));
  }

  /**
   * Back navigation
   * @param event
   */
  btnForwardEvent(event) {
    this.router.navigate([RouteName.GOAL, {
      action: ACTION.PARENT_ACTION.SELF.name,
      pillarAction: this.pillarAction
    }]);
  }

}
