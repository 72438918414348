import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppLang } from 'src/assets/i18n/app-lang';
import { RouteName } from 'src/shared/router-paths';
import { MasterButton } from '../master-content/master-content.class.button';
import { MasterContentPage } from '../master-content/master-content.class.page';
import { CongratulationConfig } from './congratulation.config';

import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { setToolBarOptions } from 'src/app/state-v2/action/component.actions';
import { setProfile } from 'src/app/state-v2/action/profile.actions';
import { Profile } from 'src/app/state-v2/model/profile.model';
import { selectProfile } from 'src/app/state-v2/selector/profile.selector';
import { selectServiceAccount } from 'src/app/state-v2/selector/service-accont.selector';
import PT from './i18n/pt.json';

@Component({
  selector: 'app-congratulation',
  templateUrl: './congratulation.component.html',
  styleUrls: ['./congratulation.component.scss']
})
export class CongratulationComponent implements OnInit {

  page: MasterContentPage;
  content: any;
  form = new FormGroup({});
  config: CongratulationConfig;

  /**
   * The profile state
   */
  profile: Profile;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private congratulationConfig: CongratulationConfig,
    private spinner: NgxSpinnerService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.store.dispatch(setToolBarOptions({
      setToolBar: false
    }));

    this.config = this.congratulationConfig;
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;

    this.page = new MasterContentPage(
      false,
      true,
      'assets/images/imgs/award-1.svg',
      this.content.title,
      [
        this.content.text1,
        this.content.title2
      ],
      new MasterButton(
        this.content.btnLabel
      )
    );

    this.store.select(selectProfile).subscribe(profile => this.profile = profile);
  }

  /**
   * It saves the user data in profile and move forward
   * @param event Button click event
   */
  btnForwardEvent(event) {
    if (this.form.valid) {
      this.spinner.show();
      const firstName = this.form.get(this.config.nameFormControl).value;
      const lastName = this.form.get(this.config.lastNameFormControl).value;

      this.store.select(selectServiceAccount).pipe(first()).subscribe(
        account => {
          this.store.dispatch(setProfile({
            profile: {
              ...this.profile,
              personalInfo: {
                ...this.profile.personalInfo,
                name: firstName,
                lastName: lastName
              },
              idField: account.profileRef
            }
          }));
          this.router.navigate([RouteName.USER_DOB_GENDER_FORM]);
          this.spinner.hide();
        }
      );
    }
  }

}
