import { MasterButton } from './master-content.class.button';

export class MasterContentPage {

  /**
   * A flag to whether display the brand logo or not
   */
  displayBrandLogo: boolean | undefined = true;

  /**
   * A flag to whether display the header or not
   */
  displayHeader: boolean | undefined = true;
  headerTitle: string | undefined;
  headerSubtitle: Array<string> | undefined;
  banner: string | undefined;
  btnFoward: MasterButton;
  btnBackward: MasterButton;

  constructor(
    displayBrandLogo?: boolean,
    displayHeader?: boolean,
    banner?: string | undefined,
    headerTitle?: string | undefined,
    headerSubtitle?: Array<string> | undefined,
    btnFoward?: MasterButton | undefined,
    btnBackward?: MasterButton | undefined) {
      this.displayBrandLogo = displayBrandLogo;
      this.displayHeader = displayHeader;
      this.banner = banner;
      this.headerTitle = headerTitle;
      this.headerSubtitle = headerSubtitle;
      this.btnFoward = btnFoward;
      this.btnBackward = btnBackward;
  }

}
