import { createAction, props } from '@ngrx/store';
import { ServiceAccount } from '../model/service-account.model';

export const setServiceAccount = createAction(
  '[ServiceAccount] Set Service Account',
  props<{ account: ServiceAccount }>()
);

export const setServiceAccountFailure = createAction(
  '[ServiceAccount] Set Service Account Failure',
  props<{ error: any }>()
);

export const setServiceAccountSuccess = createAction(
  '[ServiceAccount] Set Service Account Success',
  props<{ account: ServiceAccount }>()
);

export const loadServiceAccount = createAction(
  '[ServiceAccount] Retrieve Service Account',
  props<{ email: string }>()
);

export const loadServiceAccountFailure = createAction(
  '[ServiceAccount] Retrieve Service Account Failure',
  props<{ error: any }>()
);

export const loadServiceAccountSuccess = createAction(
  '[ServiceAccount] Retrieve Service Account Success',
  props<{ account: ServiceAccount }>()
);

export const activateServiceAccount = createAction(
  '[ServiceAccount] Activate Service Account',
  props<{ token: string, email: string }>()
);

export const activateServiceAccountFailure = createAction(
  '[ServiceAccount] Activate Service Account Failure',
  props<{ error: any }>()
);

export const getAccountByEmail = createAction(
  '[ServiceAccount API] Get Account By Email Failure',
  props<{ error: any }>()
);

export const getAccountByToken = createAction(
  '[ServiceAccount API] Get Account By Token Failure',
  props<{ error: any }>()
);
