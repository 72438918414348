import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { GoalState } from 'src/app/state-v2/reducer/goal.reducer';

@Injectable()
export class GoalFormConfig {

  titleFormControl = 'titleFormControl';
  titleMaxLength = 35;
  titleMinLength = 1;
  titleValidators = [
    Validators.required,
    Validators.maxLength(this.titleMaxLength),
    Validators.minLength(this.titleMinLength),
  ];

  descriptionFormControl = 'descriptionFormControl';
  descriptionMaxLength = 800;
  descriptionMinLength = 1;
  descriptionValidators = [
    Validators.required,
    Validators.maxLength(this.descriptionMaxLength),
    Validators.minLength(this.descriptionMinLength),
  ];

  reasonFormControl = 'reasonFormControl';
  reasonMaxLength = 500;
  reasonMinLength = 1;
  reasonValidators = [
    Validators.required,
    Validators.maxLength(this.reasonMaxLength),
    Validators.minLength(this.reasonMinLength),
  ];

  realityFormControl = 'realityFormControl';
  realityMaxLength = 500;
  realityMinLength = 1;
  realityValidators = [
    Validators.required,
    Validators.maxLength(this.realityMaxLength),
    Validators.minLength(this.realityMinLength),
  ];

  checkResourceFormControl = 'checkResourceFormControl';
  checkResourceValidators = [
    Validators.required
  ];

  constructor() {}
}
