<div class="m-container">
  <div class="p-flex-column w-full">
    <psm-home-card [title]="content.pillarCard.headerLbl">
      <section class="pillar-box-section">
        <button
          class="pillar-btn-box pillar-effect"
          (click)="onPillarClickHandler(pillar)"
          *ngFor="let pillar of pillarArrDisplay; let i = index"
          [ngClass]="{
            'highlight-yellow': pillar.pillarId === 'family',
            'highlight-red': pillar.pillarId === 'health',
            'highlight-blue': pillar.pillarId === 'self_development',
            'highlight-green': pillar.pillarId === 'work'
          }">
          <p>
            {{pillar.shortTitle}}
          </p>
        </button>
      </section>
    </psm-home-card>
    <psm-home-card [title]="content.scheduleCard.title">
      <section>
        <psm-daily-agenda
          previewMode="true"
          [agenda]="agenda$ | async"
          [selectedItem]="selectedWeekAgendaItem"
          (itemClick)="onAgendaItemClick($event)"
          (dateSelectionChangeHandler)="dateSelectionChangeHandler($event)">
        </psm-daily-agenda>
      </section>
    </psm-home-card>
  </div>
</div>
