import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import PT from './i18n/pt.json';
import { ACTION } from './action/action';

import { AppLang } from 'src/assets/i18n/app-lang';
import { Router } from '@angular/router';
import { RouteName } from 'src/shared/router-paths';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-pillar-options-dialog',
  templateUrl: './pillar-options-dialog.component.html',
  styleUrls: ['./pillar-options-dialog.component.scss']
})
export class PillarOptionsDialogComponent implements OnInit {

  content: any;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private bottomSheetRef: MatBottomSheetRef<PillarOptionsDialogComponent>
  ) { }

  ngOnInit(): void {
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;
  }

  onBtnSetupClick() {
    this.router.navigate([RouteName.PILLAR_CONFIG, {
      action: ACTION.name
    }]);
    this.bottomSheetRef.dismiss();
  }

  onBtnMyObjectivesClick() {

  }

  onBtnMyGoalsClick() {
    this.router.navigate([RouteName.GOAL, {
      action: ACTION.name
    }]);
    this.bottomSheetRef.dismiss();
  }

  onBtnExampleClick() {

  }

  onBtnCloseClick() {
    this.bottomSheetRef.dismiss();
  }

}
