import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { addGoalDocumentFailure, deleteGoalDocumentFailure, deleteGoalDocumentSuccess, getGoalByGoalId, getGoalByGoalIdFailure, getGoals, getGoalsFailure } from 'src/app/state-v2/action/goal.actions';
import { GoalState } from 'src/app/state-v2/reducer/goal.reducer';
import { AppLang } from 'src/assets/i18n/app-lang';
import { FirestorePath } from 'src/shared/firestore-paths';
import { PdpfPartyService } from '../../common/pdpf-party/pdpf-party.service';
import PT from './i18n/pt.json';

/**
 * Goal Model Interface
 */
/**
 * @deprecated DO NOT USE
 */
export interface GoalModel {
  bgColor: string;
  description: string;
  favorite: boolean;
  header: string;
  reality: string;
  length: number;
  rank: number;
  reason: string;
  expireDate: any;
  resourcesAvailable: any;
  resourcesRequired: any;
}

@Injectable({
  providedIn: 'root'
})
export class GoalApiService {

  constructor(
    private translate: TranslateService,
    private store: Store,
    private af: AngularFirestore,
    private pdpfPartyService: PdpfPartyService
  ) { }

  /**
   * It adds a new document in the goals collection
   * /goals in Firestore
   * @param goalState
   */
  addGoalDocument(goalState: GoalState): Observable<string> {
    try {
      this.translate.setTranslation(AppLang.DEFAULT, PT, true);
      const goalDocRef: GoalState = {
        ...goalState
      };

      if (!goalDocRef.pillarNode) {
        throw new Error('The pillarNode value must be defined');
      }

      if (!goalDocRef.goalId) {
        goalDocRef.goalId = this.pdpfPartyService.generateId(goalDocRef.profileId, 'goal');
      }

      if (goalState.idField) {
        this.af.doc(FirestorePath.GOALS + '/' + goalState.idField).set(goalDocRef, { merge: true });
        return of(goalState.idField);
      }

      this.af.collection(FirestorePath.GOALS).doc().set(goalDocRef);
      return of(goalState.goalId);
    } catch (error) {
      console.error(error);
      this.store.dispatch(addGoalDocumentFailure({
        error: error
      }));
    }
  }

  /**
   * It removes a goal document from firestore given a docId
   * @param docId string
   */
  deleteGoalDocument(docId: string): Observable<string> {
    try {
      if (!docId) {
        throw new Error('The document ID is invalid or missing');
      }
      this.af.doc<GoalState>(FirestorePath.GOALS + '/' + docId).delete();
      this.store.dispatch(deleteGoalDocumentSuccess());
      return of(docId);
    } catch (error) {
      this.store.dispatch(deleteGoalDocumentFailure({
        error
      }));
    }
  }

  /** @deprecated
   * Use addGoalDocument instead
   */
  add(goal: GoalModel, pillarNode: string): number {
    return 0;
  }

  /**
   * @deprecated
   * It updates an existing goal
   * @param goal
   * @param pillarNode
   * @param index
   */
  update(goal: GoalModel, pillarNode: string, index: number) {
    return 0;
  }

  /**
   * It retrieves the goals documents from firebase. All goals retrieved are active in firestore.
   * @param pillarId The current pillar id selection
   * @returns Stream of goals
   */
  getGoals(pillarId: string, profileId: string): Observable<GoalState[]> {
    try {
      this.store.dispatch(getGoals({ pillarId }));

      if (pillarId && profileId) {
        return this.af.collection<GoalState>(FirestorePath.GOALS, ref => ref
          .where('profileId', '==', profileId)
          .where('pillarNode', '==', pillarId.toLowerCase())
          .where('active', '==', true)
          .orderBy('priority')
          .orderBy('creationDate', 'desc'))
          .valueChanges({idField: 'idField'});
      }

      return of([]);
    } catch (error) {
      console.error(error);
      this.store.dispatch(getGoalsFailure({
        error
      }));
    }
  }

  /**
   * It retrieves a goal documents from firebase given the goal id
   * @param goalId The profile object reference from firestore
   * @returns Stream of goals
   */
  getGoalByGoalId(goalId: string): Observable<GoalState[]> {
    try {
      this.store.dispatch(getGoalByGoalId({ goalId }));

      if (goalId) {
        return this.af.collection<GoalState>(FirestorePath.GOALS, ref => ref
          .where('goalId', '==', goalId)
          .where('active', '==', true))
          .valueChanges({idField: 'idField'});
      }

      return of([]);
    } catch (error) {
      console.error(error);
      this.store.dispatch(getGoalByGoalIdFailure({
        error
      }));
    }
  }

}
