import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/firebase/authentication/auth.service';
import { selectComponent } from 'src/app/state-v2/selector/component.selector';
import { RouteName } from 'src/shared/router-paths';

export interface DialogData {
  message: string | '';
}

@Component({
  selector: 'app-token-validation-dialog',
  templateUrl: './token-validation-dialog.component.html',
  styleUrls: ['./token-validation-dialog.component.css']
})
export class TokenValidationDialogComponent implements OnInit {

  msg: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    public translate: TranslateService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.store.select(selectComponent).subscribe(state => {
      this.msg = state.tokenStatus.msg;
    });
  }

  public onBackBtnAction() {
    this.router.navigate([RouteName.ACTIVATE_ACCOUNT]);
    this.authService.logout();
  }
}
