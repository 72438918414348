import { DragDropModule } from '@angular/cdk/drag-drop';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before calendar plugins
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TextMaskModule } from 'angular2-text-mask';
import { ColorCircleModule } from 'ngx-color/circle';
import { ColorGithubModule } from 'ngx-color/github';
import { ColorSketchModule } from 'ngx-color/sketch';
import { NgxPhoneMaskBrModule } from 'ngx-phone-mask-br';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { MenuModule } from 'primeng/menu';
import { PasswordModule } from 'primeng/password';
import { TableModule } from 'primeng/table';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PsmButtonComponent } from './components/psm-button/psm-button.component';
import { PsmCalendarComponent } from './components/psm-calendar/psm-calendar.component';
import { PsmCheckboxComponent } from './components/psm-checkbox/psm-checkbox.component';
import { PsmDropdownComponent } from './components/psm-dropdown/psm-dropdown.component';
import { PsmFormFieldComponent } from './components/psm-form-field/psm-form-field.component';
import { PsmHomeCardComponent } from './components/psm-home-card/psm-home-card.component';
import { PsmMenuComponent } from './components/psm-menu/psm-menu.component';
import { PsmStepperButtonComponent } from './components/psm-stepper-button/psm-stepper-button.component';
import { PsmTextAreaComponent } from './components/psm-text-area/psm-text-area.component';
import { PsmTextInputComponent } from './components/psm-text-input/psm-text-input.component';
import { PsmTextInputConfig } from './components/psm-text-input/psm-text-input.config';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { AccountActivationComponent } from './pages/account-activation/account-activation.component';
import { AccountActivationConfig } from './pages/account-activation/account-activation.config';
import { CongratulationComponent } from './pages/congratulation/congratulation.component';
import { CongratulationConfig } from './pages/congratulation/congratulation.config';
import { DailyScheduleViewComponent } from './pages/daily-schedule-view/daily-schedule-view.component';
import { AccountActivationFormInvalidDialogComponent } from './pages/dialogs/account-activation/account-activation-form-invalid-dialog/account-activation-form-invalid-dialog.component';
import { AccountInUseDialogComponent } from './pages/dialogs/account-activation/account-in-use-dialog/account-in-use-dialog.component';
import { ColorPickerDialogComponent } from './pages/dialogs/color-picker-dialog/color-picker-dialog.component';
import { LogoutConfirmationComponent } from './pages/dialogs/common/logout-confirmation/logout-confirmation.component';
import { SaveDataConfirmationComponent } from './pages/dialogs/common/save-data-confirmation/save-data-confirmation.component';
import { GoalLearnmoreComponent } from './pages/dialogs/goal-learnmore/goal-learnmore.component';
import { GoalOptionsLearnmoreComponent } from './pages/dialogs/goal-options-learnmore/goal-options-learnmore.component';
import { GoalOptionsComponent } from './pages/dialogs/goal-options/goal-options.component';
import { InvalidInputValidationDialogComponent } from './pages/dialogs/invalid-input-validation-dialog/invalid-input-validation-dialog.component';
import { LoginFormInvalidDialogComponent } from './pages/dialogs/login/login-form-invalid-dialog/login-form-invalid-dialog.component';
import { LoginUnsuccessfulDialogComponent } from './pages/dialogs/login/login-unsuccessful-dialog/login-unsuccessful-dialog.component';
import { ObjectiveLearnmoreComponent } from './pages/dialogs/objective-learnmore/objective-learnmore.component';
import { PillarCreationDialogComponent } from './pages/dialogs/pillar-creation-dialog/pillar-creation-dialog.component';
import { PillarOptionsDialogComponent } from './pages/dialogs/pillar-options-dialog/pillar-options-dialog.component';
import { TokenValidationDialogComponent } from './pages/dialogs/token/token-validation-dialog/token-validation-dialog.component';
import { GoalComponent } from './pages/goal/goal.component';
import { HomeComponent } from './pages/home/home.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { MasterContentComponent } from './pages/master-content/master-content.component';
import { MyPdpfComponent } from './pages/my-pdpf/my-pdpf.component';
import { ObjectiveFormComponent } from './pages/objective/objective-form/objective-form.component';
import { ObjectiveFormConfig } from './pages/objective/objective-form/objective-form.config';
import { ObjectiveComponent } from './pages/objective/objective.component';
import { PillarConfigurationComponent } from './pages/pillar-configuration/pillar-configuration.component';
import { PillarCreationComponent } from './pages/pillar-creation/pillar-creation.component';
import { PillarEssenceComponent } from './pages/pillar-essence/pillar-essence.component';
import { UserDobGenderComponent } from './pages/user-dob-gender/user-dob-gender.component';
import { UserDobGenderConfig } from './pages/user-dob-gender/user-dob-gender.config';
import { UserInfoComponent } from './pages/user-info/user-info.component';
import { UserInfoConfig } from './pages/user-info/user-info.config';
import { UserPhoneComponent } from './pages/user-phone/user-phone.component';
import { UserPhoneConfig } from './pages/user-phone/user-phone.config';
import { AuthService } from './services/firebase/authentication/auth.service';
import { FireFunctionsService } from './services/firebase/functions/fire-functions.service';

// Full Calendar Plugins. It should not be moved from the bottom of the list
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import dayGridPlugin from '@fullcalendar/daygrid';
import interaction from '@fullcalendar/interaction';
import list from '@fullcalendar/list';
import scrollgrid from '@fullcalendar/scrollgrid';
import timegrid from '@fullcalendar/timegrid';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PsmDailyAgendaComponent } from './components/psm-daily-agenda/psm-daily-agenda.component';
import { PsmGoalViewComponent } from './components/psm-goal-view/psm-goal-view.component';
import { PsmNotesComponent } from './components/psm-notes/psm-notes.component';
import { PsmObjectiveViewComponent } from './components/psm-objective-view/psm-objective-view.component';
import { PsmSectionCardComponent } from './components/psm-section-card/psm-section-card.component';
import { PsmUserBadgeComponent } from './components/psm-user-badge/psm-user-badge.component';
import { AgendaDialogComponent } from './pages/agenda/agenda-dialog/agenda-dialog.component';
import { AgendaItemViewComponent } from './pages/agenda/agenda-item-view/agenda-item-view.component';
import { AgendaItemViewConfig } from './pages/agenda/agenda-item-view/agenda-item-view.config';
import { AgendaComponent } from './pages/agenda/agenda.component';
import { GoalDetailComponent } from './pages/goal/goal-detail/goal-detail.component';
import { GoalFormComponent } from './pages/goal/goal-form/goal-form.component';
import { GoalFormConfig } from './pages/goal/goal-form/goal-form.config';
import { GoalSummaryComponent } from './pages/goal/goal-summary/goal-summary.component';
import { ObjectiveDetailComponent } from './pages/objective/objective-detail/objective-detail.component';
import { ObjectiveSummaryComponent } from './pages/objective/objective-summary/objective-summary.component';
import { SummaryComponent } from './pages/summary/summary.component';
import { AgendaEffects } from './state-v2/effect/agenda.effects';
import { GoalEffects } from './state-v2/effect/goal.effects';
import { NoteEffects } from './state-v2/effect/notes.effects';
import { ObjectiveEffects } from './state-v2/effect/objective.effects';
import { PillarEffects } from './state-v2/effect/pillar.effects';
import { ProfileEffects } from './state-v2/effect/profile.effects';
import { ServiceAccountEffects } from './state-v2/effect/service-account.effects';
import { rootReducer } from './state-v2/reducer/index';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AgendaActivityDetailComponent } from './pages/agenda/agenda-activity-detail/agenda-activity-detail.component';
import { PsmCommentsSectionComponent } from './components/psm-comments-section/psm-comments-section.component';
registerLocaleData(ptBr)

// Register FullCalendar plugins
FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interaction,
  timegrid,
  list,
  scrollgrid
]);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    LandingPageComponent,
    HomeComponent,
    LoginFormInvalidDialogComponent,
    LoginUnsuccessfulDialogComponent,
    AccountActivationComponent,
    AccountActivationFormInvalidDialogComponent,
    SaveDataConfirmationComponent,
    TokenValidationDialogComponent,
    AccountInUseDialogComponent,
    CongratulationComponent,
    UserInfoComponent,
    PhoneMaskDirective,
    LogoutConfirmationComponent,
    PillarCreationComponent,
    MyPdpfComponent,
    PillarConfigurationComponent,
    ColorPickerDialogComponent,
    PillarOptionsDialogComponent,
    GoalComponent,
    GoalFormComponent,
    InvalidInputValidationDialogComponent,
    GoalDetailComponent,
    ObjectiveComponent,
    ObjectiveFormComponent,
    GoalOptionsLearnmoreComponent,
    GoalLearnmoreComponent,
    ObjectiveLearnmoreComponent,
    GoalOptionsComponent,
    PillarEssenceComponent,
    MasterContentComponent,
    UserDobGenderComponent,
    UserPhoneComponent,
    DailyScheduleViewComponent,
    PsmNotesComponent,
    PsmButtonComponent,
    PsmGoalViewComponent,
    PsmStepperButtonComponent,
    PsmTextInputComponent,
    PsmMenuComponent,
    PsmCalendarComponent,
    OnlyNumbersDirective,
    PsmDropdownComponent,
    PsmHomeCardComponent,
    PsmTextAreaComponent,
    PsmFormFieldComponent,
    PsmCheckboxComponent,
    PillarCreationDialogComponent,
    AgendaActivityDetailComponent,
    PsmDailyAgendaComponent,
    AgendaComponent,
    PsmCommentsSectionComponent,
    AgendaDialogComponent,
    AgendaItemViewComponent,
    PsmUserBadgeComponent,
    PsmSectionCardComponent,
    ObjectiveDetailComponent,
    PsmGoalViewComponent,
    PsmNotesComponent,
    PsmObjectiveViewComponent,
    SummaryComponent,
    ObjectiveSummaryComponent,
    GoalSummaryComponent,
  ],
  imports: [
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    ColorSketchModule,
    ColorCircleModule,
    ColorGithubModule,
    DragDropModule,
    DialogModule,
    FormsModule,
    FlexLayoutModule,
    FullCalendarModule,
    HttpClientModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatTabsModule,
    MatRadioModule,
    MatDialogModule,
    MatInputModule,
    MatIconModule,
    MatDividerModule,
    MatChipsModule,
    MatCheckboxModule,
    MatBottomSheetModule,
    MatTableModule,
    MatStepperModule,
    MatGridListModule,
    MatMenuModule,
    MatSelectModule,
    MatExpansionModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatCardModule,
    MatRippleModule,
    MatListModule,
    MatButtonModule,
    MatNativeDateModule,
    NgxSpinnerModule,
    NgxPhoneMaskBrModule,
    DividerModule,
    ButtonModule,
    PasswordModule,
    AvatarModule,
    AvatarGroupModule,
    MenuModule,
    ReactiveFormsModule,
    TextMaskModule,
    TableModule,
    TranslateModule.forRoot({
      defaultLanguage: 'pt',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    StoreModule.forRoot(rootReducer),
    EffectsModule.forRoot([
      GoalEffects,
      ObjectiveEffects,
      NoteEffects,
      AgendaEffects,
      ServiceAccountEffects,
      ProfileEffects,
      PillarEffects
    ]),
    environment.production ? [] : StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-PT' },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    AuthService,
    DatePipe,
    FireFunctionsService,
    UserInfoConfig,
    PsmTextInputConfig,
    CongratulationConfig,
    UserDobGenderConfig,
    UserPhoneConfig,
    AccountActivationConfig,
    GoalFormConfig,
    ObjectiveFormConfig,
    AgendaItemViewConfig
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
