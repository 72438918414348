export const ACTION = {
  PARENT_ACTION: {
    HOME: {
      name: 'HOME',
      active: false
    },
    PILLAR_CREATION: {
      name: 'PILLAR_CREATION',
      active: false
    },
    OBJECTIVE_LIST: {
      name: 'OBJECTIVE_LIST',
      active: false
    },
    PILLAR_ESSENCE: {
      name: 'PILLAR_ESSENCE',
      active: false
    },
    APP_MENU: {
      name: 'APP_MENU',
      active: false
    },
    SELF: {
      name: 'PILLAR_GOALS',
      active: false,
    },
    ROUTE_ERROR: {
      name: 'ROUTE_ERROR',
    },
    PILLAR_GOALS_CRUD: {
      name: 'PILLAR_GOALS_CRUD',
      active: false,
    },
    GOAL_OBJECTIVE: {
      name: 'GOAL_OBJECTIVE',
      active: false,
    },
    PILLAR_CONFIGURATION: {
      name: 'PILLAR_CONFIGURATION',
      active: false,
    }
  },
  CRUD_ACTION: {
    CREATE: {
      name: 'CREATE',
      active: false,
      pillarAction: ''
    },
    UPDATE: {
      name: 'UPDATE',
      active: false,
      pillarAction: '',
      goalTitle: ''
    }
  }
}