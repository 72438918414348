import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { AgendaApiService } from 'src/app/services/firebase/agenda/agenda-api.service';
import { setSideMenu, setToolBarOptions } from 'src/app/state-v2/action/component.actions';
import { Agenda } from 'src/app/state-v2/model/agenda.model';
import { Pillar } from 'src/app/state-v2/model/pillar.model';
import { selectPillar } from 'src/app/state-v2/selector/pillar.selector';
import { selectServiceAccount } from 'src/app/state-v2/selector/service-accont.selector';
import { AppLang } from 'src/assets/i18n/app-lang';
import { RouteName } from 'src/shared/router-paths';
import { PdpfPartyService } from '../../services/common/pdpf-party/pdpf-party.service';
import { AgendaItemViewComponent } from '../agenda/agenda-item-view/agenda-item-view.component';
import { ACTION } from './action/action';
import PT from './i18n/pt.json';
import { ServiceAccount } from 'src/app/state-v2/model/service-account.model';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {

  content: any;

  pillarState: Pillar[];
  pillarSubs: Subscription;
  pillarArrDisplay;

  /**
   * The agenda datasource
   */
  agenda$: Observable<Agenda[]>;

  /**
   * The current item that must be highlighted
   */
  selectedWeekAgendaItem: Agenda;

  /**
   * The service account representation
   */
  serviceAccount: ServiceAccount;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private pdpfParty: PdpfPartyService,
    private dialog: MatDialog,
    private agendaApiService: AgendaApiService,
    private store: Store
  ) {}

  ngOnDestroy(): void {
    this.pillarSubs.unsubscribe();
  }

  ngOnInit(): void {
    this.store.dispatch(setSideMenu({
      setSideMenu: true
    }));

    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;

    this.pillarSubs = this.store.select(selectPillar).subscribe(pillars => {
      if (pillars) {
        this.pillarState = pillars;
        this.pillarArrDisplay = this.pdpfParty.reOrderPillarsToDisplay(Array.from(this.pillarState).slice());
      }
    });

    this.store.select(selectServiceAccount).subscribe(serviceAccount => {
      this.serviceAccount = serviceAccount;

      const now = moment();
      this.agenda$ = this.agendaApiService.getAgenda(now.day(), serviceAccount?.profileRef);
    });
  }

  /**
   * It open the agenda modal
   * @param agendaItem Agenda Item
   */
  onAgendaItemClick(agendaItem: Agenda) {
    if (agendaItem?.type) {
      this.dialog.open(AgendaItemViewComponent, { data: { agendaItem: agendaItem, isEditMode: true, hasObjectiveRelationship: true } });
    }
  }

  /**
   * @deprecated use onPillarClickHandler() instead
   * It opens the goal page on the click
   * @param item PillarModel
   */
  onOpenGoalClick(item: Pillar) {
    this.router.navigate([
      RouteName.GOAL,
      {
        action: ACTION.name,
      },
    ]);

    this.store.dispatch(setToolBarOptions({
      setToolBar: true
    }));
  }

  /**
   * It routes to the goal list screen
   * @param pillar The clicked pillar object
   */
  onPillarClickHandler(pillar) {
    this.router.navigate([
      RouteName.GOAL,
      {
        action: ACTION.name,
        pillar: pillar.pillarId
      },
    ]);
  }

  /**
   * It is the drag and drop event
   */
  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      this.pillarState,
      event.previousIndex,
      event.currentIndex
    );
  }

  /**
   * Filters the agenda activities by the selected date
   * @param selectedDay day of the week
   */
  dateSelectionChangeHandler(selectedDay) {
    if (selectedDay) {
      this.agenda$ = this.agendaApiService.getAgenda(selectedDay, this.serviceAccount?.profileRef);
    }
  }
}
