import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import MOMENT from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';
import { GoalApiService } from 'src/app/services/firebase/goal/goal-api.service';
import { ObjectiveApiServiceV2 } from 'src/app/services/firebase/objective/objective-api.service';
import { PillarApiService } from 'src/app/services/firebase/pillar/pillar-api.service';
import { addObjectiveDocument } from 'src/app/state-v2/action/objective.actions';
import { Pillar } from 'src/app/state-v2/model/Pillar.model';
import { ObjectiveModel } from 'src/app/state-v2/model/objective.model';
import { ServiceAccount } from 'src/app/state-v2/model/service-account.model';
import { GoalState } from 'src/app/state-v2/reducer/goal.reducer';
import { selectServiceAccount } from 'src/app/state-v2/selector/service-accont.selector';
import { AppLang } from 'src/assets/i18n/app-lang';
import { RouteName } from 'src/shared/router-paths';
import { SaveDataConfirmationComponent } from '../../dialogs/common/save-data-confirmation/save-data-confirmation.component';
import PT from './i18n/pt.json';
import { ObjectiveFormConfig } from './objective-form.config';

@Component({
  selector: 'app-objective-form',
  templateUrl: './objective-form.component.html',
  styleUrls: ['./objective-form.component.scss'],
})
export class ObjectiveFormComponent implements OnInit {

  /**
   * The screen content
   *
   * @type {*}
   * @memberof ObjectiveFormComponent
   */
  content: any;

  /**
   * The field settings
   *
   * @type {ObjectiveFormConfig}
   * @memberof ObjectiveFormComponent
   */
  config: ObjectiveFormConfig;
  formStep1 = new FormGroup({});
  formStep2 = new FormGroup({});
  formStep3 = new FormGroup({});

  /**
   * Objective model representing data on the screen
   *
   * @type {ObjectiveModel}
   * @memberof ObjectiveFormComponent
   */
  objective: ObjectiveModel = {
    idField: '',
    description: '',
    startDate: '',
    endDate: '',
    realityDescription: '',
    requiredResDescription: '',
    favorite: false,
    isCompleted: false,
    checkedResourceAvailable: new Array(),
    checkedResourceRequired: new Array(),
    active: true,
    goalId: '',
    objectiveId: '',
    pillarNode: '',
    priority: 0,
    profileId: ''
  };

  /**
   * It represents the current goal selection
   *
   * @type {GoalState}
   * @memberof ObjectiveFormComponent
   */
  goal: GoalState;

  /**
   * Pillar model representation
   *
   * @type {Pillar}
   * @memberof ObjectiveFormComponent
   */
  pillar: Pillar;

  /**
   * Service Account State
   */
  serviceAccount: ServiceAccount;

  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private objectiveFormConfig: ObjectiveFormConfig,
    private store: Store,
    private goalApi: GoalApiService,
    private pillarApi: PillarApiService,
    private objectiveApi: ObjectiveApiServiceV2
  ) {}

  ngOnInit(): void {
    this.store.select(selectServiceAccount).subscribe(serviceAccount => this.serviceAccount = serviceAccount);

    this.spinner.show();
    MOMENT.locale(AppLang.DEFAULT);
    const pillarNode = this.route.snapshot.paramMap.get('pillarNode') ?? '';
    const goalId = this.route.snapshot.paramMap.get('goalId') ?? undefined;
    const objectiveId = this.route.snapshot.paramMap.get('objectiveId') ?? undefined;

    if (pillarNode && goalId) {
      this.pillarApi.getPillarStream(pillarNode, this.serviceAccount?.profileRef ?? '')
        .pipe(take(1))
        .subscribe(pillars => pillars.forEach(pillar => this.pillar = pillar));

      this.goalApi.getGoalByGoalId(goalId)
        .pipe(take(1))
        .subscribe(goals => goals.forEach(goal => this.goal = goal));
    }

    // If there is one id, it's an update operation
    if (objectiveId) {
      this.objectiveApi.getObjectiveById(objectiveId)
        .pipe(take(1))
        .subscribe(objectives => objectives.forEach(objective => this.objective = objective));
    }

    this.config = this.objectiveFormConfig;
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;

    this.spinner.hide();
  }

  /**
   * On change description event
   * @param event string
   * @param field component field name
   */
  onChangeEventHandler(event, field: string) {
    if (event && field && typeof 'event' === 'string') {
      this.objective[field] = event;
    }
  }

  /**
   * Returns the resources available that are only selected
   */
  get resourceAvailable() {
    return _.filter(this.goal?.goalResAvailable, ['selected', true]);
  }

  /**
   * Returns the resources required that are only selected
   */
  get resourceRequired() {
    return _.filter(this.goal?.goalResRequired, ['selected', true]);
  }

  /**
   * It updates the selection value
   * @param checkBoxName string
   */
  onCheckResAvailable(event, checkBoxName: string, isResAvailable?: boolean) {
    if (event && checkBoxName && typeof checkBoxName === 'string') {
      const resourceList = isResAvailable
        ? this.objective.checkedResourceAvailable
        : this.objective.checkedResourceRequired;

      _.each(resourceList, (value, key) => {
        if (value.resource === checkBoxName) {
          value.selected = event.checked;
        } else {
          value.selected = !event.checked;
        }
      });
    }
  }

  /**
   * Add and submit the objective to db
   */
  onSubmitForm() {
    const dialogRef = this.dialog.open(SaveDataConfirmationComponent, {
      data: {
        confirm: true,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.spinner.show();
        const objectiveDoc = Object.assign({}, this.objective);
        this.store.dispatch(addObjectiveDocument(
          {
            objectiveDoc: {
              idField: objectiveDoc?.idField,
              description: objectiveDoc.description,
              active: true,
              endDate: new Date(this.config.startDate.getFullYear() + 1, this.config.startDate.getMonth(), this.config.startDate.getDate()),
              favorite: false, //TODO: add favorite option on the screen
              goalId: this.goal?.goalId,
              checkedResourceAvailable: this.resourceAvailable,
              checkedResourceRequired: this.resourceRequired,
              isCompleted: false,
              pillarNode: this.pillar?.pillarId,
              priority: 0, //TODO: Add priority order on the screen
              profileId: this.serviceAccount?.profileRef ?? '',
              realityDescription: objectiveDoc.realityDescription,
              requiredResDescription: objectiveDoc.requiredResDescription,
              startDate: new Date()
            }
          }
        ));

        this.snackBar.open(this.content.successAlert.message, '', {
          duration: 2000,
        });

        this.backFowardToPillarGoal(true);
      }
    });
  }

  /**
   * Routes back to the parent page
   * @param timeout boolean
   */
  backFowardToPillarGoal(timeout?: boolean) {
    setTimeout(
      () => {
        this.router.navigate([
          RouteName.OBJECTIVE,
          {
            pillarNode: this.pillar.pillarId,
            goalId: this.goal?.goalId
          },
        ]);
        this.spinner.hide();
      },
      timeout ? 200 : 0
    );
  }
}
