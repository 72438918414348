<div class="home-card">
  <div
    [ngClass]="{
      'header-blue': pillarId === 'self_development',
      'header-red': pillarId === 'health',
      'header-yellow': pillarId === 'family',
      'header-green': pillarId === 'work',
      'text-title-container': !pillarId,
      'active-hover': isSelected
    }"
  >
    <p class="text-title">
      {{ title }}
    </p>
    <p class="subtitle">
      {{ subTitle }}
    </p>
  </div>

  <ng-content></ng-content>
</div>
