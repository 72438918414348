<div class="m-container">
  <psm-goal-view
    [pillarItem]="pillar"
    [goalItem]="goal"
    [title]="content.pageHeader"
    [showGoalControls]="true">
  </psm-goal-view>
  <p-divider align="center">
  </p-divider>
  <psm-comments-section *ngIf="goalId" [parentNoteId]="goalId"></psm-comments-section>
</div>
