export const ACTION = {
  PARENT_ACTION: {
    APP_MENU: {
      name: 'APP_MENU',
      active: false
    },
    ACCOUNT_ACTIVATION: {
      name: 'ACCOUNT_ACTIVATION',
      active: false
    }
  }
}