<mat-toolbar #appToolBar *ngIf="showSideMenu" class="toolbar sidenav-toolbar">
  <button *ngIf="showSideMenuOption" mat-icon-button>
    <button mat-icon-button (click)="toggleNavBar(snav)">
      <mat-icon>menu</mat-icon>
    </button>
  </button>
  <button *ngIf="showToolbarOptions" mat-icon-button aria-label="Home" (click)="onToolbarBackClick()">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <h1 *ngIf="!showToolbarOptions" class="app-name">{{ content.appName }}</h1>
  <span style="flex: 1 1 auto;"></span>
  <button *ngIf="showToolbarOptions" mat-icon-button aria-label="Home" (click)="onHomeItemClick()">
    <mat-icon>home</mat-icon>
  </button>
</mat-toolbar>
<ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple">
  <p class="loading">{{ content.spinnerLoadingLabel }}</p>
</ngx-spinner>
<mat-sidenav-container class="sidenav-container container-border">
  <mat-sidenav
    #snav
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    [fixedInViewport]="mobileQuery.matches"
    fixedTopGap="65"
    [opened]="displaySideMenu"
    [disableClose]="displaySideMenu">
    <mat-nav-list class="p-p-4 p-d-flex p-flex-column p-jc-center">
      <psm-user-badge [profileState]="profileState$ | async"></psm-user-badge>
      <!-- MENU -->
      <psm-menu class="p-mt-4" (onLogout)="snav.toggle()"></psm-menu>
      <div class="p-mt-4">
        <div class="p-d-flex p-jc-center">
          <p-avatar shape="square" image="assets/images/svg/pdpf-logo-vert.svg" size="xlarge"></p-avatar>
        </div>
      </div>
    </mat-nav-list>
  </mat-sidenav>
  <!-- Router Outlet -->
  <mat-sidenav-content class="p-d-flex p-jc-center p-p-0">
    <router-outlet #outlet="outlet"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<mat-toolbar class="footer"></mat-toolbar>
