<app-master-content [masterContentPage]="pageContent" [form]="formGroup" alignContent="center" isFrontPage="true">
  <div class="p-flex-column psm-resp-login-container">
    <div class="p-shadow-6 p-p-4">
      <form [formGroup]="formGroup">
        <mat-form-field appearance="outline" class="form-field-extended">
          <mat-label>{{ content.login.fieldText1 }}</mat-label>
          <input #txtEmail matInput type="email" placeholder="{{ content.login.placeHolder1 }}" formControlName="email">
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-field-extended">
          <mat-label>{{ content.login.fieldText2 }}</mat-label>
          <input #txtPassword matInput [type]="hidePwd ? 'password' : 'text'" placeholder="{{ content.login.placeHolder2 }}" formControlName="password">
          <button mat-icon-button matSuffix (click)="hidePwd = !hidePwd" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePwd">
            <mat-icon>{{hidePwd ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
      </form>
      <psm-button [label]="content.login.lblBtnLogIn" square="true" (onClick)="onSubmitForm()"></psm-button>
      <p-divider></p-divider>
      <psm-button square="true" color="success" [label]="content.lblBtnActivateAcc" (onClick)="onAccountActivationClick()"></psm-button>
    </div>
  </div>
</app-master-content>
