import { createAction, props } from '@ngrx/store';
import { Pillar } from '../model/pillar.model';

export const getPillar = createAction(
  '[Pillar API] Get Pillar',
  props<{pillarId: string}>()
);

export const getPillarFailure = createAction(
  '[Pillar API] Get Pillar Failure',
  props<{error: any}>()
);

export const getPillars = createAction(
  '[Pillar API] Get Pillars'
);

export const getPillarsFailure = createAction(
  '[Pillar API] Get Pillars Failure',
  props<{error: any}>()
);

export const setPillarCollection = createAction(
  '[Pillar] Set Pillar Collection',
  props<{ profileId: string, pillars: Pillar[] }>()
);

export const setPillarCollectionSuccess = createAction(
  '[Pillar] Set Pillar Collection Success',
  props<{ pillars: Pillar[] }>()
);

export const setPillarCollectionFailure = createAction(
  '[Pillar] Set Pillar Collection Failure',
  props<{ error: any }>()
);

export const loadPillarCollection = createAction(
  '[Pillar] Load Pillar Collection',
  props<{ profileId: string }>()
);

export const loadPillarCollectionSuccess = createAction(
  '[Pillar] Load Pillar Collection Success',
  props<{ pillars: Pillar[] }>()
);

export const loadPillarCollectionFailure = createAction(
  '[Pillar] Load Pillar Collection Failure',
  props<{ error: any }>()
);
