import { Component, OnInit, Inject } from '@angular/core';
import { ColorEvent } from 'ngx-color';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import PT from './i18n/pt.json';
import { AppLang } from 'src/assets/i18n/app-lang';

export interface DialogData {
  color: string | '#fff';
}

@Component({
  selector: 'app-color-picker-dialog',
  templateUrl: './color-picker-dialog.component.html',
  styleUrls: ['./color-picker-dialog.component.scss']
})
export class ColorPickerDialogComponent implements OnInit {

  content: any;

  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<ColorPickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;
  }

  // color = {
    //   hex: '#333',
    //   rgb: {
    //     r: 51,
    //     g: 51,
    //     b: 51,
    //     a: 1,
    //   },
    //   hsl: {
    //     h: 0,
    //     s: 0,
    //     l: .20,
    //     a: 1,
    //   },
    // }
  changeComplete($event: ColorEvent) {
    this.data.color = $event.color.hex;
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

}
