import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { addObjectiveDocumentFailure, addObjectiveDocumentSuccess, getObjectivesApi, getObjectivesApiFailure, getObjectivesByIdApi, getObjectivesByIdApiFailure, getObjectivesByPillarNodeApi, getObjectivesByPillarNodeApiFailure } from 'src/app/state-v2/action/objective.actions';
import { AppLang } from 'src/assets/i18n/app-lang';
import { FirestorePath } from 'src/shared/firestore-paths';
import { PdpfPartyService } from '../../common/pdpf-party/pdpf-party.service';
import { ObjectiveModel } from './../../../state-v2/model/objective.model';
import PT from './i18n/pt.json';

@Injectable({
  providedIn: 'root'
})
export class ObjectiveApiServiceV2 {

  constructor(
    private translate: TranslateService,
    private store: Store,
    private af: AngularFirestore,
    private pdpfPartyService: PdpfPartyService
  ) { }

  /**
   * It adds a new document in the objectives collection
   * /objectives in Firestore
   * @param objectiveModel
   * @type ObjectiveModel
   */
  addDocument(objectiveModel: ObjectiveModel): Observable<{docId: String}> {
    //TODO: Remove the id attribute in both goal and object documents. It should use the doc id itself from firebase.
    try {
      const objectiveDocRef: ObjectiveModel = {
        ...objectiveModel
      };

      this.translate.setTranslation(AppLang.DEFAULT, PT, true);

      if (!objectiveDocRef.pillarNode) {
        throw new Error('The pillarNode value must be defined');
      }

      if (!objectiveDocRef.goalId) {
        throw new Error('The associated Goal has not been found');
      }

      if (objectiveDocRef.idField) {
        this.af.doc(FirestorePath.OBJECTIVES + '/' + objectiveDocRef.idField).set(objectiveDocRef, { merge: true });
        return of(objectiveDocRef.idField);
      }

      objectiveDocRef.objectiveId = this.pdpfPartyService.generateId(objectiveDocRef?.profileId ?? '', 'objective');
      this.af.collection(FirestorePath.OBJECTIVES).doc().set(objectiveDocRef);
      return of({docId: objectiveDocRef.objectiveId});
    } catch (error) {
      this.store.dispatch(addObjectiveDocumentFailure({error: error}));
    } finally {
      this.store.dispatch(addObjectiveDocumentSuccess());
    }
  }

  update(documentId: string) {
    //TODO: Write the update function for objective
  }

  /**
   * It returns all active objectives under a pillar node
   * @param pillarNode
   */
  getObjectivesByPillarNode(pillarNode: string, profileId: string): Observable<ObjectiveModel[]> {
    try {
      this.store.dispatch(getObjectivesByPillarNodeApi({ pillarNode }));

      if (pillarNode && profileId) {
        return this.af.collection<ObjectiveModel>(FirestorePath.OBJECTIVES, ref => ref
          .where('profileId', '==', profileId)
          .where('pillarNode', '==', pillarNode)
          .where('active', '==', true)
          .orderBy('priority')
          .orderBy('startDate', 'desc'))
          .valueChanges({idField: 'idField'});
      }

      return of([]);
    } catch (error) {
      console.error(error);
      this.store.dispatch(getObjectivesByPillarNodeApiFailure({
        error
      }));
    }
  }

  /**
   * It retrieves all objectives given a goal id. It retrieves all active objectives.
   * @param goalId
   * @returns Observable of Objectives
   */
  getObjectives(goalId: string): Observable<ObjectiveModel[]> {
    try {
      this.store.dispatch(getObjectivesApi({ goalId }));

      if (goalId) {
        return this.af.collection<ObjectiveModel>(FirestorePath.OBJECTIVES, ref => ref
          .where('goalId', '==', goalId)
          .where('active', '==', true)
          .orderBy('priority')
          .orderBy('startDate', 'desc'))
          .valueChanges({idField: 'idField'});
      }

      return of([]);
    } catch (error) {
      console.error(error);
      this.store.dispatch(getObjectivesApiFailure({
        error
      }));
    }
  }

  /**
   * It retrieves a objective document by ID
   * @param objectiveId
   * @returns Observable of Objectives
   */
  getObjectiveById(objectiveId: string): Observable<ObjectiveModel[]> {
    try {
      this.store.dispatch(getObjectivesByIdApi({ objectiveId }));

      if (objectiveId) {
        return this.af.collection<ObjectiveModel>(FirestorePath.OBJECTIVES, ref => ref
          .where('objectiveId', '==', objectiveId)
          .where('active', '==', true)
          .orderBy('priority'))
          .valueChanges({idField: 'idField'});
      }

      return of([]);
    } catch (error) {
      console.error(error);
      this.store.dispatch(getObjectivesByIdApiFailure({
        error
      }));
    }
  }

}
