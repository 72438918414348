<div class="card-container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{ 'dialogs.token-validation.title' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <h4>{{ msg | translate }}</h4>
    </mat-card-content>
    <div class="card-container">
      <div class="card-small-default">
        <button mat-raised-button mat-dialog-close color="alert" class="btn-size-default" (click)="onBackBtnAction()">{{ 'dialogs.token-validation.btn-close' | translate }}</button>
      </div>
    </div>
  </mat-card>
</div>