<app-master-content [masterContentPage]="page" [form]="form" alignContent="center" isFrontPage="true">
  <div class="p-flex-column psm-resp-login-container">
    <div class="p-shadow-6 p-p-4">
      <psm-text-input
        [parentFormGroup]="form"
        [controlName]="config.emailControl"
        [label]="content.fieldText1"
        [placeholder]="content.placeHolder1"
        [validators]="config.emailValidator"
        [value]="accActivationModel.email"
        (inputStatus)="emailStatusChange($event)">
      </psm-text-input>
      <psm-text-input
        [parentFormGroup]="form"
        [controlName]="config.tokenControl"
        [label]="content.fieldText2"
        [placeholder]="content.placeHolder2"
        [validators]="config.tokenValidator"
        [value]="accActivationModel.token"
        [maxLength]="config.tokenMaxLength"
        [minLength]="config.tokenMinLength"
        (inputStatus)="tokenStatusChange($event)">
      </psm-text-input>
      <psm-text-input
        [parentFormGroup]="form"
        [controlName]="config.passwordControl"
        [label]="content.fieldText3"
        [placeholder]="content.placeHolder3"
        [validators]="config.passwordValidator"
        [value]="accActivationModel.password"
        [minLength]="config.passwordMinLength"
        (inputStatus)="passwordStatusChange($event)"
        inputType="password">
      </psm-text-input>
      <psm-text-input
        [parentFormGroup]="form"
        [controlName]="config.passwordConfirmControl"
        [label]="content.fieldText4"
        [placeholder]="content.placeHolder4"
        [validators]="config.passwordConfirmValidator"
        [value]="accActivationModel.passwordConfirm"
        [minLength]="config.passwordConfirmMinLength"
        (inputStatus)="passwordConfirmStatusChange($event)"
        inputType="password">
      </psm-text-input>
      <psm-button
        [label]="content.btnEnviar"
        square="true"
        (onClick)="onSubmitForm()">
      </psm-button>
      <p-divider></p-divider>
      <psm-button
        [label]="content.btnVoltar"
        square="true"
        color="secondary"
        (onClick)="onBackBtnAction()">
      </psm-button>
    </div>
  </div>
</app-master-content>
