import { createReducer, on } from '@ngrx/store';
import { loadServiceAccountSuccess, setServiceAccountSuccess } from '../action/service-account.actions';
import { ServiceAccount } from '../model/service-account.model';

export interface ServiceAccountState {
  account: ServiceAccount | null;
}

export const initialState: ServiceAccountState = {
  account: null,
};

export const serviceAccountReducer = createReducer(
  initialState,
  on(setServiceAccountSuccess, loadServiceAccountSuccess, (state, { account }) => {
    return {...state, account}
  })
);
