import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PillarApiService } from 'src/app/services/firebase/pillar/pillar-api.service';
import { loadPillarCollection, loadPillarCollectionFailure, loadPillarCollectionSuccess, setPillarCollection, setPillarCollectionFailure, setPillarCollectionSuccess } from '../action/pillar.actions';

@Injectable()
export class PillarEffects {

  constructor(
    private actions$: Actions,
    private pillarApiService: PillarApiService) {
  }

  setPillarCollection$ = createEffect(() => this.actions$.pipe(
    ofType(setPillarCollection),
    switchMap(props =>
      this.pillarApiService.setPillarCollection(
        props.profileId,
        props.pillars
      ).pipe(
        map(pillars => {
          return setPillarCollectionSuccess({
            pillars: Array.from(pillars).slice()
          });
        }),
        catchError((error) => {
          return of(setPillarCollectionFailure({ error }));
        })
      ))
  ));

  loadPillarCollection$ = createEffect(() => this.actions$.pipe(
    ofType(loadPillarCollection),
    switchMap(props =>
      this.pillarApiService.getPillarsStream(props.profileId).pipe(
        map(pillars => {
          if (pillars.length > 0) {
            return loadPillarCollectionSuccess({
              pillars: Array.from(pillars).slice()
            });
          } else {
            return loadPillarCollectionSuccess({
              pillars: [
                {
                  order: 1,
                  pillarId: 'family',
                  shortTitle: 'Família',
                  title: 'Família'
                },
                {
                  order: 2,
                  pillarId: 'self_development',
                  shortTitle: 'Pessoal',
                  title: 'Desenv. Pessoal'
                },
                {
                  order: 3,
                  pillarId: 'work',
                  shortTitle: 'Trabalho',
                  title: 'Trabalho'
                },
                {
                  order: 4,
                  pillarId: 'health',
                  shortTitle: 'Saúde',
                  title: 'Saúde'
                }]
            });
          }
        }),
        catchError((error) => {
          return of(loadPillarCollectionFailure({ error }));
        })
      ))
  ));

}
