import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import PT from './i18n/pt.json';
import { AppLang } from 'src/assets/i18n/app-lang';

@Component({
  selector: 'app-account-in-use-dialog',
  templateUrl: './account-in-use-dialog.component.html',
  styleUrls: ['./account-in-use-dialog.component.scss']
})
export class AccountInUseDialogComponent implements OnInit {

  content: any;

  constructor(
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT['dialog-content'];
  }

}
