import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'psm-stepper-button',
  templateUrl: './psm-stepper-button.component.html',
  styleUrls: ['./psm-stepper-button.component.scss']
})
export class PsmStepperButtonComponent implements OnInit {

  /**
   * Button label
   */
   @Input()
   label: string;

   /**
    * It indicates whether the button is in a loading state
    */
   @Input()
   isLoading = false;

   /**
    * Icon source path in case required
    * To display only an icon, leave label as undefined
    */
   @Input()
   icon: string;

   /**
    * The icon position in the button
    * The default selection is left
    */
   @Input()
   iconPos: 'left' | 'right';

   /**
    * Indicates whether the button is disabled or not
    */
   @Input()
   disabled = false;

   /**
    * The button primary color
    * Primary is set as default
    */
   @Input()
   color: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'help' | 'danger';

   /**
    * If not defined the button is rounded
    */
   @Input()
   square = false;

   /**
    * Event emitter for the button click
    */
   @Output()
   onClick = new EventEmitter();

   /**
    * OnFocus event emitter
    */
   @Output()
   onFocus = new EventEmitter();

   /**
    * OnFocus event emitter
    */
    @Output()
    onBlur = new EventEmitter();

   constructor() { }

   ngOnInit(): void {
     if (this.icon) {
       this.icon = 'pi ' + this.icon;
     }
   }

   /**
    * It emits the button event click
    * @param event
    */
   handleClick(event) {
     this.onClick.emit(event);
   }

   /**
    * Emits the on focus event
    * @param event
    */
   handleOnFocus(event) {
     this.onFocus.emit(event);
   }

   /**
    * Emits the on blur event
    * @param event
    */
   handleOnBlur(event) {
     this.onBlur.emit(event);
   }

}
