import {
  Component,
  OnInit
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { GoalApiService } from 'src/app/services/firebase/goal/goal-api.service';
import { PillarApiService } from 'src/app/services/firebase/pillar/pillar-api.service';
import { openGoalsPage, openGoalsPageSuccess } from 'src/app/state-v2/action/goal.actions';
import { Pillar } from 'src/app/state-v2/model/Pillar.model';
import { GoalState } from 'src/app/state-v2/reducer/goal.reducer';
import { AppLang } from 'src/assets/i18n/app-lang';
import { RouteName } from 'src/shared/router-paths';
import { GoalLearnmoreComponent } from '../dialogs/goal-learnmore/goal-learnmore.component';
import { openGoalsPageFailure } from './../../state-v2/action/goal.actions';
import { ACTION } from './action/action';
import PT from './i18n/pt.json';
import { ServiceAccount } from 'src/app/state-v2/model/service-account.model';
import { selectServiceAccount } from 'src/app/state-v2/selector/service-accont.selector';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-goal',
  templateUrl: './goal.component.html',
  styleUrls: ['./goal.component.scss'],
})
export class GoalComponent implements OnInit {

  /**
   * The representation of the pillar document from firestore
   *
   * @type {AngularFirestoreDocument<Pillar>}
   * @memberof GoalComponent
   */
  pillars: Observable<Pillar[]>;
  pillar: Pillar;

  /**
   * Goal array from firestore
   *
   * @type {Observable<GoalState[]>}
   * @memberof GoalComponent
   */
  goals: Observable<GoalState[]>;

  /**
   * It represents the current pillar selection
   *
   * @type {string}
   * @memberof GoalComponent
   */
  pillarNode: string;

  /**
   * It represents the i18n content
   *
   * @type Json
   * @memberof GoalComponent
   */
  content: any;

  form = new FormGroup({});

  /**
  * Service Account State
  */
  serviceAccount: ServiceAccount;

  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private store: Store,
    private goalApi: GoalApiService,
    private pillarApi: PillarApiService
  ) {}

  ngOnInit(): void {
    this.store.select(selectServiceAccount).subscribe(serviceAccount => this.serviceAccount = serviceAccount);

    this.pillarNode = this.route.snapshot.paramMap.get('pillar') ?? '';

    if (this.pillarNode) {
      this.store.dispatch(openGoalsPage({ pillarNode: this.pillarNode ?? '' }));

      this.spinner.show();
      this.translate.setTranslation(AppLang.DEFAULT, PT, true);
      this.content = PT.content;

      if (this.pillarNode && this.serviceAccount?.profileRef) {
        this.pillars = this.pillarApi.getPillarStream(this.pillarNode, this.serviceAccount?.profileRef ?? '');
        this.goals = this.goalApi.getGoals(this.pillarNode, this.serviceAccount?.profileRef ?? '');
        this.pillars.subscribe(data => data.forEach(pillar => this.pillar = pillar));
      }

      this.spinner.hide();
      this.store.dispatch(openGoalsPageSuccess());
    } else {
      this.store.dispatch(openGoalsPageFailure({error: 'Pillar node not found'}));
    }
  }

  /**
   * It transition to the pillar essence view
   */
  onPillarEssenceClick() {
    this.router.navigate([
      RouteName.PILLAR_ESSENCE_VIEW,
      {
        action: ACTION.PARENT_ACTION.SELF.name,
        pillarAction: this.pillar.title,
      },
    ]);
  }

  /**
   * It routes to the object list component
   */
  onObjectiveList() {
    this.router.navigate([RouteName.OBJECTIVE_SUMMARY, {
      action: ACTION.PARENT_ACTION.SELF.name
    }]);
  }

  onAddClick() {
    this.router.navigate([
      RouteName.GOAL_FORM,
      {
        pillar: this.pillarNode
      },
    ]);
  }

  /**
   * It opens the dialog for the learn more screen
   */
  onLearnMoreGoals() {
    this.dialog.open(GoalLearnmoreComponent);
  }

}
