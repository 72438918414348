import { createReducer, on } from '@ngrx/store';
import { setSideMenu, setTokenStatus, setToolBarOptions, setToolBarURL } from '../action/component.actions';

export interface ComponentState {
  tokenStatus: {
      status: boolean,
      msg: string,
  };
  toolbarOptions: boolean;
  toolbarURL: {
      url: string,
      params: {}
  };
  sideMenu: boolean;
}

export const initialState: ComponentState = {
  toolbarOptions: false,
  toolbarURL: {
      url: '',
      params: {}
  },
  tokenStatus: {
      status: false,
      msg: undefined,
  },
  sideMenu: false
}

export const componentReducer = createReducer(
  initialState,
  on(setTokenStatus, (state, { msg, status }) => {
    return {...state, msg, status}
  }),
  on(setToolBarOptions, (state, { setToolBar }) => {
    return {...state, setToolBar}
  }),
  on(setToolBarURL, (state, { url, params }) => {
    return {...state, url, params}
  }),
  on(setSideMenu, (state, { setSideMenu }) => {
    return {...state, setSideMenu}
  })
);
