import { createAction, props } from '@ngrx/store';
import { Agenda } from '../model/agenda.model';

export const openAgendaPage = createAction(
  '[Agenda Page] Open Agenda Page'
);

export const saveAgendaActivity = createAction(
  '[Agenda Page] Save Agenda Activity'
);

export const deleteAgendaActivity = createAction(
  '[Agenda Page] Delete Agenda Activity'
);

export const viewAgendaActivity = createAction(
  '[Agenda Page] View Agenda Activity'
);

export const destroyAgendaPage = createAction(
  '[Agenda Page] Destroy Agenda Page'
);

export const addAgendaItem = createAction(
  '[Agenda API] Add Agenda Item',
  props<{agenda: Agenda}>()
);

export const addAgendaItemSuccess = createAction(
  '[Agenda API] Add Agenda Item Success',
  props<{docId: string}>()
);

export const addAgendaItemFailure = createAction(
  '[Agenda API] Add Agenda Item Failure',
  props<{error: any}>()
);

export const deleteAgendaItem = createAction(
  '[Agenda API] Delete Agenda Item',
  props<{idField: any}>()
);

export const deleteAgendaItemSuccess = createAction(
  '[Agenda API] Delete Agenda Item Success',
  props<{idField: string}>()
);

export const deleteAgendaItemFailure = createAction(
  '[Agenda API] Delete Agenda Item Failure',
  props<{error: any}>()
);

export const getAgenda = createAction(
  '[Agenda API] Get Agenda'
);

export const getAgendaFailure = createAction(
  '[Agenda API] Get Agenda Failure',
  props<{error: any}>()
);

export const getAgendaByIdFailure = createAction(
  '[Agenda API] Get Agenda by Id Failure',
  props<{error: any}>()
);
