import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { setTokenStatus } from 'src/app/state-v2/action/component.actions';
import { FirestorePath } from 'src/shared/firestore-paths';

@Injectable({
  providedIn: 'root'
})
export class TokenService implements OnDestroy {

  saSubscription: Subscription;

  TOKEN_STATUS = {
    SUSPENDED: {
      name: 'suspended'
    },
    ACTIVE: {
      name: 'active'
    },
    INACTIVE: {
      name: 'inactive'
    },
    TRIAL: {
      name: 'trial'
    }
  };

  constructor(
    private db: AngularFirestore,
    public translate: TranslateService,
    private store: Store
  ) { }

  ngOnDestroy(): void {
    this.saSubscription.unsubscribe();
  }

  validateToken(token: string) {
    this.saSubscription = this.db.collection(FirestorePath.SERVICE_ACCOUNTS, ref => ref.where('token', '==', token))
      .get().subscribe(docRef => {
        if (docRef.size == 0) {
          this.setTokenStatusSession('invalidToken');
          return;
        }

        docRef.forEach(docs => {
          const doc: any = docs.data();
          // It checks whether the token is already activated and return error message
          if (doc.status) {
            this.setTokenStatusSession('inUseToken');
            return;
          }

          // It verifies whether there is a product associated with the service account
          const productName = doc.productName;
          if (productName === undefined || productName === '') {
            this.setTokenStatusSession('noProductLinked');
            return;
          }

          // It checks whether the token is expired and return error message
          const tokenExpireDate = new Date(doc.expireDTTM);
          if (tokenExpireDate === undefined || tokenExpireDate < new Date()) {
            this.setTokenStatusSession('expired');
            return;
          }

          // It checks whether the product associated with the token is active
          const companyName: string = doc.companyName;
          if (companyName === undefined || companyName === '') {
            this.setTokenStatusSession('notActive');
            return;
          }

          this.db.collection(FirestorePath.COMPANIES, ref => ref.where('name', '==', companyName))
            .get().subscribe(docRef => {
              if (docRef.size == 0) {
                this.setTokenStatusSession('noCompanyLinked');
                return;
              }

              docRef.forEach(doc => {
                const compProdCollectionPath =
                  FirestorePath.COMPANIES + '/' + doc.id + '/' + FirestorePath.COMPANY_PRODUCTS;

                this.db.collection(compProdCollectionPath, ref => ref.where('name', '==', productName))
                  .get().subscribe(querySnapshot => {
                    if (querySnapshot.size == 0) {
                      this.setTokenStatusSession('noProductAndCompanyRegistered');
                      return;
                    }

                    querySnapshot.forEach(prod => {
                      const prodData: any = prod.data();
                      const productStatus: string = prodData.status;

                      if (productStatus === undefined || productStatus === '') {
                        this.setTokenStatusSession('productAndTokenInvalid');
                        return;
                      }

                      if ((productStatus.localeCompare(this.TOKEN_STATUS.ACTIVE.name) == 0)
                        || (productStatus.localeCompare(this.TOKEN_STATUS.TRIAL.name) == 0)) {
                          // Token is in good shape and user can move on
                          this.setTokenStatusSession('', true);
                          return;
                      } else {
                        this.setTokenStatusSession('productNotActive');
                        return;
                      }
                    });
                }, error => {
                  console.error(error);
                });
              });
            }, error => {
              console.error(error);
            });
        });
      });
  }

  /**
   *
   * @param i18n The message it should display if any
   * @param status The token status. Default is false
   */
  private setTokenStatusSession(i18n?: string | '', status?: boolean | false) {
    this.store.dispatch(setTokenStatus({
      status,
      msg: i18n === '' ? i18n : 'dialogs.token-validation.' + i18n
    }));
  }

}
