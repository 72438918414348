<div class="m-container">
  <psm-home-card
    [title]="pillar.title"
    [pillarId]="pillar.pillarId">
    <div class="p-flex-column w-full">
      <mat-vertical-stepper #stepper [linear]="true" class="p-shadow-2">
        <mat-step [stepControl]="formStep1">
          <form [formGroup]="formStep1">
            <ng-template matStepLabel>{{content.lblStep1}}</ng-template>
            <mat-card class="card-margin">
              <mat-card-content>
                <psm-form-field
                  [title]="content.lblTitle"
                  [hint]="content.lbltitleHint">
                  <psm-text-input
                    [parentFormGroup]="formStep1"
                    [controlName]="config.titleFormControl"
                    [value]="goal?.header"
                    [maxLength]="config.titleMaxLength"
                    [minLength]="config.titleMinLength"
                    [validators]="config.titleValidators"
                    (inputStatus)="onChangeStatus($event, 'header')">
                  </psm-text-input>
                </psm-form-field>
              </mat-card-content>
            </mat-card>
            <mat-card class="card-margin">
              <mat-card-content>
                <psm-form-field
                  [title]="content.lblDescription"
                  [hint]="content.lblDescriptionHint">
                  <psm-text-area
                    [parentFormGroup]="formStep1"
                    [controlName]="config.descriptionFormControl"
                    [value]="goal?.description"
                    [maxLength]="config.descriptionMaxLength"
                    [minLength]="config.descriptionMinLength"
                    [validators]="config.descriptionValidators"
                    (inputStatus)="onChangeStatus($event, 'description')">
                  </psm-text-area>
                </psm-form-field>
              </mat-card-content>
            </mat-card>
            <mat-card class="card-margin">
              <mat-card-content>
                <psm-form-field
                  [title]="content.lblReason"
                  [hint]="content.lblReasonHint">
                  <psm-text-area
                    [parentFormGroup]="formStep1"
                    [controlName]="config.reasonFormControl"
                    [value]="goal?.reason"
                    [maxLength]="config.reasonMaxLength"
                    [minLength]="config.reasonMinLength"
                    [validators]="config.reasonValidators"
                    (inputStatus)="onChangeStatus($event, 'reason')">
                  </psm-text-area>
                </psm-form-field>
              </mat-card-content>
            </mat-card>
            <psm-stepper-button [label]="content.btnNext"></psm-stepper-button>
          </form>
        </mat-step>
        <mat-step [stepControl]="formStep2">
          <form [formGroup]="formStep2">
            <ng-template matStepLabel>{{content.lblStep2}}</ng-template>
            <mat-card class="card-margin">
              <mat-card-content>
                <psm-form-field
                  [title]="content.lblReality"
                  [hint]="content.lblRealityHint">
                  <psm-text-area
                    [parentFormGroup]="formStep2"
                    [controlName]="config.realityFormControl"
                    [value]="goal?.reality"
                    [maxLength]="config.realityMaxLength"
                    [minLength]="config.realityMinLength"
                    [validators]="config.realityValidators"
                    [rows]="6"
                    (inputStatus)="onChangeStatus($event, 'reality')">
                  </psm-text-area>
                </psm-form-field>
              </mat-card-content>
            </mat-card>
            <mat-card class="card-margin">
              <mat-card-content>
                <psm-form-field
                  [title]="content.lblResources"
                  [hint]="content.lblResourcesHint">
                  <section class="section-margin">
                    <span>
                      <ul>
                        <li *ngFor="let row of goal?.goalResAvailable">
                          <psm-checkbox
                            [parentFormGroup]="formStep2"
                            controlName="{{row.resource + 'ResAvailableControl'}}"
                            [checked]="row.selected"
                            [label]="row.resource"
                            (status)="onCheckResAvailable($event, row.resource, true)">
                          </psm-checkbox>
                        </li>
                      </ul>
                    </span>
                  </section>
                </psm-form-field>
              </mat-card-content>
            </mat-card>
            <mat-card class="card-margin">
              <mat-card-content>
                <psm-form-field
                  [title]="content.lblResourceRequired"
                  [hint]="content.lblResourceRequiredHint">
                  <section class="section-margin">
                    <span>
                      <ul>
                        <li *ngFor="let row of goal?.goalResRequired">
                          <psm-checkbox
                            [parentFormGroup]="formStep2"
                            controlName="{{row.resource + 'ResRequiredControl'}}"
                            [checked]="row.selected"
                            [label]="row.resource"
                            (status)="onCheckResAvailable($event, row.resource)">
                          </psm-checkbox>
                        </li>
                      </ul>
                    </span>
                  </section>
                </psm-form-field>
              </mat-card-content>
            </mat-card>
            <div class="p-d-flex p-flex-column">
              <div class="p-mb-2">
                <psm-button [label]="content.btnSave" (onClick)="onSubmitForm(goal)"></psm-button>
              </div>
              <!-- <div class="p-mb-2">
                <psm-button [label]="content.btnCancel" color="danger" (onClick)="backFowardToPillarGoal(true)"></psm-button>
              </div> -->
            </div>
          </form>
        </mat-step>
      </mat-vertical-stepper>
    </div>
  </psm-home-card>
</div>
