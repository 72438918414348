import { createReducer, on } from '@ngrx/store';
import { loginSession, logoutSession } from '../action/login.actions';

export interface LoginState {
  logged: boolean;
}

export const initialState: LoginState = {
  logged: false,
};

export const loginReducer = createReducer(
  initialState,
  on(loginSession, (state) => {
    return { ...state, logged: true }
  }),
  on(logoutSession, (state) => {
    return { ...state, logged: false }
  })
);
