<div class="p-d-flex p-jc-center text-input-psm" *ngIf="!isDatePicker; else datePickerInput">
  <form [formGroup]="parentFormGroup">
    <mat-form-field
      appearance="outline"
      [disabled]="disabled"
      [ngClass]="{'fieldEmpty': !value}">
      <mat-label
        *ngIf="label">{{ label }}
      </mat-label>
      <input
        matInput
        onlyNumbers
        autocomplete="off"
        [textMask]="mask"
        [name]="name"
        [allowNumbers]="allowOnlyNumbers"
        [readonly]="readonly"
        [formControlName]="controlName"
        [placeholder]="placeholder"
        [type]="inputType"
        [value]="value"
        [maxLength]="maxLength"
        [minLength]="minLength"
        [required]="required"
        (focus)="inputFocus.emit($event)"
        (blur)="inputBlur.emit($event)"
        (ngModelChange)="onNgModelChange($event)"
      >
    </mat-form-field>
  </form>
  <!-- <div class="error-container">
    <i class="pi pi-info-circle error-icon"></i>
  </div> -->
</div>

<ng-template #datePickerInput>
  <form [formGroup]="parentFormGroup">
    <mat-form-field
      appearance="outline"
      [disabled]="disabled">
      <mat-label
        *ngIf="label">{{ label }}
      </mat-label>
      <input
        matInput
        autocomplete="off"
        [name]="name"
        [readonly]="readonly"
        [formControlName]="controlName"
        [placeholder]="placeholder"
        [type]="inputType"
        [value]="value"
        [maxLength]="maxLength"
        [minLength]="minLength"
        [required]="required"
        [matDatepicker]="datePicker"
        (focus)="inputFocus.emit($event)"
        (blur)="inputBlur.emit($event)"
        (ngModelChange)="onNgModelChange($event)"
        (click)="datePicker.open()"
      >
      <mat-datepicker-toggle
        matSuffix
        [for]="datePicker">
      </mat-datepicker-toggle>
      <mat-datepicker
        #datePicker>
      </mat-datepicker>
    </mat-form-field>
  </form>
</ng-template>
