import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-account-activation-form-invalid-dialog',
  templateUrl: './account-activation-form-invalid-dialog.component.html',
  styleUrls: ['./account-activation-form-invalid-dialog.component.css']
})
export class AccountActivationFormInvalidDialogComponent implements OnInit {

  constructor(
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
  }

}
