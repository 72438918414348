<div class="p-flex-column p-jc-center">
  <div class="text-header">
    {{ content.title }}
  </div>
  <div class="text-subtitle p-text-left">
    <p>{{ content.msg1 }}</p>
  </div>
  <div class="p-grid p-jc-center">
    <div class="p-col-6">
      <psm-button mat-dialog-close color="danger" [label]="content.btnClose"></psm-button>
    </div>
  </div>
</div>
