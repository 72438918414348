import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Output,
  Input,
  EventEmitter,
} from '@angular/core';
import { FormControl, ValidatorFn, FormGroup } from '@angular/forms';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'psm-text-area',
  templateUrl: './psm-text-area.component.html',
  styleUrls: ['./psm-text-area.component.scss'],
})
export class PsmTextAreaComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * Default is 5 rows
   */
  @Input() rows = 5;

  /**
   * Parent form group with the text input form control
   */
  @Input() parentFormGroup: FormGroup;

  /**
   * Whether or not the form is disabled
   * Default is false
   */
  @Input() disabled = false;

  /**
   * Label for the input
   */
  @Input() label: string;

  /**
   * Whether or not the field is readonly
   * Default is false
   */
  @Input() readonly = false;

  /**
   * Placeholder for the text
   */
  @Input() placeholder: string;

  /**
   * The control name of the form defined in the formgroup
   */
  @Input() controlName: string;

  /**
   * The value of the field
   */
  @Input() value: string;

  /**
   * The max length allowed
   */
  @Input() maxLength: number;

  /**
   * The min length allowed
   */
  @Input() minLength: number;

  /**
   * Whether the field is required in the form
   */
  @Input() required = false;

  /**
   * Hint text to be displayed in the field
   */
  @Input() hintText: string;

  /**
   * Form validators
   */
  @Input() validators: ValidatorFn[];

  /**
   * The field name
   */
  @Input() name: string;

  /**
   * Emits the focus event
   */
  @Output() inputFocus = new EventEmitter();

  /**
   * Emits the blur event
   */
  @Output() inputBlur = new EventEmitter();

  /**
   * Emits the status event
   */
  @Output() inputStatus = new EventEmitter();

  /**
   * Form control to the text input
   */
  control: FormControl;

  constructor() {}

  ngOnInit(): void {
    this.control = new FormControl(
      {
        value: this.value,
        disabled: this.disabled,
      },
      this.validators
    );
    this.parentFormGroup.addControl(this.controlName, this.control);
  }

  /**
   * Callback when the model change
   * @param value string
   */
  onNgModelChange(value: string) {
    this.inputStatus.emit(value);
  }

  /**
   * Angular lifecyle hook
   * @param changes SimpleChanges
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && this.controlName) {
      const formCtrl = this.parentFormGroup.get(this.controlName);
      if (formCtrl) {
        formCtrl.setValue(this.value);
      }
    }
  }

  /**
   * It removes the input form control to the parent form
   */
  ngOnDestroy(): void {
    if (
      this.parentFormGroup &&
      this.parentFormGroup.contains(this.controlName)
    ) {
      this.parentFormGroup.removeControl(this.controlName);
    }
  }
}
