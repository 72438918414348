import { Injectable } from '@angular/core';
import { OptionModel } from 'src/app/components/psm-dropdown/psm-dropdown.component';
import { Validators } from '@angular/forms';

@Injectable()
export class UserDobGenderConfig {

  dobFormControl = 'dobControl';
  dobValidators = [
    Validators.required
  ];

  genderList: Array<OptionModel> = [
    { value: 'man', viewValue: 'Masculino' },
    { value: 'woman', viewValue: 'Feminino' },
    { value: 'other', viewValue: 'Outro' }
  ];

  constructor() { }

}
