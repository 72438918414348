import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Note } from 'src/app/state-v2/model/note.model';
import { FirestorePath } from 'src/shared/firestore-paths';

@Injectable({
  providedIn: 'root'
})
export class NotesFirebaseService {

  constructor(
    private af: AngularFirestore
  ) { }

  /**
   * It adds the note to the generic document path
   * @param note of dictionary type
   */
  addNote(note: Note): Observable<Note> {
    if (!note.note) {
      throw('The note cannot be undefined');
    }

    if (!note.parentType) {
      throw('The note must be associated with a parent type, either a goal or objective.');
    }

    if (note?.idField) {
      this.af.doc(FirestorePath.NOTES + '/' + note.idField).set({
        ...note,
        lastUpdated: new Date()
      }, { merge: true });
    } else {
      this.af.collection(FirestorePath.NOTES).add({
        ...note,
        favorite: false,
        creationDate: new Date()
      });
    }

    return of(note);
  }

  /**
   * It removes the given note from the firebase
   * @param note
   * @returns
   */
  deleteNote(idField: string): Observable<string> {
    if (idField) {
      this.af.collection<Note>(FirestorePath.NOTES).doc(idField).delete();
    }

    return of(idField);
  }

  /**
   * It returns all notes by parent id
   * @param parentId
   * @returns
   */
  getNotesByParentId(parentId: string): Observable<Note[]> {
    return this.af.collection<Note>(FirestorePath.NOTES, ref => ref
      .where('parentId', '==', parentId)
      .orderBy('creationDate', 'desc')
      .orderBy('favorite', 'desc'))
      .valueChanges({idField: 'idField'});
  }

}
