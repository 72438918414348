import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MasterContentPage } from './master-content.class.page';

@Component({
  selector: 'app-master-content',
  templateUrl: './master-content.component.html',
  styleUrls: ['./master-content.component.scss']
})
export class MasterContentComponent implements OnInit {

  /**
   * The content of the page
   */
  @Input() masterContentPage: MasterContentPage;

  /**
   * The form group of the child component
   */
  @Input() form: FormGroup;

  /**
   * Determines the alignment of the content
   * Default selection is center
   */
  @Input() alignContent: 'left' | 'center' | 'right' = 'center';

  /**
   * If front page true, it defines different css display
   */
  @Input() isFrontPage = false;

  /**
   * The button forward event emitter
   */
  @Output() btnForwardEvent = new EventEmitter();

  /**
   * The button backward event emitter
   */
  @Output() btnBackwardEvent = new EventEmitter();

  get displayLogoTitle () {
    return this.masterContentPage.displayBrandLogo || this.masterContentPage.headerTitle;
  }

  constructor() { }

  ngOnInit(): void {
    if (!this.form) {
      this.form = new FormGroup({});
    }
  }

  emitBackwardEvent(event) {
    this.btnBackwardEvent.emit(event);
  }

  emitForwardEvent(event) {
    this.btnForwardEvent.emit(event);
  }

}
