export class MasterButton {
  label: string;
  options: {
    icon?: string
  } = {};

  constructor(
    label?: string | undefined,
    options?: {
      // Path to a icon if required
      icon?: string
    }) {
      this.label = label;
      this.options = options;
    }
}
