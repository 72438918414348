<psm-home-card [title]="content.titleNotes">
  <section>
    <div class="comments-card" *ngFor="let note of activityNotes; let i = index">
      <div class="comment-body">
        {{ note.note }}
      </div>
      <div class="comment-footer">
        <div class="flex flex-row" (click)="onMarkFavoriteNote(note)">
          <mat-icon *ngIf="!note?.favorite" class="comment-icon-favorite">favorite_border</mat-icon>
          <mat-icon *ngIf="note?.favorite" class="comment-icon-favorite">favorite</mat-icon>
          <div class="comment-favorite">
            {{ content.favoriteNote }}
          </div>
        </div>
        <div class="flex flex-row" (click)="onEraseNoteClick(note)">
          <mat-icon class="comment-icon-erase">remove</mat-icon>
          <div class="comment-erase">
            {{ content.eraseNote }}
          </div>
        </div>
        <div class="comment-date">
          {{ note?.creationDate.toMillis() | date: 'dd/MM/yyyy h:mm:ss a' }}
        </div>
      </div>
    </div>
  </section>
</psm-home-card>
