<div class="p-d-flex">
  <form [formGroup]="parentFormGroup">
    <mat-checkbox
      *ngIf="!disabled"
      [required]="required"
      [attr.aria-checked]="checked"
      [formControlName]="controlName"
      [name]="controlName"
      (change)="status.emit($event)"
      (click)="handleBoxSelection($event)"
      [ngModelOptions]="{ standalone: true }"
    >
      <span class="checkbox-label">
        {{ label }}
      </span>
    </mat-checkbox>
    <mat-checkbox
      *ngIf="disabled"
      [required]="required"
      [attr.aria-checked]="checked"
      [formControlName]="controlName"
      [name]="controlName"
      (change)="status.emit($event)"
      (click)="handleBoxSelection($event)"
      [ngModelOptions]="{ standalone: true }"
      [class.mat-checkbox-disabled]="false"
    >
      <span class="checkbox-label">
        {{ label }}
      </span>
    </mat-checkbox>
  </form>
</div>
