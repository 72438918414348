import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AppLang } from 'src/assets/i18n/app-lang';

import PT from './i18n/pt.json';

@Component({
  selector: 'app-goal-learnmore',
  templateUrl: './goal-learnmore.component.html',
  styleUrls: ['./goal-learnmore.component.scss']
})
export class GoalLearnmoreComponent implements OnInit {

  content: any;

  constructor(
    private translate: TranslateService,
    private dialogRef: MatDialogRef<GoalLearnmoreComponent>
  ) { }

  ngOnInit(): void {
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;
    setTimeout(() => {
      if (document.getElementById('dialog-container')) {
        document.getElementById('dialog-container').scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    }, 500);
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

}
