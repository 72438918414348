<div class="p-flex-column p-jc-center">
  <div class="text-header">
    {{ 'dialogs.login.login-invalid-form.title' | translate }}
  </div>
  <div class="text-subtitle p-text-left">
    <h2>{{ 'dialogs.login.login-invalid-form.msg1' | translate }}</h2>
    <ul>
      <li>
        <span>&#10003;</span> {{ 'dialogs.login.login-invalid-form.msg2' | translate }}
      </li>
      <li>
        <span>&#10003;</span> {{ 'dialogs.login.login-invalid-form.msg3' | translate }}
      </li>
    </ul>
  </div>
  <div class="p-grid p-jc-center">
    <div class="p-col-6">
      <psm-button mat-dialog-close color="danger" [label]="'dialogs.login.login-invalid-form.btn-close' | translate"></psm-button>
    </div>
  </div>
</div>
