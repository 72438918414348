import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GoalApiService } from 'src/app/services/firebase/goal/goal-api.service';
import { AppLang } from 'src/assets/i18n/app-lang';
import { addGoalDocument, addGoalDocumentFailure, addGoalDocumentSuccess, deleteGoalDocument, deleteGoalDocumentFailure, deleteGoalDocumentSuccess } from '../action/goal.actions';
import PT from './i18n/pt.json';

@Injectable()
export class GoalEffects {

  constructor(
    private actions$: Actions,
    private goalApi: GoalApiService,
    private snackBar: MatSnackBar,
    private translate: TranslateService) {
  }

  deleteGoalDocument$ = createEffect(() => this.actions$.pipe(
    ofType(deleteGoalDocument),
    switchMap(props =>
      this.goalApi.deleteGoalDocument(props.docId).pipe(
        map(docId => {
          this.translate.setTranslation(AppLang.DEFAULT, PT, true);
          this.snackBar.open(
            PT['service-notification'].success, '', {
            duration: 2 * 1000
          });
          return deleteGoalDocumentSuccess();
        }),
        catchError(error => of(deleteGoalDocumentFailure({ error })))
      ))
  ));

  addGoalDocument$ = createEffect(() => this.actions$.pipe(
    ofType(addGoalDocument),
    switchMap(props =>
      this.goalApi.addGoalDocument(props.goal).pipe(
        map(response => {
          this.translate.setTranslation(AppLang.DEFAULT, PT, true);
          this.snackBar.open(
            PT['service-notification'].success, '', {
            duration: 2 * 1000
          });
          return addGoalDocumentSuccess();
        }),
        catchError((error) => {
          this.snackBar.open(
            PT['service-notification'].failure, '', {
            duration: 2 * 1000
          })
          return of(addGoalDocumentFailure({error: error}));
        })
      ))
  ));

}
