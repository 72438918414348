<div class="m-container">
  <div *ngFor="let pillar of pillars | async">
    <psm-home-card
      [title]="content.lblMyGoals + ' ' + pillar.title"
      [pillarId]="pillar.pillarId"
      [subTitle]="content.lblSubtitle">
      <div class="card-content">
        <button mat-button (click)="onAddClick()">
          <mat-icon>add_circle</mat-icon>
          {{ content.btnAdd }}
        </button>
        <button mat-button (click)="onObjectiveList()">
          <mat-icon>list_alt</mat-icon>
          {{ content.btnObjective }}
        </button>
        <button mat-button>
          <mat-icon>photo_camera_outline</mat-icon>
          {{ content.btnPicture }}
        </button>
        <button mat-button (click)="onLearnMoreGoals()">
          <mat-icon>info_outline</mat-icon>
          {{ content.btnGoalLearnMore }}
        </button>
        <button mat-button (click)="onPillarEssenceClick()">
          <mat-icon>auto_awesome_outline</mat-icon>
          {{ content.btnEssence }}
        </button>
      </div>
    </psm-home-card>
  </div>
  <div class="p-flex-column w-full">
    <div *ngFor="let goal of goals | async; let i = index">
      <psm-goal-view
        [pillarItem]="pillar"
        [goalItem]="goal"
        [title]="content.goalCard.title + ' ' + (i + 1)"
        [showGoalControls]="true"
        [showGoalCRUDControls]="true">
      </psm-goal-view>
    </div>
  </div>
</div>
