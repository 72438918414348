<app-master-content
  [masterContentPage]="page"
  [form]="form"
  (btnBackwardEvent)="btnBackwardEvent($event)"
  (btnForwardEvent)="btnForwardEvent($event)"
>
  <div class="p-flex-column w-full p-mt-4">
    <mat-card class="p-p-3">
      <psm-text-input
        [parentFormGroup]="form"
        [controlName]="config.phoneFormControl"
        [value]="getPhoneValue(profile.personalInfo?.phoneNumber)"
        [maxLength]="config.phoneMaxLength"
        [minLength]="config.phoneMinLength"
        [label]="content.phoneLbl"
        [mask]="textMaskConfig.phoneMaskConfig"
        [inputType]="textMaskConfig.ptPhoneMask.inputType"
        allowOnlyNumbers="true"
        [validators]="config.phoneValidators">
      </psm-text-input>
    </mat-card>
  </div>
</app-master-content>
