import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { GoalApiService } from 'src/app/services/firebase/goal/goal-api.service';
import { PillarApiService } from 'src/app/services/firebase/pillar/pillar-api.service';
import { openGoalDetails } from 'src/app/state-v2/action/goal.actions';
import { Pillar } from 'src/app/state-v2/model/Pillar.model';
import { ServiceAccount } from 'src/app/state-v2/model/service-account.model';
import { GoalState } from 'src/app/state-v2/reducer/goal.reducer';
import { selectServiceAccount } from 'src/app/state-v2/selector/service-accont.selector';
import { AppLang } from 'src/assets/i18n/app-lang';
import PT from './i18n/pt.json';

@Component({
  selector: 'app-goal-detail',
  templateUrl: './goal-detail.component.html',
  styleUrls: ['./goal-detail.component.scss']
})
export class GoalDetailComponent implements OnInit {

  /**
   * The goal object data item to be shown on the screen
   *
   */
  goal: GoalState;

  /**
   * Object representing the selected pillar
   */
  pillar: Pillar;

  /**
   * i18n Content
   */
  content: any;

  /**
   * It represents the service account state
   */
  serviceAccount: ServiceAccount;

  /**
   * Represents the goal id
   */
  goalId;

  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private store: Store,
    private goalApi: GoalApiService,
    private pillarApi: PillarApiService
  ) { }

  ngOnInit() {
    this.store.dispatch(openGoalDetails());
    this.store.select(selectServiceAccount).subscribe(serviceAccount => this.serviceAccount = serviceAccount);

    const pillarNode = this.route.snapshot.paramMap.get('pillar') ?? '';
    this.goalId = this.route.snapshot.paramMap.get('goalId') ?? undefined;

    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;

    if (pillarNode) {
      this.pillarApi.getPillarStream(pillarNode, this.serviceAccount?.profileRef ?? '').pipe(take(1)).subscribe(
        pillars => pillars.forEach(pillar => this.pillar)
      );
    }

    if (this.goalId) {
      this.goalApi.getGoalByGoalId(this.goalId).pipe(take(1)).subscribe(
        goals => goals.forEach(goal => {
          this.goal = goal;
          this.goal.goalId = this.goalId;
        })
      );
    }
  }

}
