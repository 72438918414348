import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppLang } from 'src/assets/i18n/app-lang';
import { RouteName } from 'src/shared/router-paths';
import { MasterContentPage } from '../master-content/master-content.class.page';
import { ACTION } from './action/action';
import { Store } from '@ngrx/store';
import { setToolBarURL } from 'src/app/state-v2/action/component.actions';
import { Pillar } from 'src/app/state-v2/model/pillar.model';
import { ServiceAccount } from 'src/app/state-v2/model/service-account.model';
import { selectServiceAccount } from 'src/app/state-v2/selector/service-accont.selector';
import PT from './i18n/pt.json';



@Component({
  selector: 'app-pillar-configuration',
  templateUrl: './pillar-configuration.component.html',
  styleUrls: ['./pillar-configuration.component.scss']
})
export class PillarConfigurationComponent implements OnInit {

  /**
   * Pages' content
   */
  content: any;
  page: MasterContentPage;
  pillarAction: string;
  pillarModel: Pillar;

  /**
  * Service Account State
  */
  serviceAccount: ServiceAccount;

  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.store.select(selectServiceAccount).subscribe(serviceAccount => this.serviceAccount = serviceAccount);

    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;
    this.pillarAction = this.route.snapshot.paramMap.get('pillarAction');

    if (!this.page) {
      this.page = new MasterContentPage(
        true,
        true,
        undefined,
        this.content.lblMyGoalsTitle,
        [this.content.lblMyGoalsDesc]
      );
    }

    // this.pillarModel = this.selectedPillarQuery.getValue();

    this.store.dispatch(setToolBarURL({
      url: RouteName.GOAL,
      params: {
        action: ACTION.PARENT_ACTION.SELF.name
      }
    }));
  }

  /**
   * It is the drag and drop event
   */
  drop(event: CdkDragDrop<any[]>) {
    // moveItemInArray(this.pillarModel.goals, event.previousIndex, event.currentIndex);
    // this.pillarService.updateFirestoreDocument(this.pillarModel, this.serviceAccount?.profileRef ?? '');
    // this.selectedPillarService.updateStore(this.pillarModel);
  }

}
