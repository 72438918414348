<div class="p-p-3 p-d-flex p-flex-column p-jc-center"
  [ngClass]="{
    'psm-md-container': isFrontPage,
    'p-flex-md-row': isFrontPage,
    'mx-auto': !isFrontPage,
    'psm-resp-container': !isFrontPage
  }">
  <div *ngIf="masterContentPage.banner || displayLogoTitle || masterContentPage.displayHeader"
    class="p-mt-4" [ngClass]="{'w-full': isFrontPage, 'home-card': !isFrontPage}">
    <div *ngIf="masterContentPage.banner" class="p-d-flex p-mt-4 p-jc-center">
      <img class="banner" [src]="masterContentPage.banner" aria-hidden="true">
    </div>
    <div *ngIf="displayLogoTitle" id="card-header" class="p-d-flex" [ngClass]="{'master-content-resp': isFrontPage}">
      <div *ngIf="masterContentPage.displayBrandLogo" class="p-mt-4 p-ml-4 p-mb-4">
        <p-avatar image="assets/images/svg/pdpf-logo-vert-icon.svg" shape="circle" size="xlarge"></p-avatar>
      </div>
      <div *ngIf="masterContentPage.headerTitle"
        class="text-title"
        [ngClass]="{
          'text-title-alignment': !isFrontPage,
          'p-mr-2': isFrontPage}">
          <div>
            {{ masterContentPage.headerTitle }}
          </div>
      </div>
    </div>
    <div *ngIf="masterContentPage.displayHeader" class="text-body"
      [ngClass]="{'master-header-resp': isFrontPage, 'p-ml-3': !isFrontPage, 'p-mb-4': !isFrontPage}">
      <p *ngFor="let subtitle of masterContentPage.headerSubtitle">{{ subtitle }}</p>
    </div>
  </div>

  <div *ngIf="isFrontPage" class="p-d-none p-d-md-inline-flex" style="margin: 0 30px 0;">
    <p-divider layout="vertical"></p-divider>
  </div>

  <!-- Content -->
  <div
    class="
      p-p-0
      p-d-flex
      w-full"
    [ngClass]="{
      'p-jc-center': alignContent === 'center',
      'p-jc-right': alignContent === 'right',
      'p-jc-left': alignContent === 'left'
    }">
    <ng-content></ng-content>
  </div>

  <!-- Action Buttons -->
  <div *ngIf="masterContentPage.btnFoward || masterContentPage.btnFoward" class="p-p-3 p-d-flex p-jc-center">
    <div class="p-flex-column psm-master-container-btn">
      <psm-button
        *ngIf="masterContentPage.btnFoward"
        [label]="masterContentPage.btnFoward.label"
        [disabled]="!form.valid"
        (onClick)="emitForwardEvent($event)">
      </psm-button>
      <p-divider *ngIf="masterContentPage.btnBackward"></p-divider>
      <psm-button
        *ngIf="masterContentPage.btnBackward"
        [label]="masterContentPage.btnBackward.label"
        color="secondary"
        (onClick)="emitBackwardEvent($event)">
      </psm-button>
    </div>
  </div>
</div>
