import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import PT from './i18n/pt.json';
import { Router } from '@angular/router';
import { AppLang } from 'src/assets/i18n/app-lang';
import { RouteName } from 'src/shared/router-paths';

@Component({
  selector: 'app-my-pdpf',
  templateUrl: './my-pdpf.component.html',
  styleUrls: ['./my-pdpf.component.scss']
})
export class MyPdpfComponent implements OnInit {

  content: any;

  constructor(
    private translate: TranslateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;
  }

  public onBackClick() {
    this.router.navigate([RouteName.HOME_VIEW]);
  }

}
