import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';


@Injectable()
export class CongratulationConfig {

  nameFormControl = 'nameControl';
  nameMaxLength = 30;
  nameMinLength = 1;
  nameValidators = [
    Validators.required,
    Validators.maxLength(this.nameMaxLength),
    Validators.minLength(this.nameMinLength),
  ];

  lastNameFormControl = 'lastNameControl';
  lastNameMaxLength = 30;
  lastNameMinLength = 1;
  lastNameValidators = [
    Validators.required,
    Validators.maxLength(this.lastNameMaxLength),
    Validators.minLength(this.lastNameMinLength),
  ];

  constructor() {}

}
