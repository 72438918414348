import { MediaMatcher } from '@angular/cdk/layout';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { concatAll, map } from 'rxjs/operators';
import { AppLang } from 'src/assets/i18n/app-lang';
import {
  fadeAnimation,
  slideInAnimation
} from 'src/shared/animations/animations';
import { RouteName } from 'src/shared/router-paths';
import PT from './i18n/pt.json';
import { AuthService } from './services/firebase/authentication/auth.service';
import { setToolBarOptions } from './state-v2/action/component.actions';
import { loginSession } from './state-v2/action/login.actions';
import { loadProfile } from './state-v2/action/profile.actions';
import { loadServiceAccount } from './state-v2/action/service-account.actions';
import { Profile } from './state-v2/model/profile.model';
import { selectComponent } from './state-v2/selector/component.selector';
import { selectLoginSession } from './state-v2/selector/login-session.selector';
import { selectProfile } from './state-v2/selector/profile.selector';

export const ACTION = {
  name: 'APP_MENU',
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation, fadeAnimation],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {

  /**
   * Profile observable
   */
  profileState$: Observable<Profile>;

  /**
   * Auth Subscription reference
   */
  authSubscription: Subscription;

  authState: any;
  title = 'pdpf';
  showToolbar: boolean;
  mobileQuery: MediaQueryList;
  showSideMenu: boolean | false;
  content: any;
  showToolbarOptions = false;
  toolbarURLParams;
  shouldDisplaySideMenu = false;
  menu = {
    open: false,
  };

  /**
   * Subscription reference for the login state
   */
  loginStateSubscription: Subscription;

  private mobileQueryListener: () => void;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private store: Store
  ) {}

  ngOnInit() {
    this.profileState$ = this.store.select(selectProfile);

    this.loginStateSubscription = this.store.select(selectLoginSession).subscribe(loginState => {
      this.showSideMenu = loginState.logged;
      this.menu.open = loginState.logged;
    });

    this.authSubscription = this.authService
      .isLoggedIn()
      .pipe(
        map(val => this.authService.getUser()),
        concatAll()
      )
      .subscribe(res => {
        if (res && res['uid'] && res['email']) {
          this.store.dispatch(loginSession());

          this.store.dispatch(loadServiceAccount({
            email: res['email'].toString()
          }));

          this.store.dispatch(loadProfile({
            email: res['email'].toString()
          }));

          this.routeTo([RouteName.HOME_VIEW]);
        } else {
          this.routeTo([RouteName.LANDING_PAGE]);
        }
    });

    this.store.select(selectComponent).subscribe(component => {
      this.showToolbarOptions = component.toolbarOptions;
    });

    this.store.select(selectComponent).subscribe(component => {
      this.shouldDisplaySideMenu = component.sideMenu;
    });

    this.translate.setDefaultLang(AppLang.DEFAULT);
    this.translate.use(AppLang.DEFAULT);
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;

    this.mobileQuery = this.media.matchMedia('(max-width: 768px)');
    this.mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this.mobileQueryListener);

    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }

    if (this.loginStateSubscription) {
      this.loginStateSubscription.unsubscribe();
    }
  }

  ngAfterViewChecked(): void {
    this.store.select(selectComponent).subscribe(component => {
      this.toolbarURLParams = component.toolbarURL;
    });
    this.cdr.detectChanges();
  }

  /**
   * Toolbar click handler
   */
  onToolbarBackClick() {
    this.router.navigate([
      this.toolbarURLParams.url,
      this.toolbarURLParams.params,
    ]);
  }

  /**
   * It fixes the warning: 'Navigation triggered outside Angular zone' which also causes some UI crashes
   * @param route any[]
   */
  routeTo(route: any[]) {
    return this.ngZone.run(() => this.router.navigate(route)).then();
  }

  /**
   *
   * @param snav any
   */
  public toggleNavBar(snav: any) {
    if (this.mobileQuery.matches) {
      snav.toggle();
    }
  }

  /**
   * It's the home button at the top of the page
   * Redirectes back to the homepage
   */
  public onHomeItemClick() {
    this.router.navigate([RouteName.HOME_VIEW]);
    this.store.dispatch(setToolBarOptions({
      setToolBar: false
    }));
  }

  /**
   * Show side menu flag
   */
  get showSideMenuOption() {
    return !this.showToolbarOptions;
  }

  /**
   * Displays side menu flag
   */
  get displaySideMenu(): boolean {
    // if (!this.shouldDisplaySideMenu) {
    //   return this.shouldDisplaySideMenu;
    // }
    return this.menu.open && !this.mobileQuery.matches;
  }
}
