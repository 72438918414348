<h1 mat-dialog-title>{{notePayload.title}}</h1>
<div mat-dialog-content>
  <mat-form-field class="note-text-area" appearance="fill">
    <mat-label>{{ content.notePlaceHolder }}</mat-label>
    <textarea matInput [(ngModel)]="notePayload.note"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCloseClickHandler()">{{ content.btnCancel }}</button>
  <button mat-button [mat-dialog-close]="notePayload.note" cdkFocusInitial
    (click)="onAddNoteClickHandler()">
    {{ content.btnAddNote }}
  </button>
</div>
