import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { addNoteApi, addNoteApiFailure, addNoteApiSuccess, deleteNoteApi, deleteNoteApiFailure, deleteNoteApiSuccess } from '../action/notes.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotesFirebaseService } from 'src/app/services/firebase/notes/notes-firebase.service';
import PT from './i18n/pt.json';
import { TranslateService } from '@ngx-translate/core';
import { AppLang } from 'src/assets/i18n/app-lang';

@Injectable()
export class NoteEffects {

  constructor(
    private actions$: Actions,
    private snackBar: MatSnackBar,
    private notesService: NotesFirebaseService,
    private translate: TranslateService) {
  }

  addNoteApi$ = createEffect(() => this.actions$.pipe(
    ofType(addNoteApi),
    switchMap(props =>
      this.notesService.addNote(props.note).pipe(
        map(note => {
          this.translate.setTranslation(AppLang.DEFAULT, PT, true);
          this.snackBar.open(
            PT['service-notification'].success, '', {
            duration: 2 * 1000
          });

          return addNoteApiSuccess({note});
        }),
        catchError((error) => {
          this.snackBar.open(
            PT['service-notification'].failure, '', {
            duration: 2 * 1000
          })

          return of(addNoteApiFailure({error}));
        })
      ))
  ));

  deleteNoteApi$ = createEffect(() => this.actions$.pipe(
    ofType(deleteNoteApi),
    switchMap(props =>
      this.notesService.deleteNote(props.noteId).pipe(
        map(noteId => {
          this.translate.setTranslation(AppLang.DEFAULT, PT, true);
          this.snackBar.open(
            PT['service-notification'].deletionSuccess, '', {
            duration: 2 * 1000
          });

          return deleteNoteApiSuccess({noteId: noteId});
        }),
        catchError((error) => {
          this.snackBar.open(
            PT['service-notification'].failure, '', {
            duration: 2 * 1000
          })

          return of(deleteNoteApiFailure({error}));
        })
      ))
  ));

}
