import { Component, OnInit } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/common';
import { AppLang } from 'src/assets/i18n/app-lang';
import list from '@fullcalendar/list';


@Component({
  // tslint:disable-next-line: component-selector
  selector: 'psm-calendar',
  templateUrl: './psm-calendar.component.html',
  styleUrls: ['./psm-calendar.component.scss']
})
export class PsmCalendarComponent implements OnInit {

  calendarOptions: CalendarOptions;

  constructor() { }

  ngOnInit() {
    this.calendarOptions = {
      schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
      plugins: [ list ],
      initialView: 'listDay',
      locale: AppLang.DEFAULT,
      height: 'auto',
      stickyHeaderDates: true,
      nowIndicator: true,
      titleFormat: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      },
      events: [
        {
          title: 'Meeting',
          start: '2021-06-19T14:30:00',
          extendedProps: {
            status: 'done'
          }
        },
        {
          title: 'Birthday Party',
          start: '2021-06-19T07:00:00',
          backgroundColor: 'green',
          borderColor: 'green'
        },
        {
          title: 'JW Meeting',
          start: '2021-06-19T20:15:00',
          backgroundColor: 'green',
          borderColor: 'green'
        }
      ],
      headerToolbar: {
        start: 'title', // will normally be on the left. if RTL, will be on the right
        center: 'prev,next',
        end: '' // will normally be on the right. if RTL, will be on the left
      },
      footerToolbar: {
        start: '', // will normally be on the left. if RTL, will be on the right
        center: 'today',
        end: '' // will normally be on the right. if RTL, will be on the left
      },
      buttonText: {
        today: 'Hoje'
      }
    };
  }

}
