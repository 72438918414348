import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteName } from '../shared/router-paths';
import { AppComponent } from './app.component';
import { AccountActivationComponent } from './pages/account-activation/account-activation.component';
import { AgendaComponent } from './pages/agenda/agenda.component';
import { CongratulationComponent } from './pages/congratulation/congratulation.component';
import { TokenValidationDialogComponent } from './pages/dialogs/token/token-validation-dialog/token-validation-dialog.component';
import { GoalDetailComponent } from './pages/goal/goal-detail/goal-detail.component';
import { GoalFormComponent } from './pages/goal/goal-form/goal-form.component';
import { GoalSummaryComponent } from './pages/goal/goal-summary/goal-summary.component';
import { GoalComponent } from './pages/goal/goal.component';
import { HomeComponent } from './pages/home/home.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { MyPdpfComponent } from './pages/my-pdpf/my-pdpf.component';
import { ObjectiveFormComponent } from './pages/objective/objective-form/objective-form.component';
import { ObjectiveSummaryComponent } from './pages/objective/objective-summary/objective-summary.component';
import { ObjectiveComponent } from './pages/objective/objective.component';
import { PillarConfigurationComponent } from './pages/pillar-configuration/pillar-configuration.component';
import { PillarCreationComponent } from './pages/pillar-creation/pillar-creation.component';
import { PillarEssenceComponent } from './pages/pillar-essence/pillar-essence.component';
import { SummaryComponent } from './pages/summary/summary.component';
import { UserDobGenderComponent } from './pages/user-dob-gender/user-dob-gender.component';
import { UserInfoComponent } from './pages/user-info/user-info.component';
import { UserPhoneComponent } from './pages/user-phone/user-phone.component';
import { AgendaActivityDetailComponent } from './pages/agenda/agenda-activity-detail/agenda-activity-detail.component';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
  },
  {
    path: RouteName.LANDING_PAGE,
    component: LandingPageComponent,
    data: { animation: 'LANDING_PAGE' },
  },
  {
    path: RouteName.HOME_VIEW,
    component: HomeComponent,
    data: { animation: 'HOME_VIEW' },
  },
  {
    path: RouteName.ACTIVATE_ACCOUNT,
    component: AccountActivationComponent,
    data: { animation: 'ACTIVATE_ACCOUNT' },
  },
  {
    path: RouteName.TOKEN_VALIDATION_ERROR,
    component: TokenValidationDialogComponent,
  },
  {
    path: RouteName.SUMMARY_PAGE,
    component: SummaryComponent,
  },
  {
    path: RouteName.CONGRATULATION_PAGE,
    component: CongratulationComponent,
    data: { animation: 'CONGRATULATION_PAGE' },
  },
  {
    path: RouteName.USER_INFO,
    component: UserInfoComponent,
    data: { animation: 'USER_INFO' },
  },
  {
    path: RouteName.PILLAR_DEFINITION,
    component: PillarCreationComponent,
    data: { animation: 'PILLAR_DEFINITION' },
  },
  {
    path: RouteName.MENU_CONFIG_MY_PDPF,
    component: MyPdpfComponent,
  },
  {
    path: RouteName.PILLAR_CONFIG,
    component: PillarConfigurationComponent,
    data: { animation: 'PILLAR_CONFIG' },
  },
  {
    path: RouteName.GOAL,
    component: GoalComponent,
    data: { animation: 'PILLAR_GOALS' },
  },
  {
    path: RouteName.GOAL_FORM,
    component: GoalFormComponent,
    data: { animation: 'PILLAR_GOALS_CRUD' },
  },
  {
    path: RouteName.OBJECTIVE,
    component: ObjectiveComponent,
    data: { animation: 'GOAL_OBJECTIVE' },
  },
  {
    path: RouteName.OBJECTIVE_FORM,
    component: ObjectiveFormComponent,
    data: { animation: 'GOAL_OBJECTIVE_CRUD' },
  },
  {
    path: RouteName.PILLAR_ESSENCE_VIEW,
    component: PillarEssenceComponent,
    data: { animation: 'PILLAR_ESSENCE_VIEW' },
  },
  {
    path: RouteName.USER_DOB_GENDER_FORM,
    component: UserDobGenderComponent,
    data: { animation: 'USER_DOB_GENDER_FORM' },
  },
  {
    path: RouteName.USER_PHONE_FORM,
    component: UserPhoneComponent,
    data: { animation: 'USER_PHONE_FORM' },
  },
  {
    path: RouteName.AGENDA,
    component: AgendaComponent,
  },
  {
    path: RouteName.GOAL_DETAIL,
    component: GoalDetailComponent,
  },
  {
    path: RouteName.OBJECTIVE_SUMMARY,
    component: ObjectiveSummaryComponent,
  },
  {
    path: RouteName.GOAL_SUMMARY,
    component: GoalSummaryComponent,
  },
  {
    path: RouteName.AGENDA_ACTIVITY_DETAIL,
    component: AgendaActivityDetailComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
