<div class="p-flex-column p-jc-center">
  <div class="text-header">
    {{ 'dialogs.login.login-unsuccessful.title' | translate }}
  </div>
  <div class="text-subtitle p-text-left">
    <p>{{ 'dialogs.login.login-unsuccessful.msg1' | translate }}</p>
  </div>
  <div class="p-grid p-jc-center">
    <div class="p-col-6">
      <psm-button mat-dialog-close color="danger" [label]="'dialogs.login.login-invalid-form.btn-close' | translate"></psm-button>
    </div>
  </div>
</div>
