import { createAction, props } from '@ngrx/store';
import { Note } from '../model/note.model';

export const openAddNotesDialog = createAction(
  '[Add Note Dialog] Open Notes Dialog',
  props<{noteTitle: string}>()
);

export const noteAddedSuccess = createAction(
  '[Add Note Dialog] Notes Added Successfully'
);

export const noteReturnedSuccess = createAction(
  '[Add Note Dialog] Notes Returned Successfully',
  props<{noteTitle: string, note: string}>()
);

export const noteDialogClosure = createAction(
  '[Add Note Dialog] Notes Dialog Closed'
);

export const addNoteApi = createAction(
  '[Add Note API] Add Note',
  props<{note: Note}>()
);

export const addNoteApiSuccess = createAction(
  '[Add Note API] Add Note Success',
  props<{note: Note}>()
);

export const addNoteApiFailure = createAction(
  '[Add Note API] Add Note Failed',
  props<{error: any}>()
);

export const deleteNoteApi = createAction(
  '[Delete Note API] Delete Note API',
  props<{noteId: string}>()
);

export const deleteNoteApiSuccess = createAction(
  '[Delete Note API] Delete Note API Success',
  props<{noteId: string}>()
);

export const deleteNoteApiFailure = createAction(
  '[Delete Note API] Delete Note API Failure',
  props<{error: any}>()
);
