import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { EMPTY, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { GoalApiService } from 'src/app/services/firebase/goal/goal-api.service';
import { PillarApiService } from 'src/app/services/firebase/pillar/pillar-api.service';
import { openGoalsSummaryPage } from 'src/app/state-v2/action/goal.actions';
import { Pillar } from 'src/app/state-v2/model/Pillar.model';
import { ObjectiveModel } from 'src/app/state-v2/model/objective.model';
import { ServiceAccount } from 'src/app/state-v2/model/service-account.model';
import { GoalState } from 'src/app/state-v2/reducer/goal.reducer';
import { selectServiceAccount } from 'src/app/state-v2/selector/service-accont.selector';
import { AppLang } from 'src/assets/i18n/app-lang';
import PT from './i18n/pt.json';

@Component({
  selector: 'app-goal-summary',
  templateUrl: './goal-summary.component.html',
  styleUrls: ['./goal-summary.component.scss']
})
export class GoalSummaryComponent implements OnInit {

  /**
   * The page's copy content
   */
  content: any;

  /**
   * The pillar stream
   *
   * @type {Observable<Pillar[]>}
   * @memberof SummaryComponent
   */
  pillars: Observable<Pillar[]> = EMPTY;

  /**
   * The current pillar selection
   *
   * @type {Pillar}
   * @memberof SummaryComponent
   */
  pillar: Pillar;

  /**
   * The goals stream
   *
   * @type {Observable<ObjectiveModel[]>}
   * @memberof SummaryComponent
   */
  goals: Observable<GoalState[]> = EMPTY;

  /**
   * Service Account State
   */
  serviceAccount: ServiceAccount;

  constructor(
    private translate: TranslateService,
    private pillarApi: PillarApiService,
    private goalsApis: GoalApiService,
    private store: Store,
    private spinner: NgxSpinnerService
    ) { }

  ngOnInit(): void {
    this.store.select(selectServiceAccount).subscribe(serviceAccount => this.serviceAccount = serviceAccount);

    this.spinner.show();
    this.store.dispatch(openGoalsSummaryPage());
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;

    this.pillars = this.pillarApi.getPillarsStream(this.serviceAccount?.profileRef ?? '');
    this.pillars.pipe(first()).subscribe(
      pillars => pillars.forEach(pillar => {
        if (pillar?.order === 1) {
          this.pillar = pillar;
          this.goals = this.goalsApis.getGoals(pillar?.pillarId, this.serviceAccount?.profileRef ?? '');
          this.spinner.hide();
        }
      })
    );
  }

  /**
   * It loads the Objectives and Goals for the specified pillar
   */
  onCardMenuClick(pillar) {
    if (pillar) {
      this.pillar = pillar;
      this.goals = this.goalsApis.getGoals(pillar?.pillarId, this.serviceAccount?.profileRef ?? '');

    }
  }

  /**
   * Utility to add background color to the selected card
   * @param pillar
   * @returns Boolean
   */
  isCardSelected(pillar) {
    return pillar.pillarId === this.pillar.pillarId;
  }

}
