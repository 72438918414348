export const ACTION = {
  PARENT_ACTION: {
    HOME: {
      name: 'HOME',
      active: false
    },
    PILLAR_DEFINITION: {
      name: 'PILLAR_DEFINITION',
      active: false
    },
    SELF: {
      name: 'PILLAR_CONFIGURATION',
      active: true
    }
  }
};
