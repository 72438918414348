import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { PdpfPartyService } from 'src/app/services/common/pdpf-party/pdpf-party.service';
import { AppLang } from 'src/assets/i18n/app-lang';
import { RouteName } from 'src/shared/router-paths';
import { MasterButton } from '../master-content/master-content.class.button';
import { MasterContentPage } from '../master-content/master-content.class.page';
import { UserDobGenderConfig } from './user-dob-gender.config';

import { Store } from '@ngrx/store';
import MOMENT from 'moment';
import { setProfile } from 'src/app/state-v2/action/profile.actions';
import { Profile } from 'src/app/state-v2/model/profile.model';
import { ServiceAccount } from 'src/app/state-v2/model/service-account.model';
import { selectProfile } from 'src/app/state-v2/selector/profile.selector';
import { selectServiceAccount } from 'src/app/state-v2/selector/service-accont.selector';
import PT from './i18n/pt.json';



@Component({
  selector: 'app-user-dob-gender',
  templateUrl: './user-dob-gender.component.html',
  styleUrls: ['./user-dob-gender.component.scss']
})
export class UserDobGenderComponent implements OnInit {

  config: UserDobGenderConfig;
  page: MasterContentPage;
  content: any;
  form = new FormGroup({});

  /**
   * The service account representation
   */
  serviceAccount: ServiceAccount

  /**
   * The profile state
   */
  profile: Profile;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private pdpfPartyService: PdpfPartyService,
    private userDobGenderConfig: UserDobGenderConfig,
    private spinner: NgxSpinnerService,
    private store: Store
  ) { }

  ngOnInit(): void {
    MOMENT.locale(AppLang.DEFAULT);
    this.config = this.userDobGenderConfig;
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.copy;

    this.store.select(selectProfile).subscribe(profile => this.profile = JSON.parse(JSON.stringify(profile)));
    this.store.select(selectServiceAccount).subscribe(serviceAccount => this.serviceAccount = JSON.parse(JSON.stringify(serviceAccount)));

    this.page = new MasterContentPage(
      false,
      true,
      'assets/images/imgs/cradle.svg',
      '',
      [this.content.title.txt1 + this.profile.personalInfo?.name + this.content.title.txt2],
      new MasterButton(this.content.btnNext),
      new MasterButton(this.content.btnBack)
    );
  }

  /**
   * It prefill the gender value from store
   * @param value string
   */
  get genderValue() {
    const value = this.profile.personalInfo?.gender
      ? this.profile.personalInfo?.gender : '';
    const selectedItem =
      this.config.genderList.filter(gender => gender.viewValue === value);

    return selectedItem.length > 0
      ? selectedItem[0].value : '';
  }

  /**
   * It updates the store with the new dropdown selection
   * @param event MatSelectChange
   */
  genderSelectionChange(event) {
    const newList = this.config.genderList.filter(gender => gender.value === event.value);
    this.profile.personalInfo.gender = newList.length > 0
      ? newList[0].viewValue : this.profile.personalInfo.gender;
  }

  /**
   * It updates the store with current values and update firestore
   *
   */
  btnForwardEvent(event) {
    this.spinner.show();
    this.profile.personalInfo.dob = this.pdpfPartyService.getFormattedDate(
      new Date(this.form.get(this.config.dobFormControl).value)
    );

    this.store.dispatch(setProfile({
      profile: {
        ...this.profile,
        idField: this.serviceAccount?.profileRef ?? ''
      }
    }));

    this.router.navigate([RouteName.USER_PHONE_FORM]);
    this.spinner.hide();
  }

  /**
   * Routes back to the congratulation page
   */
   btnBackwardEvent(event) {
    this.router.navigate([RouteName.CONGRATULATION_PAGE]);
  }

}
