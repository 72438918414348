<mat-dialog-content>
  <mat-card>
    <mat-card-header id="dialog-container">
      <mat-card-title>{{ content.title }}</mat-card-title>
      <mat-card-subtitle>{{ content.subtitle }}</mat-card-subtitle>
    </mat-card-header>
    <div class="card-container">
      <p>
        <span class="opt-content">
          {{ content.description }}
        </span>
        <br>
        <br>
        <br>
        <span class="opt-content">
          {{ content.exampleTxt }}
        </span>
      </p>

      <div class="box-list">
        <div class="box">
          <p>
            <span class="opt-content-header">
              {{ content.example.goalTitle }}
            </span>
            <br/>
            <span class="opt-content">
              {{ content.example.goalTitleDesc }}
            </span>
          </p>
        </div>
        <div class="box">
          <p>
            <span class="opt-content-header">
              {{ content.example.goalDescription }}
            </span>
            <br/>
            <span class="opt-content">
              {{ content.example.goalDescriptionTxt }}
            </span>
          </p>
        </div>
        <div class="box">
          <p>
            <span class="opt-content-header">
              {{ content.example.goalReason }}
            </span>
            <br/>
            <span class="opt-content">
              {{ content.example.goalReasonDesc }}
            </span>
          </p>
        </div>
        <div class="box">
          <p>
            <span class="opt-content-header">
              {{ content.example.goalReality }}
            </span>
            <br/>
            <span class="opt-content">
              {{ content.example.goalRealityDesc }}
            </span>
          </p>
        </div>
      </div>

      <br>
      <br>
      <p>
        <span class="opt-content">
          {{ content.summary }}
        </span>
      </p>
    </div>
    <mat-card-actions>
      <psm-button [label]="content.btnClose" color="warning" (onClick)="onCloseDialog()"></psm-button>
    </mat-card-actions>
  </mat-card>
</mat-dialog-content>
