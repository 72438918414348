import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PsmGoalViewComponent } from '../psm-goal-view/psm-goal-view.component';
import PT from './i18n/pt.json';
import { TranslateService } from '@ngx-translate/core';
import { AppLang } from 'src/assets/i18n/app-lang';
import { Store } from '@ngrx/store';
import { noteDialogClosure, noteReturnedSuccess, openAddNotesDialog } from 'src/app/state-v2/action/notes.actions';

/**
 * The note payload is meant to be generic
 */
export interface NotePayload {
  title: string;
  note?: string;
}

@Component({
  selector: 'psm-notes',
  templateUrl: './psm-notes.component.html',
  styleUrls: ['./psm-notes.component.scss']
})
export class PsmNotesComponent implements OnInit {

  /**
   * It represents the i18n content of the page
   *
   * @memberof PsmNotesComponent
   */
  content: any;

  constructor(
    public dialogRef: MatDialogRef<PsmGoalViewComponent>,
    @Inject(MAT_DIALOG_DATA) public notePayload: NotePayload,
    private translate: TranslateService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.store.dispatch(openAddNotesDialog({
      noteTitle: this.notePayload.title
    }));
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;
  }

  /**
   * It handles the dialog closure
   */
  onCloseClickHandler() {
    this.store.dispatch(noteDialogClosure());
    this.dialogRef.close();
  }

  /**
   * It dispatches the returned note action
   */
  onAddNoteClickHandler() {
    this.store.dispatch(noteReturnedSuccess({
      noteTitle: this.notePayload.title,
      note: this.notePayload.note
    }));
  }

}
