import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

@Injectable()
export class UserPhoneConfig {

  phoneFormControl = 'phoneControl';
  phoneMaxLength = 70;
  phoneMinLength = 1;
  phoneValidators = [
    Validators.required,
    Validators.maxLength(this.phoneMaxLength),
    Validators.minLength(this.phoneMinLength),
  ];

  constructor() {}
}
