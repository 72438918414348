<h2 mat-dialog-title>{{ content.title }}</h2>
<mat-dialog-content class="mat-typography">
  <div class="text-body">
    <p>{{ content.text }}</p>
    <p>{{ content.text1 }}</p>
    <p>{{ content.text2 }}</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ content.btnClose }}</button>
</mat-dialog-actions>