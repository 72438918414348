<mat-dialog-content>
  <mat-card>
    <mat-card-header id="dialog-container">
      <mat-card-title>{{ content.title }}</mat-card-title>
      <mat-card-subtitle>{{ content.subtitle }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="card-container">
        <p>
          <span class="opt-content">
            {{ content.description }}
          </span>
          <br>
          <br>
        </p>
      </div>
      <psm-button [label]="content.btnClose" color="warning" (onClick)="onCloseDialog()"></psm-button>
    </mat-card-content>
  </mat-card>
</mat-dialog-content>
