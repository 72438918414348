<app-master-content [masterContentPage]="page" [form]="form" (btnBackwardEvent)="onCloseBtnAction($event)" (btnForwardEvent)="onSubmitForm($event)">
  <div class="p-flex-column w-full p-mt-4">
    <mat-card>
      <mat-card-content>
        <psm-form-field
          [title]="content.avatarTitle"
          [hint]="content.avatarHint">
          <div class="avatar-container">
            <div>
              <label  el class="hoverable" for="fileInput">
                <img [src]="avatarUrl ? avatarUrl : 'https://www.w3schools.com/howto/img_avatar.png'">
                <div class="hover-text">{{ content.avatarSelectLbl }}</div>
                <div class="background"></div>
              </label>
              <br/>
              <input id="fileInput" type='file' (change)="onSelectAvatar($event)">
            </div>
          </div>
        </psm-form-field>
        <psm-text-input
          [parentFormGroup]="form"
          [controlName]="config.nameFormControl"
          [value]="profile.personalInfo?.name"
          [maxLength]="config.nameMaxLength"
          [minLength]="config.nameMinLength"
          [label]="content.nameLbl"
          [validators]="config.nameValidators">
        </psm-text-input>
        <psm-text-input
          [parentFormGroup]="form"
          [controlName]="config.lastNameFormControl"
          [value]="profile.personalInfo?.lastName"
          [maxLength]="config.lastNameMaxLength"
          [minLength]="config.lastNameMinLength"
          [label]="content.lastNameLbl"
          [validators]="config.lastNameValidators">
        </psm-text-input>
        <psm-text-input
          [parentFormGroup]="form"
          [controlName]="config.phoneFormControl"
          [value]="getPhoneValue(profile.personalInfo?.phoneNumber)"
          [maxLength]="config.phoneMaxLength"
          [minLength]="config.phoneMinLength"
          [label]="content.phoneLbl"
          [mask]="textMaskConfig.phoneMaskConfig"
          [inputType]="textMaskConfig.ptPhoneMask.inputType"
          allowOnlyNumbers="true"
          [validators]="config.phoneValidators">
        </psm-text-input>
        <psm-text-input
          [parentFormGroup]="form"
          [controlName]="config.emailFormControl"
          [value]="profile?.email"
          [maxLength]="config.emailMaxLength"
          [minLength]="config.emailMinLength"
          [label]="content.emailLbl"
          [validators]="config.emailValidators"
          disabled="true">
        </psm-text-input>
        <psm-text-input
          [parentFormGroup]="form"
          [controlName]="config.dobFormControl"
          [value]="profile.personalInfo?.dob"
          [label]="content.dobLbl"
          [isDatePicker]=true
          [name]="config.dobFormControl"
          [validators]="config.dobValidators">
        </psm-text-input>
        <psm-dropdown
          [label]="content.genderLbl"
          [parentFormGroup]="form"
          [value]="genderValue"
          [optionList]="config.genderList"
          (selectionChange)="genderSelectionChange($event)">
        </psm-dropdown>
      </mat-card-content>
    </mat-card>
  </div>
</app-master-content>
