<div class="m-container">
  <psm-home-card
  [title]="pillar?.title"
  [subTitle]="content.title1 + ' > ' + goal?.header"
  [pillarId]="pillar?.pillarId">
    <div class="p-flex-column w-full">
      <mat-vertical-stepper #stepper [linear]="true" class="p-shadow-2">
        <mat-step [stepControl]="formStep1">
          <form [formGroup]="formStep1">
            <ng-template matStepLabel>{{content.step1.label}}</ng-template>
            <mat-card class="card-margin">
              <mat-card-content>
                <psm-form-field
                  [title]="content.step1.lblRealityList"
                  [hint]="content.step1.lblRealityListHint">
                  <section class="section-margin">
                    <span>
                      <ul>
                        <li *ngFor="let row of resourceAvailable">
                          <psm-checkbox
                            [parentFormGroup]="formStep1"
                            controlName="{{row.resource + 'ResAvailableControl'}}"
                            [checked]="row.selected"
                            [label]="row.resource"
                            disabled=true>
                          </psm-checkbox>
                        </li>
                      </ul>
                    </span>
                  </section>
                </psm-form-field>
              </mat-card-content>
            </mat-card>
            <mat-card class="card-margin">
              <mat-card-content>
                <psm-form-field
                  [title]="content.step1.lblRealityToUse"
                  [hint]="content.step1.lblRealityToUseHint">
                  <psm-text-area
                    [parentFormGroup]="formStep1"
                    [controlName]="config.realityDescFormControl"
                    [value]="objective?.realityDescription"
                    [maxLength]="config.descMaxLength"
                    [minLength]="config.descMinLength"
                    [validators]="config.descValidators"
                    rows="8"
                    (inputStatus)="onChangeEventHandler($event, 'realityDescription')">
                  </psm-text-area>
                </psm-form-field>
              </mat-card-content>
            </mat-card>
            <psm-stepper-button [label]="content.btnNext"></psm-stepper-button>
          </form>
        </mat-step>
        <mat-step [stepControl]="formStep2">
          <form [formGroup]="formStep2">
            <ng-template matStepLabel>{{content.step2.label}}</ng-template>
            <mat-card class="card-margin">
              <mat-card-content>
                <psm-form-field
                  [title]="content.step2.lblRequiredList"
                  [hint]="content.step2.lblRequiredListHint">
                  <section class="section-margin">
                    <span>
                      <ul>
                        <li *ngFor="let row of resourceRequired">
                          <psm-checkbox
                            [parentFormGroup]="formStep2"
                            controlName="{{row.resource + 'ResRequiredControl'}}"
                            [checked]="row.selected"
                            [label]="row.resource"
                            disabled=true>
                          </psm-checkbox>
                        </li>
                      </ul>
                    </span>
                  </section>
                </psm-form-field>
              </mat-card-content>
            </mat-card>
            <mat-card class="card-margin">
              <mat-card-content>
                <psm-form-field
                  [title]="content.step2.lblRequiredToUse"
                  [hint]="content.step2.lblRequiredToUseHint">
                  <psm-text-area
                    [parentFormGroup]="formStep2"
                    [controlName]="config.requiredResDescriptionFormControl"
                    [value]="objective?.requiredResDescription"
                    [maxLength]="config.descMaxLength"
                    [minLength]="config.descMinLength"
                    [validators]="config.descValidators"
                    rows="8"
                    (inputStatus)="onChangeEventHandler($event, 'requiredResDescription')">
                  </psm-text-area>
                </psm-form-field>
              </mat-card-content>
            </mat-card>
            <psm-stepper-button [label]="content.btnNext"></psm-stepper-button>
          </form>
        </mat-step>
        <mat-step [stepControl]="formStep3">
          <form [formGroup]="formStep3">
            <ng-template matStepLabel>{{content.step3.label}}</ng-template>
            <mat-card class="card-margin">
              <mat-card-content>
                <psm-form-field
                  [title]="content.step3.lblObjectiveDesc"
                  [hint]="content.step3.lblObjectiveDescHint">
                  <psm-text-area
                    [parentFormGroup]="formStep3"
                    [controlName]="config.descFormControl"
                    [value]="objective?.description"
                    [maxLength]="config.descMaxLength"
                    [minLength]="config.descMinLength"
                    [validators]="config.descValidators"
                    rows="8"
                    (inputStatus)="onChangeEventHandler($event, 'description')">
                  </psm-text-area>
                </psm-form-field>
              </mat-card-content>
            </mat-card>
            <mat-card class="card-margin">
              <mat-card-content>
                <psm-form-field
                  [title]="content.step3.lblObjectiveTimeline"
                  [hint]="content.step3.lblObjectiveTimelineHint">
                  <psm-text-input
                    [parentFormGroup]="formStep3"
                    [controlName]="config.startDtFormControl"
                    [value]="objective?.startDate"
                    [label]="content.step3.lblObjectiveTimelineStart"
                    [isDatePicker]=true
                    [name]="config.startDtFormControl"
                    [validators]="config.startDtValidators"
                    (inputStatus)="onChangeEventHandler($event, 'startDate')"
                    disabled=true>
                  </psm-text-input>
                  <psm-text-input
                    [parentFormGroup]="formStep3"
                    [controlName]="config.endDtFormControl"
                    [value]="objective?.endDate"
                    [label]="content.step3.lblObjectiveTimelineEnd"
                    [isDatePicker]=true
                    [name]="config.endDtFormControl"
                    [validators]="config.endDtValidators"
                    (inputStatus)="onChangeEventHandler($event, 'endDate')"
                    disabled=true>
                  </psm-text-input>
                </psm-form-field>
              </mat-card-content>
            </mat-card>
            <div class="p-d-flex p-flex-column">
              <div class="p-mb-2">
                <psm-button
                  [label]="content.btnSave"
                  (onClick)="onSubmitForm()">
                </psm-button>
              </div>
              <div class="p-mb-2">
                <psm-button
                  [label]="content.btnCancel"
                  color="danger"
                  (onClick)="backFowardToPillarGoal()">
                </psm-button>
              </div>
            </div>
          </form>
        </mat-step>
      </mat-vertical-stepper>
    </div>
  </psm-home-card>
</div>
