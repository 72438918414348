<div class="example-container mat-elevation-z8">
  <mat-table [dataSource]="dataSource" *ngFor="let table of tables">
    <ng-container matColumnDef="position">
      <mat-header-cell *matHeaderCellDef> Position </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.position}} </mat-cell>
      <mat-footer-cell *matFooterCellDef> Position Footer </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
      <mat-footer-cell *matFooterCellDef> Name Footer </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="weight">
      <mat-header-cell *matHeaderCellDef> Weight </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.weight}} </mat-cell>
      <mat-footer-cell *matFooterCellDef> Weight Footer </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="symbol">
      <mat-header-cell *matHeaderCellDef> Symbol </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.symbol}} </mat-cell>
      <mat-footer-cell *matFooterCellDef> Symbol Footer </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="filler">
      <mat-header-cell *matHeaderCellDef> Filler header cell </mat-header-cell>
      <mat-cell *matCellDef="let element"> Filler data cell </mat-cell>
      <mat-footer-cell *matFooterCellDef> Filler footer cell </mat-footer-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
    <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

    <mat-footer-row *matFooterRowDef="displayedColumns;"></mat-footer-row>
    <mat-footer-row *matFooterRowDef="displayedColumns;"></mat-footer-row>
  </mat-table>
</div>
