import { ObjectiveApiServiceV2 } from 'src/app/services/firebase/objective/objective-api.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { addObjectiveDocument } from '../action/objective.actions';
import { AppLang } from 'src/assets/i18n/app-lang';
import { TranslateService } from '@ngx-translate/core';
import { addObjectiveDocumentSuccess } from '../action/objective.actions';
import { addObjectiveDocumentFailure } from '../action/objective.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import PT from './i18n/pt.json';
import { of } from 'rxjs';

@Injectable()
export class ObjectiveEffects {

  constructor(
    private actions$: Actions,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private objectiveApiService: ObjectiveApiServiceV2) { }

  addObjectiveDocument$ = createEffect(() => this.actions$.pipe(
    ofType(addObjectiveDocument),
    switchMap(props =>
      this.objectiveApiService.addDocument(props.objectiveDoc).pipe(
        map(docId => {
          this.translate.setTranslation(AppLang.DEFAULT, PT, true);
          this.snackBar.open(
            PT['service-notification'].success, '', {
            duration: 2 * 1000
          });

          return addObjectiveDocumentSuccess();
        }),
        catchError((error) => {
          this.snackBar.open(
            PT['service-notification'].failure, '', {
            duration: 2 * 1000
          })
          return of(addObjectiveDocumentFailure({error: error}));
        })
      ))
  ));

}
