<div class="p-d-flex p-jc-center" class="text-input-psm">
  <form [formGroup]="parentFormGroup">
    <mat-form-field
      appearance="outline"
      [disabled]="disabled"
      [ngClass]="{ fieldEmpty: !value }"
    >
      <mat-label *ngIf="label">{{ label }}</mat-label>
      <textarea
        matInput
        [name]="name"
        [readonly]="readonly"
        [formControlName]="controlName"
        [placeholder]="placeholder"
        [value]="value"
        [maxLength]="maxLength"
        [minLength]="minLength"
        [required]="required"
        [rows]="rows"
        (focus)="inputFocus.emit($event)"
        (blur)="inputBlur.emit($event)"
        (ngModelChange)="onNgModelChange($event)"
      >
      </textarea>
    </mat-form-field>
  </form>
</div>
