import { ActionReducerMap, createReducer, on } from "@ngrx/store";
import { ProfileState, profileReducer } from './profile.reducer';
import { ServiceAccountState, serviceAccountReducer } from "./service-account.reducer";
import { LoginState, loginReducer } from "./login.reducer";
import { PillarState, pillarReducer } from "./pillar.reducer";
import { ComponentState, componentReducer } from "./component.reducer";

export interface AppState {
  profile: ProfileState;
  serviceAccount: ServiceAccountState;
  login: LoginState;
  pillar: PillarState;
  component: ComponentState;
}

export const rootReducer: ActionReducerMap<AppState> = {
  profile: profileReducer,
  serviceAccount: serviceAccountReducer,
  login: loginReducer,
  pillar: pillarReducer,
  component: componentReducer
};
