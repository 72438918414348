import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FireFunctionsService {

  url = 'https://us-central1-pdpf-app-dev.cloudfunctions.net/app';

  constructor(
    private http: HttpClient
  ) {}

  delete(uid: string): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 
        'Authorization': 'x_pdpf_token_' + uid
      })
    };

    return this.http.delete(this.url, httpOptions).toPromise();
  }

}
