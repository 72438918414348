<psm-dropdown
  [parentFormGroup]="form"
  [value]="selectedWeekDay"
  [optionList]="weekOptions"
  (selectionChange)="onWeekDaySelectionChange($event)">
</psm-dropdown>
<p-table
  *ngIf="previewMode"
  [value]="agenda"
  [scrollable]="true"
  [(selection)]="selectedItem"
  (onRowSelect)="onRowSelect($event)"
  selectionMode="single"
  styleClass="p-datatable-striped"
>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 3rem;"></th>
      <th>{{ content.table.colTime }}</th>
      <th>{{ content.table.colTask }}</th>
      <th>{{ content.table.colPillar }}</th>
      <th>{{ content.table.colExpiryDate }}</th>
      <th>{{ content.table.colStatus }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-agenda>
    <tr [pSelectableRow]="agenda">
      <td style="width: 3rem;">
        <button
          style="margin-right: 5px; margin-top: 5px;"
          type="button"
          pButton
          pRipple
          class="p-button-rounded p-button-text p-button-info"
          icon="pi pi-eye"
          (click)="itemClick.emit(agenda)"
        ></button>
      </td>
      <td>
        {{ agenda.startHour }}:{{ agenda.startMin }} - {{ agenda.endHour }}:{{ agenda.endMin }}
      </td>
      <td>{{ agenda.content }}</td>
      <td>
        <mat-chip-list *ngIf="agenda.pillarId">
          <mat-chip
            [ngClass]="{
              'chip-bg-color-blue': agenda?.pillarId === 'self_development',
              'chip-bg-color-green': agenda?.pillarId === 'work',
              'chip-bg-color-yellow': agenda?.pillarId === 'family',
              'chip-bg-color-red': agenda?.pillarId === 'health'
            }">
            {{ content.pillars[agenda.pillarId] }}
          </mat-chip>
        </mat-chip-list>
      </td>
      <td> {{ agenda?.endDate.toMillis() | date: 'dd/MM/yyyy' }} </td>
      <td> {{ agenda.completed ? 'Completado!' : 'Ativo' }} </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-agenda>
    Expansion
  </ng-template>
</p-table>
