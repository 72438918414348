import { Component, OnInit, Input, OnDestroy, HostListener, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, ValidatorFn } from '@angular/forms';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'psm-checkbox',
  templateUrl: './psm-checkbox.component.html',
  styleUrls: ['./psm-checkbox.component.scss']
})
export class PsmCheckboxComponent implements OnInit, OnDestroy {

  /**
   * Parent form group
   */
  @Input() parentFormGroup: FormGroup;

  /**
   * Flag indicates whether the component is required
   */
  @Input() required = false;

  /**
   * Flag indicates whether the checkbox is flagged
   */
  @Input() checked = false;

  /**
   * Flag indicates whether the checkbox is disabled
   */
  @Input() disabled = false;

  /**
   * The form control name
   */
  @Input() controlName: string;

  /**
   * Component validators
   */
  @Input() validators: ValidatorFn[];

  /**
   * Checkbox label
   */
  @Input() label: string;

  /**
   * If yes it prevents the check to be selected by the label
   */
  @Input() boxSelectionOnly: false;

  /**
   * Value status emitter
   */
  @Output() status: EventEmitter<boolean> = new EventEmitter();

  /**
   * Form control for the checkbox
   */
  formControl: FormControl;

  constructor() { }

  /**
   * Clicks to toggle checkbox
   * @param e Event
   */
  @HostListener('click', ['$event'])
  onClick(e: Event) {
    this.checked = !this.checked;
  }

  ngOnInit(): void {
    this.formControl = new FormControl({
      value: this.checked ? true : '',
      disabled: this.disabled
    },
    this.validators);
    this.parentFormGroup.addControl(this.controlName, this.formControl);
  }

  /**
   * Removes the formControl from the form group
   *
   */
  ngOnDestroy(): void {
    if (this.parentFormGroup && this.parentFormGroup.contains(this.controlName)) {
      this.parentFormGroup.removeControl(this.controlName);
    }
  }

  /**
   * It prevents the box to be selected out of the component
   *
   */
  handleBoxSelection(event) {
    if (this.boxSelectionOnly && event.target.className === 'checkbox-label') {
      event.preventDefault();
    }
  }

}
