import { Injectable } from '@angular/core';
import { OptionModel } from '../../components/psm-dropdown/psm-dropdown.component';
import { Validators } from '@angular/forms';


@Injectable()
export class UserInfoConfig {

  nameFormControl = 'nameControl';
  nameMaxLength = 30;
  nameMinLength = 1;
  nameValidators = [
    Validators.required,
    Validators.maxLength(this.nameMaxLength),
    Validators.minLength(this.nameMinLength),
  ];

  lastNameFormControl = 'lastNameControl';
  lastNameMaxLength = 30;
  lastNameMinLength = 1;
  lastNameValidators = [
    Validators.required,
    Validators.maxLength(this.lastNameMaxLength),
    Validators.minLength(this.lastNameMinLength),
  ];

  emailFormControl = 'emailControl';
  emailMaxLength = 70;
  emailMinLength = 1;
  emailValidators = [
    Validators.required,
    Validators.maxLength(this.emailMaxLength),
    Validators.minLength(this.emailMinLength),
    Validators.email,
    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
  ];

  phoneFormControl = 'phoneControl';
  phoneMaxLength = 70;
  phoneMinLength = 1;
  phoneValidators = [
    Validators.required,
    Validators.maxLength(this.phoneMaxLength),
    Validators.minLength(this.phoneMinLength),
  ];

  dobFormControl = 'dobControl';
  dobValidators = [
    Validators.required
  ];

  genderList: Array<OptionModel> = [
    { value: 'man', viewValue: 'Masculino' },
    { value: 'woman', viewValue: 'Feminino' },
    { value: 'other', viewValue: 'Outro' }
  ];

  constructor() {
  }

}
