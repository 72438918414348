<mat-dialog-content>
  <mat-card>
    <mat-card-header id="dialog-container">
      <mat-card-title>{{ goal.header ? goal.header : content.title }}</mat-card-title>
    </mat-card-header>
    <div *ngIf="showGoals; else NoOptionToDisplay" class="card-container">
      <p>
        <span class="opt-content-header">
          {{ content.title }}
        </span>
        <br>
        <span class="opt-content">
          {{ content.text1 }}
        </span>
        <br>
        <br>
      </p>
      <div class="box-list">
        <div class="box" *ngFor="let opt of goal.goalOptions; let i = index">
          <p>
            <span class="opt-content-header">
              {{ '#' + (i + 1) }}
            </span>
            <br>
            <span class="opt-content">
              {{ opt }}
            </span>
          </p>
        </div>
      </div>
    </div>

    <ng-template #NoOptionToDisplay>
      <div class="card-container">
        <p>
          <span class="opt-content">
            {{ content.text2 }}
          </span>
          <br>
          <br>
          <span class="opt-content-header">
            {{ content.text3Title }}
          </span>
          <br>
          <span class="opt-content">
            {{ content.text3 }}
          </span>
        </p>
      </div>
    </ng-template>
    <mat-card-actions>
      <div class="card-container">
        <div class="card-small-default">
          <button mat-raised-button (click)="onCloseDialog()" color="alert" class="btn-size-default">
            {{ content.btnClose }}
          </button>
        </div>
      </div>
    </mat-card-actions>
  </mat-card>
</mat-dialog-content>
