import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'psm-section-card',
  templateUrl: './psm-section-card.component.html',
  styleUrls: ['./psm-section-card.component.scss']
})
export class PsmSectionCardComponent implements OnInit {

  /**
   * The card's header text
   */
  @Input()
  headerTxt: string;

  @Input()
  pillarId: string;

  constructor() { }

  ngOnInit(): void {
  }

}
