<div class="m-container">
  <div class="menu-container">
    <div class="sub-container" *ngFor="let pillar of pillars | async">
      <psm-home-card
        matRipple
        [title]="pillar?.shortTitle"
        [pillarId]="pillar?.pillarId"
        (click)="onCardMenuClick(pillar)"
        [isSelected]="isCardSelected(pillar)"
      ></psm-home-card>
    </div>
  </div>
  <psm-home-card [title]="content.goalListTitle" [pillarId]="pillar?.pillarId">
    <section>
      <div class="objectiveGrid" *ngIf="!goals || goals.length === 0">
        <psm-form-field>
          <span class="error-text-box error-box" [innerHtml]="content.noGoalsError">
          </span>
          <psm-button
            [label]="content.createGoalBtn"
            (onClick)="onCreateGoalClick(pillar)"
          ></psm-button>
        </psm-form-field>
      </div>
      <p-table
        *ngIf="goals && goals.length > 0"
        [value]="goals"
        [scrollable]="true"
        [(selection)]="goal"
        (onRowSelect)="onRowSelect($event)"
        selectionMode="single"
        styleClass="p-datatable-striped">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 3rem;"></th>
            <th>{{ content.objectiveTable.col1 }}</th>
            <th>{{ content.objectiveTable.col2 }}</th>
            <th>{{ content.objectiveTable.col3 }}</th>
            <th>{{ content.objectiveTable.col4 }}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-goalRow>
          <tr [pSelectableRow]="goalRow">
            <td style="width: 3rem;">
              <button
                style="margin-right: 5px; margin-top: 5px;"
                type="button"
                pButton
                pRipple
                class="p-button-rounded p-button-text p-button-info"
                icon="pi pi-eye"
                (click)="onViewObjectiveClick(goalRow)">
              </button>
            </td>
            <td>
              {{ goalRow?.header }}
            </td>
            <td>
              {{ goalRow?.reason }}
            </td>
            <td>
              <mat-chip-list>
                <mat-chip *ngFor="let resourceAvailable of goalRow?.goalResAvailable">
                  {{ resourceAvailable?.resource }}
                </mat-chip>
              </mat-chip-list>
            </td>
            <td>
              <mat-chip-list>
                <mat-chip *ngFor="let resourceRequired of goalRow?.goalResRequired">
                  {{ resourceRequired?.resource }}
                </mat-chip>
              </mat-chip-list>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-agenda>
          Expansion
        </ng-template>
      </p-table>
    </section>
  </psm-home-card>
  <psm-home-card [title]="content.objectiveListTitle" [pillarId]="pillar?.pillarId">
    <section>
      <!-- <div class="objectiveGrid" *ngIf="objectivesCount === 0">
        <psm-form-field>
          <span class="error-text-box error-box" [innerHtml]="content.objCard.noObjectiveError">
          </span>
          <psm-button
            [label]="content.objCard.createObjBtnLbl"
            (onClick)="onCreateObjectiveClick(goal)"
          ></psm-button>
        </psm-form-field>
      </div> -->
      <div class="objectiveGrid">
        <div *ngFor="let objective of objectives | async; let i = index" class="objective-card-grid">
          <mat-card class="mat-grid">
            <mat-card-title>{{ content.objCard.lblActionPlan + ' ' +  (i + 1)}}</mat-card-title>
            <mat-card-subtitle>{{ objective?.description }}</mat-card-subtitle>
            <div class="card-goal-content">
              <div>
                <psm-form-field
                  [title]="content.objCard.lblCompletingObjWithCurrentResources"
                  [hint]="objective?.realityDescription">
                </psm-form-field>
              </div>
              <div>
                <psm-form-field
                  [title]="content.objCard.lblCompletingObjWithoutCurrentResources"
                  [hint]="objective?.requiredResDescription">
                </psm-form-field>
              </div>
            </div>
            <div class="card-goal-content">
              <div>
                <psm-form-field [title]="content.objCard.lblCurrentResources">
                  <mat-chip-list>
                    <mat-chip *ngFor="let resourceAvailable of objective.checkedResourceAvailable">
                      {{ resourceAvailable.resource }}
                    </mat-chip>
                  </mat-chip-list>
                </psm-form-field>
              </div>
              <div>
                <psm-form-field [title]="content.objCard.lblRequiredResources">
                  <mat-chip-list>
                    <mat-chip *ngFor="let resourceRequired of objective.checkedResourceRequired">
                      {{ resourceRequired.resource }}
                    </mat-chip>
                  </mat-chip-list>
                </psm-form-field>
              </div>
            </div>
            <div class="card-goal-content">
              <div>
                <psm-form-field
                  [title]="content.objCard.startDate"
                  [hint]="objective?.startDate.toMillis() | date: 'dd/MM/yyyy h:mm:ss a'">
                </psm-form-field>
              </div>
              <div>
                <psm-form-field
                  [title]="content.objCard.endDate"
                  [hint]="objective?.endDate.toMillis() | date: 'dd/MM/yyyy h:mm:ss a'">
                </psm-form-field>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </section>
  </psm-home-card>
</div>
