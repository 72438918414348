import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

@Injectable()
export class ObjectiveFormConfig {

  realityDescFormControl = 'realityDescFormControl';
  requiredResDescriptionFormControl = 'requiredResDescriptionFormControl';

  descFormControl = 'descControl';
  descMaxLength = 300;
  descMinLength = 1;
  descValidators = [
    Validators.required,
    Validators.maxLength(this.descMaxLength),
    Validators.minLength(this.descMinLength),
  ];

  startDate = new Date((new Date().getTime() - 3888000000));
  startDtFormControl = 'startDtControl';
  startDtValidators = [
    Validators.required
  ];

  endDtFormControl = 'endDtControl';
  endDtValidators = [
    Validators.required
  ];

  constructor() {}
}
