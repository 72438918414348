export const ACTION = {
  PARENT_ACTION: {
    SELF: {
      name: 'PILLAR_GOALS_CRUD',
      active: false,
    },
    PILLAR_GOALS: {
      name: 'PILLAR_GOALS',
      active: false,
    },
    ROUTE_ERROR: {
      name: 'ROUTE_ERROR',
    }
  },
  CRUD_ACTION: {
    CREATE: {
      name: 'CREATE',
      active: false,
      pillarAction: ''
    },
    UPDATE: {
      name: 'UPDATE',
      active: false,
      pillarAction: '',
      goalTitle: '',
      index: -1
    }
  }
}