import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import _ from 'lodash';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ServiceAccountApiService } from 'src/app/services/firebase/service-account/service-account-api.service';
import { activateServiceAccount, activateServiceAccountFailure, loadServiceAccount, loadServiceAccountFailure, loadServiceAccountSuccess, setServiceAccount, setServiceAccountFailure, setServiceAccountSuccess } from '../action/service-account.actions';
import { ServiceAccount } from '../model/service-account.model';
import { QueryDocumentSnapshot } from '@angular/fire/firestore';

@Injectable()
export class ServiceAccountEffects {

  constructor(
    private actions$: Actions,
    private serviceAccountApiService: ServiceAccountApiService) {
  }

  loadServiceAccount$ = createEffect(() => this.actions$.pipe(
    ofType(loadServiceAccount),
    switchMap(props =>
      this.serviceAccountApiService.getAccountByEmail(props.email).pipe(
        map(e => {
          if (e.empty) {
            return loadServiceAccountFailure({ error: 'A service account has not been found' });
          }

          const docRef = _.find(e.docs, (doc) => {
            if (doc.data().email === props.email) {
              return doc.data();
            }
          }) as QueryDocumentSnapshot<unknown>;

          const serviceAccount: ServiceAccount = {
            statusDescription: docRef.data()?.['statusDescription'],
            activationDTTM: docRef.data()?.['activationDTTM'],
            createDTTM: docRef.data()?.['createDTTM'],
            expireDTTM: docRef.data()?.['expireDTTM'],
            companyName: docRef.data()?.['companyName'],
            email: docRef.data()?.['email'],
            productName: docRef.data()?.['productName'],
            status: docRef.data()?.['status'],
            token: docRef.data()?.['token'],
            profileRef: docRef.data()?.['profileRef'],
            idField: docRef.ref.id
          };

          return loadServiceAccountSuccess({
            account: { ...serviceAccount }
          });
        }),
        catchError((error) => {
          return of(loadServiceAccountFailure({ error }));
        })
      ))
  ));

  activateServiceAccount$ = createEffect(() => this.actions$.pipe(
    ofType(activateServiceAccount),
    switchMap(props =>
      this.serviceAccountApiService.getAccountByToken(props.token).pipe(
        map(docRef => {
          if (docRef.empty) {
            return activateServiceAccountFailure({ error: 'A service account has not been found' });
          }

          const serviceAccount = _.find(docRef.docs, (doc) => {
            if (doc.data().token === props.token) {
              return doc.data();
            }
          }) as ServiceAccount;

          if (!serviceAccount) {
            throw new Error('Service account is not defined');
          }

          return setServiceAccount({
            account: {
              status: true,
              statusDescription: 'Active',
              email: props.email,
              activationDTTM: new Date(),
              token: props.token
            }
          });
        }),
        catchError((error) => {
          return of(activateServiceAccountFailure({ error }));
        })
      ))
  ));

  setServiceAccount$ = createEffect(() => this.actions$.pipe(
    ofType(setServiceAccount),
    switchMap(props =>
      this.serviceAccountApiService.setDocument(props.account).pipe(
        map(serviceAccount => {
          return setServiceAccountSuccess({
            account: { ...props.account }
          });
        }),
        catchError((error) => {
          return of(setServiceAccountFailure({ error }));
        })
      ))
  ));

}
