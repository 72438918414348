import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import PT from './i18n/pt.json';
import { AppLang } from 'src/assets/i18n/app-lang';

@Component({
  selector: 'app-invalid-input-validation-dialog',
  templateUrl: './invalid-input-validation-dialog.component.html',
  styleUrls: ['./invalid-input-validation-dialog.component.scss']
})
export class InvalidInputValidationDialogComponent implements OnInit {

  content: any;

  constructor(
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT['content'];
  }

}
