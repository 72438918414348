import { Injectable } from '@angular/core';
import { AngularFirestore, QuerySnapshot } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { getPillar, getPillarFailure, getPillars, getPillarsFailure, setPillarCollectionFailure } from 'src/app/state-v2/action/pillar.actions';
import { Pillar } from '../../../state-v2/model/pillar.model';
import { FirestorePath } from 'src/shared/firestore-paths';
import _ from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class PillarApiService {

  constructor(
    private store: Store,
    private af: AngularFirestore
  ) { }

  /**
   * Sets a pillar collection
   * @param profileId
   * @param pillars
   * @returns Observable<Pillar[]>
   */
  setPillarCollection(profileId: string, pillars: Pillar[]): Observable<Pillar[]> {
    try {
      if (!profileId && !pillars) {
        console.error('Profile is not defined');
        return of();
      }
      _.each(JSON.parse(JSON.stringify(pillars)), (value, index) => {
        value.order = (index + 1);
        this.af.collection(FirestorePath.PROFILES_COLLECTION + '/' + profileId + '/' + FirestorePath.PILLARS_COLLECTION)
          .doc(value.pillarId)
          .set(value, { merge: true });
      });

      return of(pillars);
    } catch (error) {
      console.error(error);
      this.store.dispatch(setPillarCollectionFailure({
        error
      }));
    }
  }

  /**
   * It retrieves a pillar document from firebase given the pillar id
   * @param pillarId The pillar node/id reference
   * @returns Stream of pillars
   */
  getPillarStream(pillarId: string, profileId: string): Observable<Pillar[]> {
    try {
      this.store.dispatch(getPillar({ pillarId }));

      if (pillarId) {
        return this.af.collection<Pillar>(FirestorePath.PROFILES_COLLECTION + '/' + profileId + '/' + FirestorePath.PILLARS_COLLECTION,
        ref => ref.where('pillarId', '==', pillarId)).valueChanges();
      }

      return of([]);
    } catch (error) {
      console.error(error);
      this.store.dispatch(getPillarFailure({
        error
      }));
    }
  }

  /**
   * It retrieves all pillars document from firebase given the profile id
   * @returns Stream of pillars
   */
  getPillarsStream(profileId: string): Observable<Pillar[]> {
    try {
      this.store.dispatch(getPillars());

      if (profileId) {
        return this.af.collection<Pillar>(FirestorePath.PROFILES_COLLECTION + '/' + profileId + '/' + FirestorePath.PILLARS_COLLECTION,
          ref => ref.orderBy('order')).valueChanges();
      }

      return of([]);
    } catch (error) {
      console.error(error);
      this.store.dispatch(getPillarsFailure({
        error
      }));
    }
  }

  /**
   * It retrieves all pillars document from firebase given the profile id
   * @returns Stream of pillars
   */
  getPillars(profileId: string): Observable<QuerySnapshot<Pillar>> {
    try {
      if (profileId) {
        return this.af.collection<Pillar>(FirestorePath.PROFILES_COLLECTION + '/' + profileId + '/' + FirestorePath.PILLARS_COLLECTION,
          ref => ref.orderBy('order')).get();
      }

      return of();
    } catch (error) {
      console.error(error);
      this.store.dispatch(getPillarsFailure({
        error
      }));
    }
  }

}
