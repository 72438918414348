import { Directive, Input, ElementRef, HostListener } from '@angular/core';
import { BACKSPACE, DELETE, TAB, UP_ARROW, DOWN_ARROW, LEFT_ARROW, RIGHT_ARROW, HOME, END } from '@angular/cdk/keycodes';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[onlyNumbers]'
})
export class OnlyNumbersDirective {

  /**
   * Flag to activate the directive
   */
  @Input() allowNumbers = false;

  constructor(private elementRef: ElementRef) { }

  private authoringKeys: Array<number> = [
    DELETE,
    BACKSPACE,
    TAB,
    HOME,
    END,
    UP_ARROW,
    DOWN_ARROW,
    LEFT_ARROW,
    RIGHT_ARROW
  ];

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (!this.allowNumbers) {
      return;
    }

    // tslint:disable-next-line: deprecation
    if (this.authoringKeys.indexOf(e.keyCode) > -1 || !isNaN(Number(e.key))) {
      return;
    }

    e.preventDefault();
  }

  @HostListener('input', ['$event'])
  onInput(e: Event) {
    if (!this.allowNumbers) {
      return;
    }

    e.preventDefault();

    this.elementRef.nativeElement.value = this.elementRef.nativeElement.value.replace(/\D/g, '');
  }

}
