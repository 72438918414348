import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/firebase/authentication/auth.service';
import { AppLang } from 'src/assets/i18n/app-lang';
import { RouteName } from 'src/shared/router-paths';
import { LoginFormInvalidDialogComponent } from '../dialogs/login/login-form-invalid-dialog/login-form-invalid-dialog.component';
import { MasterContentPage } from '../master-content/master-content.class.page';
import PT from './i18n/pt.json';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  pageContent: MasterContentPage;
  formGroup: FormGroup;
  content: any;
  hidePwd = true;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private fb: FormBuilder,
    private authService: AuthService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;
    this.formGroup = this.fb.group({
      email:
      ['',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
        ]
      ],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
    if (!this.pageContent) {
      this.pageContent = new MasterContentPage(
        false,
        true,
        'assets/images/svg/pdpf-logo-vert.svg',
        this.content.txtWelcome,
        [this.content.txtSubtitle]
      );
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.formGroup.controls; }

  public onSubmitForm() {
    if (this.formGroup.invalid) {
      this.dialog.open(LoginFormInvalidDialogComponent);
      return;
    }

    const user = {
      email: this.f.email.value,
      password: this.f.password.value,
    };

    this.authService.login(user);
  }

  public onAccountActivationClick() {
    this.router.navigate([RouteName.ACTIVATE_ACCOUNT]);
  }

}
