import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AgendaApiService } from 'src/app/services/firebase/agenda/agenda-api.service';
import { AppLang } from 'src/assets/i18n/app-lang';
import { addAgendaItem, addAgendaItemSuccess, deleteAgendaItem, deleteAgendaItemFailure, deleteAgendaItemSuccess } from '../action/agenda.actions';
import { addNoteApiFailure } from '../action/notes.actions';
import PT from './i18n/pt.json';

@Injectable()
export class AgendaEffects {

  constructor(
    private actions$: Actions,
    private snackBar: MatSnackBar,
    private agendaApiService: AgendaApiService,
    private translate: TranslateService) {
  }

  addAgendaItem$ = createEffect(() => this.actions$.pipe(
    ofType(addAgendaItem),
    switchMap(props =>
      this.agendaApiService.addDocument(props.agenda).pipe(
        map(agenda => {
          this.translate.setTranslation(AppLang.DEFAULT, PT, true);
          this.snackBar.open(
            PT['service-notification'].success, '', {
            duration: 2 * 1000
          });
          return addAgendaItemSuccess({docId: agenda?.idField ?? 'docId was auto generated'});
        }),
        catchError((error) => {
          this.snackBar.open(
            PT['service-notification'].failure, '', {
            duration: 2 * 1000
          })
          return of(addNoteApiFailure({error}));
        })
      ))
  ));

  deleteAgendaItem$ = createEffect(() => this.actions$.pipe(
    ofType(deleteAgendaItem),
    switchMap(props =>
      this.agendaApiService.deleteDocument(props.idField).pipe(
        map(idField => {
          this.translate.setTranslation(AppLang.DEFAULT, PT, true);
          this.snackBar.open(
            PT['service-notification'].deletionSuccess, '', {
            duration: 2 * 1000
          });

          return deleteAgendaItemSuccess({ idField });
        }),
        catchError((error) => {
          this.snackBar.open(
            PT['service-notification'].failure, '', {
            duration: 2 * 1000
          })

          return of(deleteAgendaItemFailure({error}));
        })
      ))
  ));

}
