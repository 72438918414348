import { Injectable } from '@angular/core';
import { QueryDocumentSnapshot } from '@angular/fire/firestore';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import _ from 'lodash';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ProfileServiceV2 } from 'src/app/services/firebase/profile/profile.service';
import { loadProfile, loadProfileFailure, loadProfileSuccess, setProfile, setProfileFailure, setProfileSuccess } from '../action/profile.actions';
import { Profile } from '../model/profile.model';
import { Store } from '@ngrx/store';
import { loadPillarCollection } from '../action/pillar.actions';

@Injectable()
export class ProfileEffects {

  constructor(
    private actions$: Actions,
    private profileService: ProfileServiceV2,
    private store: Store) {
  }

  setProfile$ = createEffect(() => this.actions$.pipe(
    ofType(setProfile),
    switchMap(props =>
      this.profileService.setProfile({
        ...props.profile
      }).pipe(
        map(profile => {
          return setProfileSuccess({
            profile: { ...profile }
          });
        }),
        catchError((error) => {
          return of(setProfileFailure({ error }));
        })
      ))
  ));

  loadProfile$ = createEffect(() => this.actions$.pipe(
    ofType(loadProfile),
    switchMap(props =>
      this.profileService.getProfileByEmail(props.email).pipe(
        map(e => {
          const docRef = _.find(e.docs, (doc) => {
            if (doc.data()['email'] === props.email) {
              return doc.data();
            }
          }) as QueryDocumentSnapshot<unknown>;

          const profile: Profile = {
            active: docRef.data()?.['active'],
            completed: docRef.data()?.['completed'],
            createDTTM: docRef.data()?.['createDTTM'],
            email: docRef.data()?.['email'],
            lastUpdateDTTM: docRef.data()?.['lastUpdateDTTM'],
            personalInfo: {
              birthCountry: docRef.data()?.['personalInfo']?.birthCountry,
              dob: docRef.data()?.['personalInfo']?.dob,
              currentCountry: docRef.data()?.['personalInfo']?.currentCountry,
              gender: docRef.data()?.['personalInfo']?.gender,
              lastName: docRef.data()?.['personalInfo']?.lastName,
              name: docRef.data()?.['personalInfo']?.name,
              phoneNumber: docRef.data()?.['personalInfo']?.phoneNumber
            },
            pillarCollectionId: docRef.data()?.['pillarCollectionId'],
            uid: docRef.data()?.['uid'],
            avatarUrl: docRef.data()?.['avatarUrl'],
            idField: docRef.ref.id
          };

          this.store.dispatch(loadPillarCollection({
            profileId: profile?.idField
          }));

          return loadProfileSuccess({ profile });
        }),
        catchError((error) => {
          return of(loadProfileFailure({ error }));
        })
      ))
  ));

}
