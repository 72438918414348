import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { addAgendaItemFailure, deleteAgendaItemFailure, getAgendaByIdFailure, getAgendaFailure } from 'src/app/state-v2/action/agenda.actions';
import { Agenda } from 'src/app/state-v2/model/agenda.model';
import { AppLang } from 'src/assets/i18n/app-lang';
import { FirestorePath } from 'src/shared/firestore-paths';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AgendaApiService {

  constructor(
    private store: Store,
    private af: AngularFirestore,
  ) { }

  /**
   * It adds or updates an agenda document.
   * It merges the document if an IdField is found in the object
   * @param agenda
   * @returns Agenda<Agenda>
   */
  addDocument(agenda: Agenda): Observable<Agenda> {
    try {
      moment.locale(AppLang.DEFAULT);
      const agendaDocRef: Agenda = {
        ...agenda
      };

      if (agendaDocRef.idField) {
        agendaDocRef.lastUpdatedDate = moment().toDate();
        this.af.doc(FirestorePath.AGENDA + '/' + agendaDocRef.idField).set(agendaDocRef, { merge: true });
      } else {
        agendaDocRef.creationDate = moment().toDate();
        this.af.collection(FirestorePath.AGENDA).doc().set(agendaDocRef);
      }

      return of(agendaDocRef);
    } catch (error) {
      console.error(error);
      this.store.dispatch(addAgendaItemFailure({ error: error }));
    }
  }

  /**
   * It removes a document from firestore given a docId
   * @param idField string
   */
  deleteDocument(idField: string): Observable<string> {
    try {
      if (!idField) {
        throw new Error('The document ID is invalid or missing');
      }

      this.af.doc<Agenda>(FirestorePath.AGENDA + '/' + idField).delete();
      return of(idField);
    } catch (error) {
      this.store.dispatch(deleteAgendaItemFailure({
        error
      }));
    }
  }

  /**
   * Returns the agenda activity given the idfield
   * @param idField
   * @returns Observable<Agenda>
   */
  getAgendaById(idField: string): Observable<Agenda> {
    try {
      if (idField) {
        return this.af.doc<Agenda>(FirestorePath.AGENDA + '/' + idField).get().pipe(
          map(docRef => {
            if (docRef.exists) {
              return {
                ...docRef.data(),
                idField: docRef.id
              };
            }
          })
        );
      }

      return of();
    } catch (error) {
      console.error(error);
      this.store.dispatch(getAgendaByIdFailure({ error }));
    }
  }

  /**
   * It streams the agenda documents
   * @param filter by week day
   * @returns Observable
   */
  getAgenda(weekDay: number, profileRef: any): Observable<Agenda[]> {
    try {
      if (profileRef) {
        if (weekDay && weekDay >= 0 && weekDay <= 6) {
          return this.af.collection<Agenda>(FirestorePath.AGENDA,
            ref => ref
              .where('profileId', '==', profileRef)
              .where('weekDay', '==', weekDay)
              .orderBy('startHour')
              .orderBy('startMin'))
              .valueChanges({ idField: 'idField' });
        } else {
          return this.af.collection<Agenda>(FirestorePath.AGENDA, ref => ref
              .where('profileId', '==', profileRef))
              .valueChanges({ idField: 'idField' });
        }
      }

      return of([]);
    } catch (error) {
      console.error(error);
      this.store.dispatch(getAgendaFailure({ error }));
    }
  }

}
