import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ObjectiveApiServiceV2 } from 'src/app/services/firebase/objective/objective-api.service';
import { PillarApiService } from 'src/app/services/firebase/pillar/pillar-api.service';
import { openObjectivesSummaryPage } from 'src/app/state-v2/action/objective.actions';
import { Pillar } from 'src/app/state-v2/model/Pillar.model';
import { ObjectiveModel } from 'src/app/state-v2/model/objective.model';
import { ServiceAccount } from 'src/app/state-v2/model/service-account.model';
import { selectServiceAccount } from 'src/app/state-v2/selector/service-accont.selector';
import { AppLang } from 'src/assets/i18n/app-lang';
import PT from './i18n/pt.json';

@Component({
  selector: 'app-objective-summary',
  templateUrl: './objective-summary.component.html',
  styleUrls: ['./objective-summary.component.scss']
})
export class ObjectiveSummaryComponent implements OnInit, OnDestroy {

  /**
   * The page's copy content
   */
  content: any;

  /**
   * The pillar stream
   *
   * @type {Observable<Pillar[]>}
   * @memberof SummaryComponent
   */
  pillars: Observable<Pillar[]> = EMPTY;
  pillarSubscription: Subscription;

  /**
   * The current pillar selection
   *
   * @type {Pillar}
   * @memberof SummaryComponent
   */
  pillar: Pillar;

  /**
   * The objectives stream
   *
   * @type {Observable<ObjectiveModel[]>}
   * @memberof SummaryComponent
   */
  objectives: Observable<ObjectiveModel[]> = EMPTY;
  objectiveSubscription: Subscription;

  /**
   * It represents the service account state
   */
  serviceAccount: ServiceAccount;

  constructor(
    private translate: TranslateService,
    private pillarApi: PillarApiService,
    private objectiveApi: ObjectiveApiServiceV2,
    private spinner: NgxSpinnerService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.store.select(selectServiceAccount).subscribe(serviceAccount => this.serviceAccount = serviceAccount);

    this.spinner.show();
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;

    this.pillars = this.pillarApi.getPillarsStream(this.serviceAccount?.profileRef ?? '');
    this.pillarSubscription = this.pillars.pipe(take(1)).subscribe(
      pillars => pillars.forEach(pillar => {
        if (pillar?.order === 1) {
          this.pillar = pillar;
          this.objectives = this.objectiveApi.getObjectivesByPillarNode(pillar.pillarId, this.serviceAccount?.profileRef ?? '');
          this.spinner.hide();
        }
      })
    );
  }

  ngOnDestroy(): void {
      if (this.pillarSubscription) {
        this.pillarSubscription.unsubscribe();
      }

      if (this.objectiveSubscription) {
        this.objectiveSubscription.unsubscribe();
      }
  }

  /**
   * It loads the Objectives and Goals for the specified pillar
   */
  onCardMenuClick(pillar) {
    if (pillar) {
      this.pillar = pillar;
      this.objectives = this.objectiveApi.getObjectivesByPillarNode(pillar.pillarId, this.serviceAccount?.profileRef ?? '');
    }
  }

  /**
   * Utility to add background color to the selected card
   * @param pillar
   * @returns Boolean
   */
  isCardSelected(pillar) {
    return pillar.pillarId === this.pillar.pillarId;
  }

}
