<mat-nav-list>
  <a mat-list-item (click)="onBtnMyGoalsClick()">
    <span mat-line>{{ content.btnMyGoals }}</span>
  </a>
  <mat-divider></mat-divider>
  <a mat-list-item (click)="onBtnMyObjectivesClick()">
    <span mat-line>{{ content.btnMyObjectives }}</span>
  </a>
  <mat-divider></mat-divider>
  <a mat-list-item (click)="onBtnSetupClick()">
    <span mat-line>{{ content.btnSetup }}</span>
  </a>
  <mat-divider></mat-divider>
  <a mat-list-item (click)="onBtnExampleClick()">
    <span mat-line>{{ content.btnExample }}</span>
  </a>
  <mat-divider></mat-divider>
  <a mat-list-item (click)="onBtnCloseClick()">
    <span mat-line>{{ content.btnClose }}</span>
  </a>
</mat-nav-list>