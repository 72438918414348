import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AppLang } from '../../../../assets/i18n/app-lang';
import PT from './i18n/pt.json';

@Component({
  selector: 'app-pillar-creation-dialog',
  templateUrl: './pillar-creation-dialog.component.html',
  styleUrls: ['./pillar-creation-dialog.component.scss'],
})
export class PillarCreationDialogComponent implements OnInit {
  content: any;

  constructor(
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;
    setTimeout(() => {
      if (document.getElementById('dialog-container')) {
        document.getElementById('dialog-container').scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, 500);
  }
}
