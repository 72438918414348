import { createReducer, on } from '@ngrx/store';
import { loadProfileSuccess, setProfileSuccess } from '../action/profile.actions';
import { Profile } from '../model/profile.model';

export interface ProfileState {
  profile: Profile | null;
}

export const initialState: ProfileState = {
  profile: null,
};

export const profileReducer = createReducer(
  initialState,
  on(setProfileSuccess, (state, { profile }) => {
    return {...state, profile}
  }),
  on(loadProfileSuccess, (state, { profile }) => {
    return {...state, profile}
  })
);
