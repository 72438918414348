<div class="p-flex-column p-jc-center">
  <div class="text-header">
    {{ content.title }}
  </div>
  <div class="text-subtitle p-text-left">
    <p>{{ content.text1 }}</p>
    <br/>
    <p>{{ content.text2 }}</p>
  </div>
  <div class="p-grid p-jc-center p-mt-4">
    <div class="p-col-6">
      <psm-button
        class="w-full"
        [mat-dialog-close]="data.confirm"
        [label]="content.components.btnYes">
      </psm-button>
    </div>
  </div>
  <div class="p-grid p-jc-center">
    <div class="p-col-6">
      <psm-button
        [label]="content.components.btnNo"
        color="danger"
        (onClick)="onCloseDialog()">
      </psm-button>
    </div>
  </div>
</div>
