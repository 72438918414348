<div class="head-card">
  <form class="card-form">
    <div
      class="header"
      [ngClass]="{
        'header-blue-bg': pillarId === 'self_development',
        'header-red-bg': pillarId === 'health',
        'header-yellow-bg': pillarId === 'family',
        'header-green-bg': pillarId === 'work'
      }"
    >
      <h1 class="header-h">
        {{ headerTxt }}
      </h1>
    </div>

    <div class="content-card">
      <ng-content></ng-content>
    </div>
  </form>
</div>
