<div class="menu-container">
  <psm-home-card
    [title]="title ?? goal?.header"
    [subTitle]="content.lblActionPlan"
    [pillarId]="pillar.pillarId">
    <section>
      <mat-card>
        <mat-card-title>{{ objective.description }}</mat-card-title>
        <div class="card-goal-content">
          <div>
            <psm-form-field [title]="content.lblRealityToUse">
              <span>
                {{ objective.realityDescription }}
              </span>
            </psm-form-field>
          </div>
          <div>
            <psm-form-field [title]="content.lblResourcesAvailable">
              <mat-chip-list>
                <mat-chip
                  [ngClass]="{
                    'chip-bg-color-blue': pillar.pillarId === 'self_development',
                    'chip-bg-color-green': pillar.pillarId === 'work',
                    'chip-bg-color-yellow': pillar.pillarId === 'family',
                    'chip-bg-color-red': pillar.pillarId === 'health'
                  }"
                  *ngFor="let resourceAvailable of objective.checkedResourceAvailable"
                >
                  {{ resourceAvailable.resource }}
                </mat-chip>
              </mat-chip-list>
            </psm-form-field>
          </div>
        </div>
        <div class="card-goal-content">
          <div>
            <psm-form-field [title]="content.lblRequiredToUse">
              <span>
                {{ objective.requiredResDescription }}
              </span>
            </psm-form-field>
          </div>
          <div>
            <psm-form-field [title]="content.lblResourcesRequired">
              <mat-chip-list>
                <mat-chip
                  *ngFor="let resourceRequired of objective.checkedResourceRequired"
                  [ngClass]="{
                    'chip-bg-color-blue': pillar.pillarId === 'self_development',
                    'chip-bg-color-green': pillar.pillarId === 'work',
                    'chip-bg-color-yellow': pillar.pillarId === 'family',
                    'chip-bg-color-red': pillar.pillarId === 'health'
                  }"
                >
                  {{ resourceRequired.resource }}
                </mat-chip>
              </mat-chip-list>
            </psm-form-field>
          </div>
        </div>
        <div class="card-goal-content">
          <div>
            <psm-form-field
              [title]="content.startDate"
              [hint]="objective.startDate.toMillis() | date: 'dd/MM/yyyy h:mm:ss a'">
            </psm-form-field>
          </div>
          <div>
            <psm-form-field
              [title]="content.endDate"
              [hint]="objective.endDate.toMillis() | date: 'dd/MM/yyyy h:mm:ss a'">
            </psm-form-field>
          </div>
        </div>
        <mat-card-actions>
          <div class="card-actions">
            <button *ngIf="showControls" mat-button (click)="onEditClick(objective)">
              <mat-icon>create</mat-icon>
              {{ content.editBtn }}
            </button>
            <button *ngIf="showControls"  mat-button (click)="onRemoveClick(objective)">
              <mat-icon>remove_circle</mat-icon>
              {{ content.removeBtn }}
            </button>
            <button *ngIf="showControls" mat-button (click)="onAddGoalNoteClick(objective)">
              <mat-icon>edit_note</mat-icon>
              {{ content.addGoalNotes }}
            </button>
          </div>
        </mat-card-actions>
      </mat-card>
    </section>
  </psm-home-card>
</div>
