import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

export interface OptionModel {
  value: string;
  viewValue: string;
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'psm-dropdown',
  templateUrl: './psm-dropdown.component.html',
  styleUrls: ['./psm-dropdown.component.scss']
})
export class PsmDropdownComponent implements OnInit {

  /**
   * Parent form group with the form control
   */
  @Input() parentFormGroup: FormGroup;

  /**
   * Placeholder for the select input
   */
  @Input() placeholder: string;

  /**
   * Whether or not the form is disabled
   */
  @Input() disabled = false;

  /**
   * Label for the input
   */
  @Input() label: string;

  /**
   * The value of the field
   */
  @Input() value: string;

  /**
   * Whether the field is required
   */
  @Input() required = false;

  /**
   * Array with the options for the dropdown
   */
  @Input() optionList: Array<OptionModel>;

  /**
   * Event emitted when the select panel has been toggled.
   */
  @Output() openedChange = new EventEmitter();

  /**
   * Event emitted when the selected value has been changed by the user.
   */
  @Output() selectionChange = new EventEmitter();

  constructor() { }

  ngOnInit(): void {

  }

}
