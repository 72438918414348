<div class="m-container">
  <psm-home-card [title]="content.title" [pillarId]="pillar?.pillarId" [subTitle]="content.titleHint">
    <section>
      <div class="menu-container">
        <div class="sub-container" *ngFor="let pillar of pillars | async">
          <psm-home-card
            matRipple
            [title]="pillar?.shortTitle"
            [pillarId]="pillar?.pillarId"
            (click)="onCardMenuClick(pillar)"
            [isSelected]="isCardSelected(pillar)"
          ></psm-home-card>
        </div>
      </div>
    </section>
  </psm-home-card>
  <div class="objective-grid" *ngIf="!(goals | async)">
    <psm-form-field>
      <span class="error-text-box error-box" [innerHtml]="content.emptyListError">
      </span>
    </psm-form-field>
  </div>
  <div *ngFor="let goal of goals | async; let i = index">
    <psm-goal-view
      [pillarItem]="pillar"
      [goalItem]="goal"
      [showGoalControls]="true"
      [showGoalCRUDControls]="true">
    </psm-goal-view>
  </div>
</div>
