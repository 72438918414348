import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { GoalApiService } from 'src/app/services/firebase/goal/goal-api.service';
import { PillarApiService } from 'src/app/services/firebase/pillar/pillar-api.service';
import { Pillar } from 'src/app/state-v2/model/Pillar.model';
import { ObjectiveModel } from 'src/app/state-v2/model/objective.model';
import { ServiceAccount } from 'src/app/state-v2/model/service-account.model';
import { GoalState } from 'src/app/state-v2/reducer/goal.reducer';
import { selectServiceAccount } from 'src/app/state-v2/selector/service-accont.selector';
import { RouteName } from 'src/shared/router-paths';
import { AppLang } from '../../../assets/i18n/app-lang';
import { ObjectiveApiServiceV2 } from './../../services/firebase/objective/objective-api.service';
import PT from './i18n/pt.json';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {

  /**
   * The page's copy content
   */
  content: any;

  /**
   * The goals stream
   *
   * @memberof SummaryComponent
   */
  goals: GoalState[];

  /**
   * The current goal selection
   *
   * @type {GoalState}
   * @memberof SummaryComponent
   */
  goal: GoalState;

  /**
   * The pillar stream
   *
   * @type {Observable<Pillar[]>}
   * @memberof SummaryComponent
   */
  pillars: Observable<Pillar[]>;

  /**
   * The current pillar selection
   *
   * @type {Pillar}
   * @memberof SummaryComponent
   */
  pillar: Pillar;

  /**
   * The objectives stream
   *
   * @type {Observable<ObjectiveModel[]>}
   * @memberof SummaryComponent
   */
  objectives: Observable<ObjectiveModel[]> = EMPTY;

   /**
   * Service Account State
   */
  serviceAccount: ServiceAccount;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private pillarApi: PillarApiService,
    private goalApi: GoalApiService,
    private objectiveApi: ObjectiveApiServiceV2,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.store.select(selectServiceAccount).subscribe(serviceAccount => this.serviceAccount = serviceAccount);

    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;

    this.pillars = this.pillarApi.getPillarsStream(this.serviceAccount?.profileRef ?? '');
    this.pillars.pipe(take(1)).subscribe(
      pillars => pillars.forEach(pillar => {
        this.pillar = pillar
        this.goalApi.getGoals(pillar?.pillarId, this.serviceAccount?.profileRef ?? '').subscribe(goals => {
          this.goals = goals;
          this.goal = goals[0];

          if (goals[0]?.goalId) {
            this.objectives = this.objectiveApi.getObjectives(goals[0].goalId);
          }
        })
      })
    );
  }

  /**
   * It opens the detailed objective view
   */
  onViewObjectiveClick(goal: GoalState) {
    this.router.navigate([
      RouteName.GOAL_DETAIL, {
        goalId: goal?.goalId
      }
    ]);
  }

  /**
   * Routes to goal creation
   * @param pillar
   */
  onCreateGoalClick(pillar: Pillar) {
    this.router.navigate([
      RouteName.GOAL_FORM,
      {
        pillar: pillar?.pillarId
      },
    ]);
  }

  /**
   * It loads the Objectives and Goals for the specified pillar
   */
  onCardMenuClick(pillar) {
    this.objectives = EMPTY;

    if (pillar) {
      this.pillar = pillar;
      this.goalApi.getGoals(pillar?.pillarId, this.serviceAccount?.profileRef ?? '').subscribe(goals => {
      this.goals = goals;
      this.goal = goals[0];

      if (goals[0]?.goalId) {
        this.objectives = this.objectiveApi.getObjectives(goals[0].goalId);
      }
    });
    }
  }

  /**
   * Utility to add background color to the selected card
   * @param pillar
   * @returns Boolean
   */
  isCardSelected(pillar) {
    return pillar === this.pillar;
  }

  /**
   * It goes to the objective creation page
   */
  onCreateObjectiveClick(goal: GoalState) {
    this.router.navigate([
      RouteName.OBJECTIVE_FORM,
      {
        pillarNode: this.pillar.pillarId,
        goalId: goal.goalId
      },
    ]);
  }

  /**
   * Row select event click handler for the goal grid
   * @param event
   */
  onRowSelect(event) {
    this.goal = event.data;
  }

}
