import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { AgendaApiService } from 'src/app/services/firebase/agenda/agenda-api.service';
import { destroyAgendaPage, openAgendaPage } from 'src/app/state-v2/action/agenda.actions';
import { Agenda } from 'src/app/state-v2/model/agenda.model';
import { selectServiceAccount } from 'src/app/state-v2/selector/service-accont.selector';
import { AppLang } from '../../../assets/i18n/app-lang';
import { ACTIVITY_TYPE_OBJECTIVE_ITEM, AgendaItemViewComponent } from './agenda-item-view/agenda-item-view.component';
import PT from './i18n/pt.json';
import { ServiceAccount } from 'src/app/state-v2/model/service-account.model';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss']
})
export class AgendaComponent implements OnInit, OnDestroy {

  /**
   * The agenda datasource
   */
  agenda$: Observable<Agenda[]>;

  /**
   * Represents the current item in the agenda according to the current time
   */
  selectedAgendaItem: Agenda | any;

  /**
   * i18n
   */
  content: any;

  /**
   * Accordion's state
   */
  objectiveActivityAccordionState = false;

  /**
   * Accordion ref
   */
  @ViewChild(MatAccordion) accordion: MatAccordion;

  /**
   * The service account representation
   */
  serviceAccount: ServiceAccount;

  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private agendaApiService: AgendaApiService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.store.dispatch(openAgendaPage());

    moment.locale(AppLang.DEFAULT);
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;

    this.store.select(selectServiceAccount).subscribe(serviceAccount => {
      this.serviceAccount = serviceAccount;

      const now = moment();
      this.agenda$ = this.agendaApiService.getAgenda(now.day(), serviceAccount?.profileRef);
    });
  }

  ngOnDestroy(): void {
    this.store.dispatch(destroyAgendaPage());
  }

  /**
   * Filters the agenda activities by the selected date
   * @param selectedDay day of the week
   */
  dateSelectionChangeHandler(selectedDay) {
    if (selectedDay) {
      this.agenda$ = this.agendaApiService.getAgenda(selectedDay, this.serviceAccount?.profileRef);
    }
  }

  onAgendaItemClick(agendaItem: Agenda) {
    if (agendaItem?.type) {
      this.dialog.open(AgendaItemViewComponent, { data: { agendaItem: agendaItem, isEditMode: true, hasObjectiveRelationship: true } });
    }
  }

  /**
   * To display the agenda report
   */
  onReportClick() {

  }

}
