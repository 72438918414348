import { Injectable } from '@angular/core';
import { AngularFirestore, QueryDocumentSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import _ from 'lodash';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { getAccountByEmail, getAccountByToken, setServiceAccountFailure, setServiceAccountSuccess } from 'src/app/state-v2/action/service-account.actions';
import { Profile } from 'src/app/state-v2/model/profile.model';
import { ServiceAccount } from 'src/app/state-v2/model/service-account.model';
import { FirestorePath } from 'src/shared/firestore-paths';

@Injectable({
  providedIn: 'root'
})
export class ServiceAccountApiService {

  constructor(
    private store: Store,
    private af: AngularFirestore,) { }

  /**
    * It returns the current service account by email
    * @param email
    * @returns Observable<ServiceAccount>
    */
  getAccountByEmail(email: string): Observable<QuerySnapshot<ServiceAccount>> {
    try {
      if (!email) {
        console.error('Email is not defined');
        return of();
      }

      return this.af.collection<ServiceAccount>(FirestorePath.SERVICE_ACCOUNTS, ref => ref
        .where('email', '==', email))
        .get();
    } catch (error) {
      console.error(error);
      this.store.dispatch(getAccountByEmail({
        error
      }));
    }
  }

  /**
   * It returns the current service account by token
   * @param email
   * @returns Observable<ServiceAccount>
   */
  getAccountByToken(token: string): Observable<QuerySnapshot<ServiceAccount>> {
    try {
      if (!token) {
        console.error('Token is not defined');
        return of();
      }

      return this.af.collection<ServiceAccount>(FirestorePath.SERVICE_ACCOUNTS, ref => ref.where('token', '==', token)).get();
    } catch (error) {
      console.error(error);
      this.store.dispatch(getAccountByToken({
        error
      }));
    }
  }

  /**
   * It adds or updates a document.
   * It merges the document if an IdField is found in the object
   * @param agenda
   * @returns Agenda<Agenda>
   */
  setDocument(serviceAccount: ServiceAccount): Observable<ServiceAccount> {
    try {
      if (!serviceAccount && !serviceAccount.token) {
        throw new Error('Service account is not defined');
      }

      this.af.collection<Profile>(FirestorePath.PROFILES_COLLECTION, ref => ref.where('email', '==', serviceAccount.email)).get()
        .pipe(
          filter(value => !value.empty),
          map(e => {
            const profile = _.find(e.docs, (doc) => {
              if (doc.data().email === serviceAccount.email) {
                return doc;
              }
            }) as QueryDocumentSnapshot<unknown>;

            return profile;
          })
        ).subscribe(profile => {
          const account: ServiceAccount = {
            ...serviceAccount,
            profileRef: profile.ref.id
          };

          this.af.doc(FirestorePath.SERVICE_ACCOUNTS + '/' + account?.token).set(account, { merge: true });
          this.store.dispatch(setServiceAccountSuccess({
            account
          }));
        });

      return of(serviceAccount);
    } catch (error) {
      console.error(error);
      this.store.dispatch(setServiceAccountFailure({
        error: error
      }));
    }
  }

}
