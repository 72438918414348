import { Injectable } from '@angular/core';
import { AngularFirestore, QuerySnapshot } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { getProfileByEmailFailure, setProfileFailure } from 'src/app/state-v2/action/profile.actions';
import { Profile } from 'src/app/state-v2/model/profile.model';
import { FirestorePath } from 'src/shared/firestore-paths';


@Injectable({
  providedIn: 'root'
})
export class ProfileServiceV2 {

  constructor(
    private af: AngularFirestore,
    private store: Store
  ) { }

  /**
   * It sets a new profile or updates an existing profile
   * @param profile any
   * @param email string
   * @param updateStore boolean
   */
  setProfile(profile: Profile): Observable<Profile> {
    try {
      if (!profile) {
        console.error('Profile is not defined');
        return of();
      }

      if (profile.idField) {
        this.af.doc(FirestorePath.PROFILES_COLLECTION + '/' + profile.idField).set({
          ...profile
        }, { merge: true });
      } else {
        this.af.collection(FirestorePath.PROFILES_COLLECTION).doc().set({ ...profile });
      }

      return of(profile);
    } catch (error) {
      console.error(error);
      this.store.dispatch(setProfileFailure({
        error
      }));
    }
  }

  /**
   * It return a profile given an email address
   * @param email string
   * @returns Observable<QuerySnapshot<Profile>>
   */
  getProfileByEmail(email:String ): Observable<QuerySnapshot<Profile>> {
    try {
      if (!email) {
        console.error('Email is not defined');
        return of();
      }

      return this.af.collection<Profile>(FirestorePath.PROFILES_COLLECTION, ref => ref.where('email', '==', email)).get();
    } catch (error) {
      console.error(error);
      this.store.dispatch(getProfileByEmailFailure({
        error
      }));
    }
  }

}
