<div class="m-container">
  <psm-home-card [title]="content.title">
    <section>
      <agenda-item-view
        [editForm]="true"
        [agendaItem]="agendaItem"
        [hasObjectiveRelationship]="hasObjectiveRelationship">
      </agenda-item-view>
    </section>
  </psm-home-card>
  <psm-comments-section *ngIf="idField" [parentNoteId]="idField"></psm-comments-section>
</div>
