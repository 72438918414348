<div class="p-mt-4">
  <div class="p-d-flex p-jc-center">
    <p-avatar *ngIf="!profileState.avatarUrl" shape="circle" icon="pi pi-user" size="xlarge"></p-avatar>
    <p-avatar *ngIf="profileState.avatarUrl" [image]="profileState.avatarUrl" shape="circle" size="xlarge"></p-avatar>
  </div>
  <div class="p-d-flex text-header p-mt-4">
    {{ profileState.personalInfo?.name + ' ' + profileState.personalInfo?.lastName }}
  </div>
  <div class="p-d-flex text-subtitle">
    <p>{{ profileState?.email }}</p>
  </div>
</div>
