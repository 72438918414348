import { Injectable } from '@angular/core';

@Injectable()
export class PsmTextInputConfig {
  txtMaxLength = 45;

  ptPhoneMask = {
    inputType: 'tel',
    mask: [
      '(',
      /[1-9]/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
  };
  phoneMaskConfig = {
    guide: false,
    mask: this.ptPhoneMask.mask,
  };

  constructor() {}
}
