import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SaveDataConfirmationComponent } from 'src/app/pages/dialogs/common/save-data-confirmation/save-data-confirmation.component';
import { addGoalDocument } from 'src/app/state-v2/action/goal.actions';
import { addNoteApi } from 'src/app/state-v2/action/notes.actions';
import { ServiceAccount } from 'src/app/state-v2/model/service-account.model';
import { GoalState } from 'src/app/state-v2/reducer/goal.reducer';
import { selectServiceAccount } from 'src/app/state-v2/selector/service-accont.selector';
import { AppLang } from 'src/assets/i18n/app-lang';
import { RouteName } from 'src/shared/router-paths';
import { PsmNotesComponent } from '../psm-notes/psm-notes.component';
import PT from './i18n/pt.json';
import { Pillar } from 'src/app/state-v2/model/pillar.model';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'psm-goal-view',
  templateUrl: './psm-goal-view.component.html',
  styleUrls: ['./psm-goal-view.component.scss']
})
export class PsmGoalViewComponent implements OnInit {

  /**
   * It defines the title at the top of the card
   *
   * @memberof PsmGoalViewComponent
   */
  @Input() title = '';

  /**
   * The goal object data item to be shown on the screen
   *
   */
  @Input() goalItem: GoalState = {};

  /**
   * Object representing the selected pillar
   */
  @Input() pillarItem: Pillar = {};

  /**
   * If true it displays the controls to add notes, close the goal and add pictures
   *
   * @memberof PsmGoalViewComponent
   */
  @Input() showGoalControls = false;

  /**
   * If true it displays some of the CRUD controls
   * Edit, Remove and Objectives
   *
   * @memberof PsmGoalViewComponent
   */
  @Input() showGoalCRUDControls = false;

  /**
   * It represents the copy content of the page
   *
   * @memberof PsmGoalViewComponent
   */
  content: any;

  /**
   * Service Account State
   */
  serviceAccount: ServiceAccount;

  constructor(
    private translate: TranslateService,
    private store: Store,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.store.select(selectServiceAccount).subscribe(serviceAccount => this.serviceAccount = serviceAccount);
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;
  }

  /**
   * It launches the dialog to add the note
   */
  onAddGoalNoteClick() {
    const notesDialog = this.dialog.open(PsmNotesComponent, {
      data: {
        title: this.goalItem.header ?? ''
      }
    });

    notesDialog.afterClosed().subscribe(
      notePayload => {
        if (notePayload) {
          this.store.dispatch(addNoteApi({
            note: {
              note: notePayload,
              parentId: this.goalItem.goalId ?? '',
              parentType: 'goal',
              profile: this.serviceAccount?.profileRef ?? ''
            }
          }));
        }
      }
    );
  }

  /**
   * It displays the objectives for the given goal
   * @param goal Goal
   * @param index number
   */
  onObjectiveClick(goal: GoalState) {
    this.router.navigate([
      RouteName.OBJECTIVE,
      {
        goalId: goal.goalId,
        pillarNode: this.pillarItem.pillarId
      }
    ]);
  }

  /**
   * It edits the selected goal
   * @param goal Goal
   * @param index number
   */
  onEditClick(goal: GoalState) {
    this.router.navigate([
      RouteName.GOAL_FORM,
      {
        goalId: goal.goalId,
        pillar: this.pillarItem.pillarId
      },
    ]);
  }

  /**
   * It routes to the goal details page
   */
  onViewDetailsClick(goal: GoalState) {
    if(goal?.goalId) {
      this.router.navigate([RouteName.GOAL_DETAIL, {
          goalId: goal.goalId,
        },
      ]);
    }
  }

  /**
   * It removes the document from firestore
   * @param index The array index for the given goal
   */
  onRemoveClick(goal: GoalState) {
    if (!goal) console.error('Goal ID not found');
    if (goal) {
      const dialogRef = this.dialog.open(SaveDataConfirmationComponent, {
        data: {
          confirm: true,
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.store.dispatch(addGoalDocument({
            goal: {
              idField: goal.idField,
              goalId: goal.goalId,
              active: false,
              description: goal.description,
              favorite: false,
              header: goal.header,
              length: goal.length,
              pillarNode: this.pillarItem.pillarId,
              priority: 0,
              reality: goal.reality,
              reason: goal.reason,
              goalResAvailable: goal.goalResAvailable,
              goalResRequired: goal.goalResRequired
            }
          }));
        }
      });
    }
  }

}
