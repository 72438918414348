import { Component, EventEmitter, Input, OnInit, Output, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { PsmTextInputConfig } from './psm-text-input.config';

export interface TextMaskConfig {

  // tslint:disable-next-line: ban-types
  mask: boolean | Array<string | RegExp> | Function;

  guide?: boolean;

  // tslint:disable-next-line: ban-types
  pipe?: Function;
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'psm-text-input',
  templateUrl: './psm-text-input.component.html',
  styleUrls: ['./psm-text-input.component.scss']
})
export class PsmTextInputComponent implements OnInit, OnChanges, OnDestroy {

  /**
   * If true it displays a text area instead
   */
  @Input() textArea = false;

  /**
   * Default is 5 rows
   */
  @Input() textAreaRows = 5;

  /**
   * Parent form group with the text input form control
   */
  @Input() parentFormGroup: FormGroup;

  /**
   * Whether or not the form is disabled
   * Default is false
   */
  @Input() disabled = false;

  /**
   * Label for the input
   */
  @Input() label: string;

  /**
   * Whether or not the field is readonly
   * Default is false
   */
  @Input() readonly = false;

  /**
   * Placeholder for the text
   */
  @Input() placeholder: string;

  /**
   * The input type for the field
   */
  @Input() inputType: string;

  /**
   * The control name of the form defined in the formgroup
   */
  @Input() controlName: string;

  /**
   * The value of the field
   */
  @Input() value: any;

  /**
   * The max length allowed
   */
  @Input() maxLength: number;

  /**
   * The min length allowed
   */
  @Input() minLength: number;

  /**
   * Whether the field is required in the form
   */
  @Input() required = false;

  /**
   * Hint text to be displayed in the field
   */
  @Input() hintText: string;

  /**
   * Form validators
   */
  @Input() validators: ValidatorFn[];

  /**
   * Indicates if the field is for the datepicker
   */
  @Input() isDatePicker = false;

  /**
   * Indicates wheter the field allow only numbers
   */
  @Input() allowOnlyNumbers = false;

  /**
   * The field name
   */
  @Input() name: string;

  /**
   * Masking configuration
   */
  @Input() mask: TextMaskConfig = { mask: false };

  /**
   * Emits the focus event
   */
  @Output() inputFocus = new EventEmitter();

  /**
   * Emits the blur event
   */
  @Output() inputBlur = new EventEmitter();

  /**
   * Emits the status event
   */
  @Output() inputStatus = new EventEmitter();

  /**
   * Form control to the text input
   */
  control: FormControl;

  constructor(private psmTextInputConfig: PsmTextInputConfig) { }

  ngOnInit(): void {
    if (!this.maxLength) {
      this.maxLength = this.psmTextInputConfig.txtMaxLength;
    }

    this.control = new FormControl({
      value: this.isDatePicker ? new Date(this.value) : this.value,
      disabled: this.disabled
    }, this.validators);
    this.parentFormGroup.addControl(this.controlName, this.control);
  }

  /**
   * Callback when the model change
   * @param value string
   */
  onNgModelChange(value: string) {
    this.inputStatus.emit(value);
  }

  /**
   * Angular lifecyle hook
   * @param changes SimpleChanges
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && this.controlName) {
      const formCtrl = this.parentFormGroup.get(this.controlName);
      if (formCtrl) {
        formCtrl.setValue(
          this.subsInputValue(this.value)
        );
      }
    }
  }

  /**
   * It removes the input form control to the parent form
   */
  ngOnDestroy(): void {
    if (this.parentFormGroup && this.parentFormGroup.contains(this.controlName)) {
      this.parentFormGroup.removeControl(this.controlName);
    }
  }

  /**
   * It does a substring in case the value is greater than the maxLength
   * Some android versions do not respect the maxLength
   *
   * @param value string
   * @returns string
   */
  private subsInputValue(value: string): string {
    if ((value && typeof value === 'string') && value.length > this.maxLength) {
      return value.substr(0, this.maxLength);
    }

    return value;
  }

}
