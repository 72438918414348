<div class="p-d-flex p-jc-center">
  <form [formGroup]="parentFormGroup">
    <mat-form-field
      appearance="outline"
      [disabled]="disabled">
      <mat-label>{{ label }}</mat-label>
      <mat-select
        disableOptionCentering="false"
        [(value)]="value"
        [placeholder]="placeholder"
        [required]="required"
        (openedChange)="openedChange.emit($event)"
        (selectionChange)="selectionChange.emit($event)">
        <mat-option
          [value]="option.value"
          *ngFor="let option of optionList">
          {{ option.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
