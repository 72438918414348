import { createReducer, on } from '@ngrx/store';
import { loadPillarCollectionSuccess, setPillarCollectionSuccess } from '../action/pillar.actions';
import { Pillar } from '../model/pillar.model';

export interface PillarState {
  pillars: Pillar[] | null;
}

export const initialState: PillarState = {
  pillars: [
    {
      order: 1,
      pillarId: 'family',
      shortTitle: 'Família',
      title: 'Família'
    },
    {
      order: 2,
      pillarId: 'self_development',
      shortTitle: 'Pessoal',
      title: 'Desenv. Pessoal'
    },
    {
      order: 3,
      pillarId: 'work',
      shortTitle: 'Trabalho',
      title: 'Trabalho'
    },
    {
      order: 4,
      pillarId: 'health',
      shortTitle: 'Saúde',
      title: 'Saúde'
    }]
};

export const pillarReducer = createReducer(
  initialState,
  on(setPillarCollectionSuccess, (state, { pillars }) => {
    return {...state, pillars}
  }),
  on(loadPillarCollectionSuccess, (state, { pillars }) => {
    return {...state, pillars}
  })
);
