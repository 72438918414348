import { Component, Inject, OnInit } from '@angular/core';

import PT from './i18n/pt.json';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppLang } from 'src/assets/i18n/app-lang';

export interface DialogData {
  confirm: boolean;
}

@Component({
  selector: 'app-agenda-dialog',
  templateUrl: './agenda-dialog.component.html',
  styleUrls: ['./agenda-dialog.component.scss']
})
export class AgendaDialogComponent implements OnInit {

  content: any;

  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<AgendaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;
  }

  /**
   * It closes the modal
   */
  onCloseDialog() {
    this.dialogRef.close();
  }

}
