<app-master-content
  [masterContentPage]="page"
  (btnBackwardEvent)="onCloseClick($event)"
  (btnForwardEvent)="onSubmit($event)"
>
  <div class="p-flex-column w-full p-mt-4">
    <mat-card class="p-p-3">
      <mat-card-content>
        <div fxLayout="row">
          <img
            class="info-example"
            [src]="exampleBanner"
            aria-hidden="true"
          >
          <span class="example-text">
            {{content.lblInfo}}
          </span>
        </div>
        <div cdkDropList class="pillar-box-drag-div" (cdkDropListDropped)="drop($event)">
          <div class="order-box" *ngFor="let pillar of pillarState; let i = index" cdkDrag>
            <div class="order-placeholder" *cdkDragPlaceholder></div>
            <div
              class="chip-div"
              [ngClass]="{
                'highlight-yellow': pillar.pillarId === 'family',
                'highlight-red': pillar.pillarId === 'health',
                'highlight-blue': pillar.pillarId === 'self_development',
                'highlight-green': pillar.pillarId === 'work'
              }">
              <span>
                {{ i + 1 }}
              </span>
            </div>
            <span>
              {{pillar.shortTitle}}
            </span>
          </div>
        </div>
        <psm-home-card [title]="content.lblPillar">
          <section class="pillar-box-section">
            <button
              class="pillar-btn-box"
              *ngFor="let item of pillarArrDisplay"
              [ngClass]="{
                'highlight-yellow': item.pillarId === 'family',
                'highlight-red': item.pillarId === 'health',
                'highlight-blue': item.pillarId === 'self_development',
                'highlight-green': item.pillarId === 'work'
              }">
              <p>
                {{item.shortTitle}}
              </p>
            </button>
          </section>
        </psm-home-card>
      </mat-card-content>
    </mat-card>
  </div>
</app-master-content>
