import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { GoalApiService } from 'src/app/services/firebase/goal/goal-api.service';
import { ObjectiveApiServiceV2 } from 'src/app/services/firebase/objective/objective-api.service';
import { PillarApiService } from 'src/app/services/firebase/pillar/pillar-api.service';
import { openObjectivesPageFailure } from 'src/app/state-v2/action/objective.actions';
import { Pillar } from 'src/app/state-v2/model/Pillar.model';
import { ObjectiveModel } from 'src/app/state-v2/model/objective.model';
import { ServiceAccount } from 'src/app/state-v2/model/service-account.model';
import { GoalState } from 'src/app/state-v2/reducer/goal.reducer';
import { selectServiceAccount } from 'src/app/state-v2/selector/service-accont.selector';
import { AppLang } from 'src/assets/i18n/app-lang';
import { RouteName } from 'src/shared/router-paths';
import { ObjectiveLearnmoreComponent } from '../dialogs/objective-learnmore/objective-learnmore.component';
import { openObjectivesPage } from './../../state-v2/action/objective.actions';
import PT from './i18n/pt.json';

@Component({
  selector: 'app-objective',
  templateUrl: './objective.component.html',
  styleUrls: ['./objective.component.scss'],
})
export class ObjectiveComponent implements OnInit {

  /**
   * It's the content object representation
   *
   * @type {*}
   * @memberof ObjectiveComponent
   */
  content: any;

  /**
   * The objectives stream
   *
   * @type {Observable<ObjectiveModel[]>}
   * @memberof ObjectiveComponent
   */
  objectives: Observable<ObjectiveModel[]>;

  /**
   * The representation of the pillar document from firestore
   *
   * @type {AngularFirestoreDocument<Pillar>}
   * @memberof GoalComponent
   */
  pillars: Observable<Pillar[]>;
  pillar: Pillar;

  /**
   * It represents the current Goal selection
   *
   * @type {GoalState}
   * @memberof ObjectiveComponent
   */
  goal: GoalState;

  /**
  * Service Account State
  */
  serviceAccount: ServiceAccount;

  constructor(
    private translate: TranslateService,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private objectiveApi: ObjectiveApiServiceV2,
    private goalApi: GoalApiService,
    private pillarApi: PillarApiService
  ) {}

  ngOnInit(): void {
    this.store.select(selectServiceAccount).subscribe(serviceAccount => this.serviceAccount = serviceAccount);

    const goalId = this.route.snapshot.paramMap.get('goalId');
    this.store.dispatch(openObjectivesPage({ goalId }));

    this.translate.setTranslation(AppLang.DEFAULT, PT, true);
    this.content = PT.content;
    this.scrollTop();

    const pillarNode = this.route.snapshot.paramMap.get('pillarNode');

    if (goalId && pillarNode) {
      this.objectives = this.objectiveApi.getObjectives(goalId);
      this.pillars = this.pillarApi.getPillarStream(pillarNode, this.serviceAccount?.profileRef ?? '');
      this.pillars.subscribe(pillars => pillars.forEach(pillar => this.pillar = pillar));
      this.goalApi.getGoalByGoalId(goalId).subscribe(goals => goals.forEach(goal => this.goal = goal));
    } else {
      console.error('GoalRef not found');
      this.store.dispatch(openObjectivesPageFailure({
        error: 'The Goal reference has not been found when loading the page'
      }));
    }
  }

  /**
   * It scrolls the screen to the top page
   */
  private scrollTop() {
    setTimeout(() => {
      if (document.getElementById('cardHeader')) {
        document.getElementById('cardHeader').scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }, 100);
  }

  /**
   * It routes to the objective form for creation
   */
  onAddClick() {
    this.router.navigate([
      RouteName.OBJECTIVE_FORM,
      {
        pillarNode: this.pillar.pillarId,
        goalId: this.goal.goalId
      },
    ]);
  }

  onLearnMoreClick() {
    this.dialog.open(ObjectiveLearnmoreComponent);
  }
}
