import { createAction, props } from '@ngrx/store';

export const setTokenStatus = createAction(
  '[Component] Set Token Status',
  props<{status: boolean, msg: string}>()
);

export const setToolBarOptions = createAction(
  '[Component] Set Tool Bar Options',
  props<{setToolBar: boolean}>()
);

export const setToolBarURL = createAction(
  '[Component] Set Tool Bar URL',
  props<{url: string, params: {}}>()
);

export const setSideMenu = createAction(
  '[Component] Set Side Menu',
  props<{setSideMenu: boolean}>()
);
